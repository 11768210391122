import { createAsyncThunk } from "@reduxjs/toolkit";
import { $apiV1 } from "../../config/api";

export const loginUser = createAsyncThunk(
  "user/login",
  async (body, { rejectWithValue }) => {
    try {
      const response = await $apiV1.post(
        "https://platform.smrtstats.com:8888/api/v1/auth/login/",
        body
      );
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  }
);

export const regUser = createAsyncThunk(
  "user/registration",
  async (body, { rejectWithValue }) => {
    try {
      const response = await $apiV1.post(
        "https://platform.smrtstats.com:8888/api/v1/auth/registration/",
        body
      );
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  }
);

export const getFormationInfo = createAsyncThunk(
  "user/user_profile",
  async (body, { rejectWithValue }) => {
    try {
      const response = await $apiV1.get(
        "https://platform.smrtstats.com:8888/api/v1/users/user_profile/",
        {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        }
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  }
);
