import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";

// Components
import Navigation from "../../../components/Navigation/Navigation";
import IntroText from "../../../components/introText/index";

// Language
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SmrtSearchTable from "../../../components/table/SmrtSearchTable";
import MatchTable from "../../../components/table/MatchTable";

// Images
import placeholder from "../../../images/ic_logo.svg";
import yellowCard from "../../../images/ic_yellow-card.svg";
import redCard from "../../../images/ic_red-card.svg";
import substitution from "../../../images/ic_substition.svg";
import goal from "../../../images/ic_goal.svg";
import injury from "../../../images/ic_injury.svg";
import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";

import Moment from "react-moment";
import { TeamButtons } from "../player/Index";
import Button from "../../../components/button";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../../style/Favorites";
import PlayersStat from "../../../components/table/PlayersStat";
import GoalKeeperStat from "../../../components/table/GoalKeeperStat";
import { breakpoints } from "../../../style/size";
import Filter, { FormWrap } from "../../../components/modal/Filter";
import { InputWrapper } from "../../../components/inputs/TextInput";
import { $apiV1 } from "../../../config/api";
import { Formation } from "../../../components/Formation";
import { getGroupedAndSortedParametrs } from "../../../helpers";
import { playersModalParams } from "../../../config/parametrs/playersModalParams";
import { goalkeepersModalParams } from "../../../config/parametrs/goalkeepersModalParams";
import ParamFilter from "./ParamFilter";
import { Overlay } from "../../../components/modal";
import {
  Content,
  ModalWrapper,
} from "../../../components/ParametersSelector/ParametersModal";
import { ActionButtonsContainer, ParametersSelector } from "../../../components/ParametersSelector";

export const TeamCardWrap = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  margin-bottom: 60px;
`;

export const DownloadXml = styled.div`
  text-align: right;

  button {
    border-radius: 1px;
    border: none;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    color: ${(props) => props.theme.black};
    opacity: 1;
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0 10px;
    height: 40px;
    cursor: pointer;
  }
`;

export const TeamContent = styled.div`
  padding: 20px;
`;

export const TopContent = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(24, 25, 24, 1);

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 35px;
  }
`;

export const Info = styled.div`
  display: flex;
  gap: 16px;

  @media only screen and (${breakpoints.mobile}) {
    align-items: center;
    width: 100%;
  }
`;

export const BottomContent = styled.div``;

export const Result = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiBold";
    font-size: 36px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 4px;
    text-align: center;
    line-height: 50px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const TeamInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &.reverse {
    flex-direction: row-reverse;

    @media only screen and (${breakpoints.mobile}) {
      flex-direction: row;
    }
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  a {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 38px;
    text-decoration: none;

    width: 170px;
    max-width: fit-content;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 16px;
      width: 100%;
    }
  }

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }
`;

export const TeamLeftInfo = styled.div`
  h5 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;

    time {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 18px;
    }
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 38px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 24px;
    }

    &.statText {
      max-width: 300px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media only screen and (${breakpoints.mobile}) {
        max-width: 150px;
      }
    }
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const TeamRightInfo = styled.div`
  padding-right: 70px;
  ul {
    li {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }
`;

export const Stat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TeamStatistics = styled.div`
  padding-top: 20px;

  h5 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  h4 {
    opacity: 1;
    color: rgba(207, 218, 230, 1);
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const StatCardsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const StatCard = styled.div`
  width: 48%;
  border-radius: 4px;
  opacity: 1;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const TopStatCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
  }

  button {
    border-radius: 1px;
    border: none;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    width: 92px;
    height: 40px;
    cursor: pointer;
  }
`;

export const InnerCardInfo = styled.div`
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  height: 400px;
  overflow: scroll;
`;

export const BottomTableStat = styled.div`
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
`;

export const TeamStatInfo = styled.div``;

export const TopTeamNames = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.black};
  padding: 5px 0;

  &:nth-child(1) {
    z-index: 1;
  }

  a {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    width: 30%;
    padding: 0 10px;
    text-align: right;
    text-decoration: none;

    &:nth-child(1) {
      text-align: left;
    }
  }

  select {
    border-radius: 1px;
    border: 1px solid rgba(44, 50, 48, 1);
    opacity: 1;
    background: transparent;
    color: #fff;
    height: 40px;
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    outline: none;
  }
`;

export const TeamStatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  // &:nth-child(2) {
  //   width: 40%;
  // }

  h5 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    margin: auto;

    &.aboveSpace {
      margin-top: 20px;
    }
  }
`;

export const TeamInfoStat = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const InfoStat = styled.div`
  padding: 0 10px;
  &:nth-child(even) {
    background: ${(props) => props.theme.black};
  }
`;

export const MatchEvent = styled.div`
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  height: 400px;
  overflow: scroll;
`;

export const MathTitle = styled.div`
  display: flex;
  padding: 20px 0 0 10px;

  h3 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;

    &:nth-child(1) {
      width: 150px;
    }

    &:nth-child(2) {
      width: 100px;
    }
  }
`;
export const MatchWrap = styled.div`
  display: flex;
  padding: 10px 0px 10px 10px;

  &:nth-child(even) {
    background: ${(props) => props.theme.black};
  }

  img {
    object-fit: contain;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 5px;

    &:nth-child(1) {
      width: 150px;
    }

    &:nth-child(2) {
      width: 100px;
    }

    &.creatorName {
      max-width: 120px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const BottomStatTable = styled.div`
  margin: 40px 0;
`;

export const ParamCheckboxStyle = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 10px !important;
  gap: 20px;
  white-space: nowrap;

  label {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.green}; /* Green color */
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 0.6em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 21%, 80% 0, 43% 62%);
    transform: scale(0);
    transition: transform 0.15s ease-in-out;
    background-color: ${(props) =>
      props.theme.green}; /* Green color for the check */
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  label {
  }
`;

export const ParamsWrap = styled.div`
  position: relative;
`;

export const OpenedDropdown = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: 20px;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  max-height: 200px;
  overflow: scroll;

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: #00000012;
    }
  }
`;

const Index = () => {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [matchStat, setMatchStat] = useState({});
  const [matchesWithStatHome, setMathesWithStatHome] = useState([]);
  const [matchesWithStatAway, setMathesWithStatAway] = useState([]);
  const [playerStats, setPlayerStats] = useState();

  const [selectedHomePlayer, setSelectedHomePlayer] = useState("");
  const [selectedAwayPlayer, setSelectedAwayPlayer] = useState("");

  const [selectedGkHomePlayer, setSelectedGkHomePlayer] = useState("");
  const [selectedGkAwayPlayer, setSelectedGkAwayPlayer] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const [openPlayerFilter, setOpenPlayerFilter] = useState(false);

  const [openParams, setOpenParams] = useState(false);

  const [openFormation, setOpenFormation] = useState(false);

  const [teamParamsList, setTeamParamsList] = useState([]);
  const [playerParamsList, setPlayerParamsList] = useState([]);
  const [playerGkParams, setPlayerGkParams] = useState([]);

  const [selectedParams, setSelectedParams] = useState([]);

  //players filter state
  const [typePlayerStat, setTypePlayerStat] = useState('player'); // player | gk
  const [gkPlayerSelectedFilters, setGkPlayerSelectedFilters] = useState({ checkedIds: [], params: [] });
  const [playerSelectedFilters, setPlayerSelectedFilters] = useState({ checkedIds: [], params: [] });

  useEffect(() => {
    // Initialize selectedParams with all parameters
    const allParams = teamParamsList.map(([key]) => key);
    setSelectedParams(allParams);
  }, [teamParamsList]);

  const isSelected = (param) => selectedParams.includes(param);

  const handleParameterSelect = (param) => {
    if (isSelected(param)) {
      setSelectedParams(selectedParams.filter((p) => p !== param));
    } else {
      setSelectedParams([...selectedParams, param]);
    }
  };

  const navigate = useNavigate();


  useEffect(() => {
    const setParams = async () => {
      try {
        let params = null;
        if (sessionStorage.getItem('playerParameters')) {
          params = JSON.parse(sessionStorage.getItem('playerParameters'))
          const checkedIds = params.flatMap(group => group[1].filter(item => item.checked).map(item => item.id));
          const checkedParams = params.flatMap(group => group[1].filter(item => item.checked).map(item => item.name));
          setGkPlayerSelectedFilters({ checkedIds, params: checkedParams });
          setPlayerSelectedFilters({ checkedIds, params: checkedParams })
        } else {
          const response = await $apiV1.get('/stats/parameters/player/');
          const data = response.data?.results;
          if (data) {
            params = getGroupedAndSortedParametrs(data, true);
            const checkedIds = params.flatMap(group => group[1].map(item => item.id));
            const checkedParams = params.flatMap(group => group[1].map(item => item.name));
            setGkPlayerSelectedFilters({ checkedIds, params: checkedParams });
            setPlayerSelectedFilters({ checkedIds, params: checkedParams })
          }
        }
      } catch (e) {
        console.log(`Error in receiving player parameters. ${e.message}`)
      }
    }
    setParams();
  }, [])

  useEffect(() => {
    if (playerStats?.players_stats?.home_team.length) {
      setSelectedHomePlayer(
        String(playerStats.players_stats.home_team[0]?.id || "")
      );
      const gk = playerStats.players_stats.home_team.find((item) =>
        item.positions.includes("GK")
      );
      console.log("home", gk);
      if (gk) {
        setSelectedGkHomePlayer(`${gk.id}`);
      }
    }
    if (playerStats?.players_stats?.away_team.length) {
      setSelectedAwayPlayer(
        String(playerStats.players_stats.away_team[0]?.id || "")
      );
      const gk = playerStats.players_stats.away_team.find((item) =>
        item.positions.includes("GK")
      );

      if (gk) {
        setSelectedGkAwayPlayer(`${gk.id}`);
      }
    }
  }, [playerStats]);

  // Handler for home team player change
  const handleHomePlayerChange = (event) => {
    setSelectedHomePlayer(event.target.value);
  };

  // Handler for away team player change
  const handleAwayPlayerChange = (event) => {
    setSelectedAwayPlayer(event.target.value);
  };

  const filterPlayers = (players) => {
    return (players || []).filter(({ positions }) => !positions.includes("GK"));
  };

  const filterGK = (players) => {
    return (players || []).filter(({ positions }) => positions.includes("GK"));
  };

  // Find selected home player stats
  const homePlayer = playerStats?.players_stats?.home_team.find(
    (player) => String(player.id) === selectedHomePlayer
  );

  // Find selected away player stats
  const awayPlayer = playerStats?.players_stats?.away_team.find(
    (player) => String(player.id) === selectedAwayPlayer
  );

  // Find selected home gk player stats
  const homeGkPlayer = playerStats?.players_stats?.home_team.find(
    (player) => String(player.id) === selectedGkHomePlayer
  );

  // Find selected away gk player stats
  const awayGkPlayer = playerStats?.players_stats?.away_team.find(
    (player) => String(player.id) === selectedGkAwayPlayer
  );

  const getTeamParams = async () => {
    try {
      const response = await $apiV1.get(`/stats/parameters/team/`);
      const data = response?.data?.results;
      if (data) {
        const params = getGroupedAndSortedParametrs(data);
        getMatchStat(id, params);
        setTeamParamsList(params);
      }
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getPlayerParams = async () => {
    try {
      const response = await $apiV1.get(`/stats/parameters/player/`);
      const data = response?.data?.results;
      if (data) {
        const params = getGroupedAndSortedParametrs(data);
        setPlayerParamsList(params);
        const gkParamIds = goalkeepersModalParams.map((item) => item.paramId);
        const playerGkParams = data.filter((item) =>
          gkParamIds.includes(item.id)
        );
        setPlayerGkParams(getGroupedAndSortedParametrs(playerGkParams));
      }
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getStats = async (id) => {
    try {
      setLoading(true);
      const response = await $apiV1.get(`/platform/match_detail/${id}/events`);
      setStats(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const getMatchStat = async (id, params) => {
    try {
      setLoading(true);
      let allParams = [];
      params.forEach(([_, value]) => {
        allParams = [...allParams, ...value];
      });
      allParams = allParams?.filter((param) => param.checked);
      const paramsIds = allParams?.map((item) => item.id);
      const response = await $apiV1.get(
        `/stats/match_teams/${id}?params=${paramsIds?.join(",")}`
      );
      setMatchStat(response?.data);
      const dataHome = response.data?.teams_stats?.home_team?.stats;
      const dataAway = response.data?.teams_stats?.away_team?.stats;
      setMathesWithStatHome(dataHome);
      setMathesWithStatAway(dataAway);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const getPlayerStats = async (id, params = []) => {
    try {
      setLoading(true);
      let currentParams = params;
      if (sessionStorage.getItem("playerParameters") && params?.length === 0) {
        const params = JSON.parse(sessionStorage.getItem("playerParameters"));
        const checkedIds = params.flatMap((group) =>
          group[1].filter((item) => item.checked).map((item) => item.id)
        );
        currentParams = checkedIds;
      }
      const response = await $apiV1.get(`/stats/match_players/${id}?params_ids=${currentParams.join(",")}`);
      setPlayerStats(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  //   ------

  function downloadFile(url, fileName) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl); // Освобождение ресурсов
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.error("Ошибка при скачивании файла:", e));
  }

  const postSelectedMatchesEventsXML = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await $apiV1.post("/statistics/match_event_unload", {
        file_type: "xml",
        matches: [id],
      });
      const fileUrl = response.data?.file_url;
      downloadFile(
        `https://platform.smrtstats.com:8888/${fileUrl}`,
        "report.xml"
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleDownload = () => {
    postSelectedMatchesEventsXML();
  };

  useEffect(() => {
    getStats(id);
    getPlayerParams();
    getTeamParams();
    getPlayerStats(id);
  }, [id]);

  useEffect(() => { }, []);

  const [tempSelectedFilters, setTempSelectedFilters] = useState([]);

  const handleFilterChange = (id) => {
    setTempSelectedFilters((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((item) => item !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const displayFilters = () => {
    let displayedParams = typePlayerStat === 'gk' ? gkPlayerSelectedFilters : playerSelectedFilters;

    if (displayedParams?.params?.length === 0) {
      return "Choose params";
    }

    const firstTwo = displayedParams?.params?.slice(0, 2)?.join(", ");
    const additionalCount = displayedParams?.params?.length - 2;
    return additionalCount > 0 ? `${firstTwo}, +${additionalCount}` : firstTwo;
  };

  const handleClearFilter = () => {
    setTempSelectedFilters("");
  };

  const handleGroupFilterChange = (keywords) => {
    const items = playerStats?.players_stats.away_team[0]?.stats.filter(
      (item) =>
        keywords.some((keyword) => item.parameter_name.includes(keyword))
    );
    const allSelected = areAllSelected(keywords);

    if (allSelected) {
      // Deselect all items in the group
      const newFilters = tempSelectedFilters.filter(
        (filter) => !items.some((item) => item.parameter_name === filter)
      );
      setTempSelectedFilters(newFilters);
    } else {
      // Select all items in the group
      const newFilters = [
        ...tempSelectedFilters,
        ...items.map((item) => item.parameter_name),
      ];
      setTempSelectedFilters([...new Set(newFilters)]);
    }
  };

  const areAllSelected = (keywords) => {
    const items = playerStats?.players_stats.away_team[0]?.stats.filter(
      (item) =>
        keywords.some((keyword) => item.parameter_name.includes(keyword))
    );
    return items.every((item) =>
      tempSelectedFilters.includes(item.parameter_name)
    );
  };

  function handleChangePlayerParams(params) {
    const checkedIds = params.flatMap(group => group[1].filter(item => item.checked).map(item => item.id));
    const checkedParams = params.flatMap(group => group[1].filter(item => item.checked).map(item => item.name));
    if (typePlayerStat === 'gk') {
      setGkPlayerSelectedFilters({ checkedIds, params: checkedParams })

    } else {
      setPlayerSelectedFilters({ checkedIds, params: checkedParams })
    }
    // getPlayerStats?.(id, checkedIds);
  }

  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener("navigationOpenChange", handleNavigationOpenChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle={t("favorites.smallTitle")}
            title="Check Out the Match Statistics."
          />
        </TopWrapper>
        <TeamCardWrap>
          <TeamContent>
            <TopContent>
              <Info style={{ alignItems: "center" }}>
                <img
                  src={stats?.match?.competition_logo || placeholder}
                  alt={placeholder}
                />{" "}
                <TeamLeftInfo>
                  <h5>
                    {" "}
                    <Moment format="DD.MM.YYYY">{stats?.match?.date}</Moment>
                  </h5>
                  <h2 className="statText">{stats?.match?.competition}</h2>
                </TeamLeftInfo>
              </Info>

              <TeamButtons>
                <Button
                  onClick={() => setOpenFormation(true)}
                  title="formation"
                  color={"secondary"}
                />
                <Button
                  disabled={!stats?.match?.id}
                  onClick={() =>
                    window.open(
                      `/tournaments/match/video/${stats?.match?.id}`,
                      "_blank"
                    )
                  }
                  title="Full Match Video"
                />
              </TeamButtons>
            </TopContent>
            <BottomContent>
              <Result>
                <TeamInfo>
                  <img
                    src={stats?.match?.home_team_logo || placeholder}
                    alt={placeholder}
                  />
                  <a
                    data-tooltip-id={`full-name-${stats?.match?.home_team_id}`}
                    href={`/tournaments/team/${stats?.match?.home_team_id}`}
                  >
                    {stats?.match?.home_team}
                  </a>

                  <ReactTooltip
                    id={`full-name-${stats?.match?.home_team_id}`}
                    place="bottom"
                    content={stats?.match?.home_team}
                  />
                </TeamInfo>
                <h5>{stats?.match?.score}</h5>
                <TeamInfo className="reverse">
                  <img
                    src={stats?.match?.away_team_logo || placeholder}
                    alt={placeholder}
                  />
                  <a
                    data-tooltip-id={`full-name-${stats?.match?.away_team_id}`}
                    href={`/tournaments/team/${stats?.match?.away_team_id}`}
                  >
                    {stats?.match?.away_team}
                  </a>
                  <ReactTooltip
                    id={`full-name-${stats?.match?.away_team_id}`}
                    place="bottom"
                    content={stats?.match?.away_team}
                  />
                </TeamInfo>
              </Result>
            </BottomContent>
          </TeamContent>
        </TeamCardWrap>

        <StatCardsWrap>
          <StatCard>
            <TopStatCard>
              <h2>Statistics</h2>
              <button onClick={() => setOpenFilter(!openFilter)}>Filter</button>
            </TopStatCard>
            <InnerCardInfo>
              <TabsWrapper>
                <TopList style={{ padding: "10px" }}>
                  <TabListWrapper>
                    <TabTitle>teams</TabTitle>
                    <TabTitle>players</TabTitle>
                    <TabTitle>goalkeepers</TabTitle>
                  </TabListWrapper>
                </TopList>

                {/* Team Tab */}
                <TabContent>
                  <TeamStatInfo>
                    {teamParamsList?.map(([key, values], index) => {
                      if (!isSelected(key)) return null;
                      return (
                        <React.Fragment key={key}>
                          <TopTeamNames>
                            {index === 0 && (
                              <a
                                href={`/tournaments/team/${matchStat?.teams_stats?.home_team?.id}`}
                              >
                                {matchStat?.teams_stats?.home_team?.name}
                              </a>
                            )}
                            {index === 0 && (
                              <a
                                href={`/tournaments/team/${matchStat?.teams_stats?.away_team?.id}`}
                              >
                                {matchStat?.teams_stats?.away_team?.name}
                              </a>
                            )}
                          </TopTeamNames>
                          <TeamStatHeader>
                            <h5 className={index === 0 ? "" : "aboveSpace"}>
                              {key}
                            </h5>
                          </TeamStatHeader>

                          <TeamInfoStat>
                            {values?.map(({ name }) => {
                              const tmpHome = matchesWithStatHome.find(
                                (stat) => stat.parameter_name === name
                              );
                              const tmpAway = matchesWithStatAway.find(
                                (stat) => stat.parameter_name === name
                              );
                              const linkHome =
                                tmpHome?.markers?.length > 0
                                  ? `/tournaments/match/video?markers=${tmpHome.markers.join(
                                      ","
                                    )}`
                                  : null;
                              const linkAway =
                                tmpAway?.markers?.length > 0
                                  ? `/tournaments/match/video?markers=${tmpAway.markers.join(
                                      ","
                                    )}`
                                  : null;
                              const sHome = tmpHome?.value ?? "0";
                              const sAway = tmpAway?.value ?? "0";
                              return (
                                <InfoStat
                                  key={name}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                  }}
                                >
                                  <h5
                                    onClick={() => navigate(linkHome)}
                                    onContextMenu={() =>
                                      window.open(linkHome, "_blank")
                                    }
                                    style={{
                                      cursor:
                                        tmpHome?.markers?.length > 0 &&
                                        "pointer",
                                    }}
                                  >
                                    {sHome}
                                  </h5>
                                  <h5>{name}</h5>
                                  <h5
                                    onClick={() => navigate(linkAway)}
                                    onContextMenu={() =>
                                      window.open(linkAway, "_blank")
                                    }
                                    style={{
                                      cursor:
                                        tmpAway?.markers?.length > 0 &&
                                        "pointer",
                                    }}
                                  >
                                    {sAway}
                                  </h5>
                                </InfoStat>
                              );
                            })}
                          </TeamInfoStat>
                        </React.Fragment>
                      );
                    })}
                  </TeamStatInfo>
                </TabContent>

                {/* Players Tab */}
                <TabContent>
                  <TeamStatInfo>
                    <TopTeamNames>
                      <select
                        onChange={handleHomePlayerChange}
                        value={selectedHomePlayer}
                      >
                        {filterPlayers(
                          playerStats?.players_stats?.home_team
                        ).map((item) => (
                          <option key={item.id} value={String(item.id)}>
                            {item.name} {item.surname}
                          </option>
                        )) || <option value="">No Home Players</option>}
                      </select>
                      <select
                        onChange={handleAwayPlayerChange}
                        value={selectedAwayPlayer}
                      >
                        {filterPlayers(
                          playerStats?.players_stats?.away_team
                        ).map((item) => (
                          <option key={item.id} value={String(item.id)}>
                            {item.name} {item.surname}
                          </option>
                        )) || <option value="">No Away Players</option>}
                      </select>
                    </TopTeamNames>

                    {playerParamsList?.map(([key, values]) => {
                      if (key === "Without group") return null;
                      if (!isSelected(key)) return null;
                      return (
                        <React.Fragment key={key}>
                          <TeamStatHeader style={{ width: "100%" }}>
                            <h5 className={"aboveSpace"}>{key}</h5>
                          </TeamStatHeader>
                          <TeamInfoStat>
                            {values?.map(({ name }) => {
                              const tmpHome = homePlayer?.stats?.find(
                                (stat) => stat.parameter_name === name
                              );
                              const tmpAway = awayPlayer?.stats?.find(
                                (stat) => stat.parameter_name === name
                              );
                              const linkHome =
                                tmpHome?.markers?.length > 0
                                  ? `/tournaments/match/video?markers=${tmpHome.markers.join(
                                      ","
                                    )}`
                                  : null;
                              const linkAway =
                                tmpAway?.markers?.length > 0
                                  ? `/tournaments/match/video?markers=${tmpAway.markers.join(
                                      ","
                                    )}`
                                  : null;
                              const sHome = tmpHome?.value ?? "0";
                              const sAway = tmpAway?.value ?? "0";
                              return (
                                <InfoStat
                                  key={name}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                  }}
                                >
                                  <h5
                                    onClick={() => navigate(linkHome)}
                                    onContextMenu={() =>
                                      window.open(linkHome, "_blank")
                                    }
                                    style={{
                                      cursor:
                                        tmpHome?.markers?.length > 0 &&
                                        "pointer",
                                    }}
                                  >
                                    {sHome}
                                  </h5>
                                  <h5>{name}</h5>
                                  <h5
                                    onClick={() => navigate(linkAway)}
                                    onContextMenu={() =>
                                      window.open(linkAway, "_blank")
                                    }
                                    style={{
                                      cursor:
                                        tmpAway?.markers?.length > 0 &&
                                        "pointer",
                                    }}
                                  >
                                    {sAway}
                                  </h5>
                                </InfoStat>
                              );
                            })}
                          </TeamInfoStat>
                        </React.Fragment>
                      );
                    })}
                  </TeamStatInfo>
                </TabContent>

                {/* GoalKeeper Tab */}
                <TabContent>
                  <TeamStatInfo>
                    <TopTeamNames>
                      <select
                        onChange={(event) =>
                          setSelectedGkHomePlayer(event.target.value)
                        }
                        value={selectedGkHomePlayer}
                      >
                        {filterGK(playerStats?.players_stats?.home_team).map(
                          (item) => (
                            <option key={item.id} value={String(item.id)}>
                              {item.name} {item.surname}
                            </option>
                          )
                        ) || <option value="">No Home Players</option>}
                      </select>
                      <select
                        onChange={(event) =>
                          setSelectedGkAwayPlayer(event.target.value)
                        }
                        value={selectedGkAwayPlayer}
                      >
                        {filterGK(playerStats?.players_stats?.away_team).map(
                          (item) => (
                            <option key={item.id} value={String(item.id)}>
                              {item.name} {item.surname}
                            </option>
                          )
                        ) || <option value="">No Away Players</option>}
                      </select>
                    </TopTeamNames>

                    {playerGkParams?.map(([key, values]) => {
                      if (!isSelected(key)) return null;
                      return (
                        <React.Fragment key={key}>
                          <TeamInfoStat>
                            {values?.map(({ name }) => {
                              const tmpHome = homeGkPlayer?.stats?.find(
                                (stat) => stat.parameter_name === name
                              );
                              const tmpAway = awayGkPlayer?.stats?.find(
                                (stat) => stat.parameter_name === name
                              );
                              const linkHome =
                                tmpHome?.markers?.length > 0
                                  ? `/tournaments/match/video?markers=${tmpHome.markers.join(
                                      ","
                                    )}`
                                  : null;
                              const linkAway =
                                tmpAway?.markers?.length > 0
                                  ? `/tournaments/match/video?markers=${tmpAway.markers.join(
                                      ","
                                    )}`
                                  : null;
                              const sHome = tmpHome?.value ?? "0";
                              const sAway = tmpAway?.value ?? "0";
                              return (
                                <InfoStat
                                  key={name}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                  }}
                                >
                                  <h5
                                    onClick={() => navigate(linkHome)}
                                    onContextMenu={() =>
                                      window.open(linkHome, "_blank")
                                    }
                                    style={{
                                      cursor:
                                        tmpHome?.markers?.length > 0 &&
                                        "pointer",
                                    }}
                                  >
                                    {sHome}
                                  </h5>
                                  <h5>{name}</h5>
                                  <h5
                                    onClick={() => navigate(linkAway)}
                                    onContextMenu={() =>
                                      window.open(linkAway, "_blank")
                                    }
                                    style={{
                                      cursor:
                                        tmpAway?.markers?.length > 0 &&
                                        "pointer",
                                    }}
                                  >
                                    {sAway}
                                  </h5>
                                </InfoStat>
                              );
                            })}
                          </TeamInfoStat>
                        </React.Fragment>
                      );
                    })}
                  </TeamStatInfo>
                </TabContent>
              </TabsWrapper>
            </InnerCardInfo>
          </StatCard>
          <StatCard>
            <TopStatCard>
              <h2>Match Events</h2>
              {/* <button>Filter</button> */}
            </TopStatCard>
            <MatchEvent style={{ color: "#fff" }}>
              <MathTitle>
                <h3>Events</h3>
                <h3>Min. of Match</h3>
                <h3>Description</h3>
              </MathTitle>
              {stats?.markers?.map((item, index) => {
                const roundedSeconds = Math.round(item.second);
                const minutes = Math.floor(roundedSeconds / 60);
                const remainingSeconds = roundedSeconds % 60;
                return (
                  <MatchWrap>
                    <h5>
                      <img
                        src={
                          item.action.title == "Yellow card"
                            ? yellowCard
                            : item.action.title == "Red card"
                            ? redCard
                            : item.action.title == "Substitution"
                            ? substitution
                            : item.action.title == "Goal"
                            ? goal
                            : item.action.title == "Injury"
                            ? injury
                            : ""
                        }
                      />{" "}
                      {item.action.title}{" "}
                    </h5>
                    <h5>
                      {minutes}:{remainingSeconds}
                    </h5>
                    {item?.creator && (
                      <img
                        width={25}
                        src={`https://platform.smrtstats.com:8888${item?.team_logo}`}
                        alt=""
                      />
                    )}
                    {item?.creator && (
                      <img
                        style={{ marginRight: "2px" }}
                        width={25}
                        src={`https://platform.smrtstats.com:8888${item?.creator?.photo}`}
                        alt=""
                      />
                    )}
                    {item.action === "Substitution" ? (
                      <>
                        <p style={{ margin: 0 }}>
                          {item?.creator?.surname} Off,{" "}
                          {item?.recipient?.surname} On
                        </p>
                        <img
                          width={25}
                          src={`https://platform.smrtstats.com:8888${item?.recipient?.photo}`}
                          alt=""
                        />
                      </>
                    ) : (
                      <h5 className="creatorName">
                        {item?.action?.title} {item?.creator?.surname}
                      </h5>
                    )}
                  </MatchWrap>
                );
              })}
            </MatchEvent>
          </StatCard>
        </StatCardsWrap>

        <BottomStatTable>
          <TopStatCard>
            <h2>Player Statistics</h2>
            <button onClick={() => setOpenPlayerFilter(!openPlayerFilter)}>
              Filter
            </button>
          </TopStatCard>
          <BottomTableStat>
            <TabsWrapper>
              <TopList style={{ padding: "10px" }}>
                <TabListWrapper>
                  <TabTitle onClick={() => setTypePlayerStat("player")}>
                    players
                  </TabTitle>
                  <TabTitle onClick={() => setTypePlayerStat("gk")}>
                    goalkeepers
                  </TabTitle>
                </TabListWrapper>
                <DownloadXml>
                  <button onClick={() => handleDownload()}>
                    Download in XML
                  </button>
                </DownloadXml>
              </TopList>

              <TabContent>
                <PlayersStat
                  items={playerStats?.players_stats}
                  club={stats?.match}
                  tempSelectedFilters={tempSelectedFilters}
                  playerSelectedFilters={playerSelectedFilters}
                />
              </TabContent>
              <TabContent>
                <GoalKeeperStat
                  items={playerStats?.players_stats}
                  club={stats?.match}
                  tempSelectedFilters={tempSelectedFilters}
                  gkPlayerSelectedFilters={gkPlayerSelectedFilters}
                />
              </TabContent>
            </TabsWrapper>
          </BottomTableStat>
        </BottomStatTable>

        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          onClick={() => handleClearFilter()}
        >
          <FormWrap>
            <div style={{ height: "300px", overflow: "scroll" }}>
              <h4>Select Parameters</h4>
              <ParamFilter
                paramsList={teamParamsList}
                isSelected={isSelected}
                handleParameterSelect={handleParameterSelect}
              />
            </div>
          </FormWrap>
        </Filter>

        <Filter
          openFilter={openPlayerFilter}
          setOpenFilter={setOpenPlayerFilter}
          onClick={() => handleClearFilter()}
        >
          <FormWrap>
            <div>
              <h4>Select Parameters</h4>

              <ParamsWrap>
                <InputWrapper>
                  <input
                    type="text"
                    value={displayFilters()}
                    readOnly
                    style={{ width: "100%", marginBottom: "10px" }}
                    onClick={() => setOpenParams(!openParams)}
                  />
                  <img
                    className="dropdownIcon"
                    src={openParams ? arrowIconUp : arrowIconDown}
                    alt={openParams ? arrowIconUp : arrowIconDown}
                    onClick={() => setOpenParams(!openParams)}
                  />
                </InputWrapper>
              </ParamsWrap>
            </div>
            <Button title="search" onClick={() => setOpenPlayerFilter(false)} />
          </FormWrap>
        </Filter>
      </MainWrapperFavorite>

      <ParametersSelector
        type={typePlayerStat}
        open={openParams}
        onClose={() => setOpenParams(false)}
        updateData={handleChangePlayerParams}
      />

      <Formation
        open={openFormation}
        handleClose={() => setOpenFormation(false)}
        match={stats?.match}
      />
    </MainWrapper>
  );
};

export default Index;
