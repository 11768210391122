import React, { useEffect, useRef } from 'react'
import { Portal } from './Portal'
import { CloseModal, Overlay } from '../modal';

import closeIcon from "../../images/ic_close.svg";
import styled from 'styled-components';

export const ModalWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid rgba(24, 31, 4, 1);
    box-shadow: 0px 3px 15px ${(props) => props.theme.green};
    opacity: 1;
    background-color: ${(props) => props.theme.black};
    display: flex;
    gap: 50px;
    height: 90vh;
    padding: 20px 40px 20px 20px;
    position: relative;
    top: 0;
    width: 80vw;
    z-index: 2;
`

export const Content = styled.div`
    display: flex;
    gap: 50px;
    height: calc(100% - 100px);
    overflow: auto;
    scrollbar-width: auto;
    padding: 20px 40px 20px 20px;
    position: relative;
    top: 0;
    width: calc(100% - 75px);
    z-index: 2;
`


export const ParametersModal = (props) => {
    const {
        open,
        onClose,
        children
    } = props;

    // need to check if click outside modal content
    const containerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                onClose?.();
            }
        };
        document.addEventListener(
            'click',
            handleClickOutside,
            true,
        );

        return () => {
            document.removeEventListener(
                'click',
                handleClickOutside,
                true,
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    if (!open) return null

    return (
        <Portal>
            <Overlay>
                <ModalWrapper ref={containerRef}>
                    <CloseModal onClick={onClose}>
                        <img src={closeIcon} alt={closeIcon} />
                    </CloseModal>
                    <Content>
                        {children}
                    </Content>
                </ModalWrapper>
            </Overlay>
        </Portal>
    )
}
