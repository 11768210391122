import styled from "styled-components";
import { breakpoints } from "./size";

export const CompareListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  overflow: scroll;
`;

export const GeneralCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  width: 196px;
`;

export const Content = styled.div`
  padding: 20px 10px 10px;
`;

export const Dropdown = styled.div`
  color: #fff;
`;

export const ImageProfile = styled.div`
  margin-top: 16px;
  position: relative;
  width: fit-content;

  .profle {
    width: 80px;
    height: 80px;

    @media only screen and (${breakpoints.mobile}) {
      width: 44px;
      height: 44px;
    }
  }

  .flag {
    width: 25px;
    height: 25px;
    right: -10px;
    top: -10px;
    position: absolute;

    @media only screen and (${breakpoints.mobile}) {
      width: 15px;
      height: 15px;
      top: -7px;
      right: -7px;
    }
  }
`;

export const PlayerTitle = styled.div`
  margin-bottom: 30px;

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }
`;

export const Title = styled.div`
  display: flex;
  gap: 6px;

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const PlayerCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  width: 273px;
`;

export const Progress = styled.div``;
