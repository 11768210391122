import styled from "styled-components";
import { breakpoints } from "./size";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export const TopList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.needBackGroundColor ? props.theme.sidebar : 'transparent'};

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
    overflow: scroll;
  }
`;

export const TabsWrapper = styled(Tabs)``;

export const TabListWrapper = styled(TabList)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  list-style-type: none;
  overflow: scroll; 
  text-wrap: nowrap; 

  @media only screen and (${breakpoints.mobile}) {
    overflow: scroll;
    white-space: nowrap;
  }
`;

export const TabTitle = styled(Tab)`
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SpaceGrotesk";
  font-size: 19px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 1px;
  text-align: left;
  line-height: 30px;
  text-transform: uppercase;
  outline: none;
  background: transparent;
  cursor: pointer;

  &.react-tabs__tab--selected {
    color: ${(props) => props.theme.green}; !important;
  }
`;

export const TabContent = styled(TabPanel)`
  margin-top: 10px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  span {
    font-family: "SpaceGrotesk";
    color: ${(props) => props.theme.white};
    padding: 0 10px;
  }
`;
export const PageButton = styled.button`
  border-radius: 1px;
  border: none;
  opacity: 1;
  background-color: ${(props) => props.theme.green};
  color: ${(props) => props.theme.black};
  opacity: 1;
  font-family: "SpaceGrotesk";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 24px;
  text-transform: uppercase;
  padding: 10px;

  height: 40px;
  cursor: pointer;
  float: right;
`;
