import React, { useState, useEffect } from "react";
import { breakpoints } from "../../style/size";
import styled from "styled-components";
import searchIcon from "../../images/ic_search.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { $apiV1 } from "../../config/api";

const sizeOptions = {
  medium: {
    width: "221px",
    height: "40px",
  },
  large: {
    width: "423px",
    height: "40px",
  },
  full: {
    width: "100%",
    height: "40px",
  },
};

export const SearchWrapper = styled.div`
  width: -webkit-fill-available;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 13px 10px;

  border-radius: 1px;
  border: 1px solid ${(props) => props.theme.blueish3};
  opacity: 1;

  @media only screen and (${breakpoints.desktop}) {
    width: ${(props) => sizeOptions[props.size].width};
  }

  input {
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    &::placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }

  img {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;

const ResultsDropdown = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.background};
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;

  div {
    padding: 10px 10px 10px 30px;
    cursor: pointer;
    font-family: "SatoshiMedium";
    color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.blueish3};
    &:hover {
      background: ${(props) => props.theme.blueish3};
    }
  }
`;

const Index = ({ size = "large", getComparePlayers }) => {
  const { t } = useTranslation();

  const [text, setText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (text.length >= 1) {
      const fetchResults = async () => {
        try {
          const playerResponse = await $apiV1.get(
            `/platform/player/?search=${text}`
          );

          const players = playerResponse.data.results.map((player) => ({
            ...player,
            type: "player",
          }));

          const combinedResults = [...players];
          setSearchResults(combinedResults);
        } catch (error) {
          console.error("Error fetching search results:", error);
          setSearchResults([]);
        }
      };

      fetchResults();
    } else {
      setSearchResults([]);
    }
  }, [text]);

  const handleNavigate = (result) => {
    // Retrieve the players array from local storage
    const storedPlayers = localStorage.getItem("players");
    let playersArray = [];

    if (storedPlayers) {
      // Parse the existing players array from local storage
      playersArray = JSON.parse(storedPlayers);
    }

    // Add the new player ID to the array
    playersArray.push(result.id);

    // Store the updated array back in local storage
    localStorage.setItem("players", JSON.stringify(playersArray));

    // Perform navigation or other actions
    getComparePlayers();
    setSearchResults([]);
  };

  return (
    <>
      <SearchWrapper size={size}>
        <img src={searchIcon} alt="search icon" />
        <input
          type="text"
          placeholder={t("search.title")}
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        {searchResults.length > 0 && (
          <ResultsDropdown>
            {searchResults.map((result, index) => (
              <div key={index} onClick={() => handleNavigate(result)}>
                {result.name} {result.surname}
              </div>
            ))}
          </ResultsDropdown>
        )}
      </SearchWrapper>
    </>
  );
};

export default Index;
