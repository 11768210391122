import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Style
import {
  CompareSide,
  MainWrapper,
  RightContent,
  TopWrapper,
} from "../../style/DasboardMain";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import CompareSearch from "../../components/inputs/CompareSearch";
import CompareSearchSinglePlayer from "../../components/inputs/CompareSearchSinglePlayer";

// Images
import arrowaUp from "../../images/up.svg";
import arrowDown from "../../images/down.svg";
import emptyState from "../../images/contactLogo.svg";
import starOn from "../../images/star_on.svg";
import starOff from "../../images/star_off.svg";

// Language
import { useTranslation, Trans } from "react-i18next";
import { $apiV1, $apiV2 } from "../../config/api";
import {
  ComparePlayerContent,
  ComparePlayerInfo,
  ComparePlayerInfoContent,
  ComparePlayerLabels,
  ComparePlayerLabelsContent,
  ComparePlayerList,
  CompareWrapper,
  NameWrapper,
  Parameters,
  ParamsLabel,
  PlayerClubInfo,
  PlayerPhoto,
  PlayerSeason,
  ProgressContent,
  SearchPlayer,
  SelectedMatches,
  SelectedMatchesContent,
  SelectedMatchesOptions,
  SelectedMatchesOptionsContent,
} from "../../style/ComparePlayersStyle";

const Index = () => {
  const { t } = useTranslation();
  const [openMatches, setOpenMatches] = useState(false);
  const [listOfPlayers, setListOfPlayers] = useState([]);
  const [favoritePlayers, setFavoritePlayers] = useState({});
  const [selectedMatch, setSelectedMatch] = useState("Main");
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(null);
  const [selectedPlayerSeason, setSelectedPlayerSeason] = useState("");
  const [playerSeasons, setPlayerSeasons] = useState({});

  const getComparePlayers = async () => {
    const storedPlayers = localStorage.getItem("players");

    let comparePlayersList = [];
    if (storedPlayers) {
      try {
        comparePlayersList = JSON.parse(storedPlayers);
        if (!Array.isArray(comparePlayersList)) {
          comparePlayersList = [];
        }
      } catch (error) {
        console.error("Error parsing players from local storage:", error);
      }
    }

    try {
      const response = await $apiV2.post(`/platform/comparison_players/`, {
        players: comparePlayersList.map((player, index) => ({
          player_id: player,
          year_season_id: [
            parseInt(playerSeasons[index], 10) || 134, // Default to 134 if season is not set
          ],
        })),
      });
      setListOfPlayers(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  useEffect(() => {
    getComparePlayers();
  }, [playerSeasons]);

  const toggleFavorite = async (item) => {
    try {
      const isCurrentlyFavorite = favoritePlayers[item.id] ?? false;

      if (isCurrentlyFavorite) {
        await $apiV1.delete(`/users/favorites/players/${item.id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: item.id });
      }

      setFavoritePlayers((prev) => ({
        ...prev,
        [item.id]: !isCurrentlyFavorite,
      }));
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  useEffect(() => {
    getComparePlayers();

    const handleStorageChange = (event) => {
      if (event.key === "players") {
        getComparePlayers();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [selectedPlayerSeason]);

  const selectedMatchList = [
    {
      id: 1,
      name: "Main",
    },
    {
      id: 2,
      name: "Average Matche",
    },
    {
      id: 3,
      name: "Average 90",
    },
  ];

  const handleClickedMatch = (item) => {
    setSelectedMatch(item.name);
    setOpenMatches(!openMatches);
  };

  const handlePlayerSeasonChange = (index, seasonId) => {
    setPlayerSeasons((prevSeasons) => ({
      ...prevSeasons,
      [index]: seasonId, // Use index to keep track of the season
    }));

    console.log(`Player index changed: ${index}`);
    console.log(`New season ID for player at index ${index}: ${seasonId}`);

    getComparePlayers(); // Fetch updated player data
  };

  return (
    <MainWrapper>
      <Navigation />
      <CompareSide>
        <TopWrapper style={{ marginBottom: "15px" }}>
          <IntroText
            smallTitle={t("contactDashboard.smallTitle")}
            title="Search & Compare Players."
          />
          <CompareSearch getComparePlayers={getComparePlayers} />
        </TopWrapper>

        <CompareWrapper>
          <ComparePlayerLabels>
            <ComparePlayerLabelsContent>
              <SelectedMatchesContent>
                <SelectedMatches onClick={() => setOpenMatches(!openMatches)}>
                  <h3>{selectedMatch ?? "Selected Match"}</h3>
                  <img
                    src={openMatches ? arrowaUp : arrowDown}
                    alt={openMatches ? arrowaUp : arrowDown}
                  />
                </SelectedMatches>

                {openMatches && (
                  <SelectedMatchesOptions>
                    <SelectedMatchesOptionsContent>
                      <ul>
                        {selectedMatchList.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => handleClickedMatch(item)}
                            >
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </SelectedMatchesOptionsContent>
                  </SelectedMatchesOptions>
                )}
              </SelectedMatchesContent>

              <ParamsLabel>
                <ul>
                  {listOfPlayers[0]?.params[0]?.values.map((item, index) => {
                    return (
                      <li key={index}>
                        {item.parameter_name}
                      </li>
                    );
                  })}
                  <li></li>
                </ul>
              </ParamsLabel>
            </ComparePlayerLabelsContent>
          </ComparePlayerLabels>
          <ComparePlayerList>
            <ComparePlayerContent>
              {listOfPlayers?.map((item, index) => {
                const isFavorite = favoritePlayers[item.id] ?? false;

                return (
                  <ComparePlayerInfo key={index} id={index}>
                    <ComparePlayerInfoContent>
                      <CompareSearchSinglePlayer
                        id={item.id}
                        getComparePlayers={getComparePlayers}
                      />
                      <PlayerPhoto>
                        <img
                          className="playerPhoto"
                          src={
                            item.photos.length > 0
                              ? `https://platform.smrtstats.com:8888/${item.photos[0].image}`
                              : emptyState
                          }
                          alt={emptyState}
                        />
                        <img
                          className="flag"
                          src={
                            item.nationality.length > 0
                              ? `https://platform.smrtstats.com:8888/${item.nationality[0].flag}`
                              : emptyState
                          }
                        />
                      </PlayerPhoto>
                      <PlayerClubInfo>
                        {item.team ? <h4>{item.team.name}</h4> : <h4>/</h4>}
                        <NameWrapper>
                          <h2
                            data-tooltip-id={`full-name-${item.name}-${item.surname}`}
                          >
                            {item.name} {item.surname}
                          </h2>
                          <ReactTooltip
                            id={`full-name-${item.name}-${item.surname}`}
                            place="bottom"
                            content={`${item.name} ${item.surname}`}
                          />
                          <img
                            src={isFavorite ? starOn : starOff}
                            alt="Favorite star"
                            style={{
                              width: "18px",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleFavorite(item)}
                          />
                        </NameWrapper>
                      </PlayerClubInfo>

                      <PlayerSeason>
                        <select
                          onChange={(e) =>
                            handlePlayerSeasonChange(index, e.target.value)
                          }
                          value={playerSeasons[index] || ""} // Use index to get the season
                        >
                          {item.available_year_seasons?.map((season) => (
                            <option key={season.id} value={season.id}>
                              Season {season.years}
                            </option>
                          ))}
                        </select>
                      </PlayerSeason>

                      <Parameters>
                        {item?.params[0].values.map((param, index) => (
                          <React.Fragment key={index}>
                            {selectedMatch === "Main" && (
                              <ProgressContent>
                                <progress
                                  id="bar"
                                  value={param.value.main}
                                  max="100"
                                >
                                  {param.value.main}%
                                </progress>
                                <h4>
                                  {param.value.main
                                    ? `${Math.round(param.value.main)}`
                                    : "-"}
                                </h4>
                              </ProgressContent>
                            )}
                            {selectedMatch === "Average Matche" && (
                              <ProgressContent>
                                <progress
                                  id="bar"
                                  value={param.value.average}
                                  max="100"
                                >
                                  {param.value.average}%
                                </progress>
                                <h4>
                                  {param.value.average
                                    ? `${Math.round(param.value.average)}`
                                    : "-"}
                                </h4>
                              </ProgressContent>
                            )}
                            {selectedMatch === "Average 90" && (
                              <ProgressContent>
                                <progress
                                  id="bar"
                                  value={param.value.average_90}
                                  max="100"
                                >
                                  {param.value.average_90}%
                                </progress>
                                <h4>
                                  {param.value.average_90
                                    ? `${Math.round(param.value.average_90)}`
                                    : "-"}
                                </h4>
                              </ProgressContent>
                            )}
                          </React.Fragment>
                        ))}
                      </Parameters>
                    </ComparePlayerInfoContent>
                  </ComparePlayerInfo>
                );
              })}
            </ComparePlayerContent>
          </ComparePlayerList>
        </CompareWrapper>
      </CompareSide>
    </MainWrapper>
  );
};

export default Index;
