import React, { useState } from "react";
import styled from "styled-components";
import eyeOpen from "../../images/on.svg";
import eyeClosed from "../../images/off.svg";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;

  label {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    padding-left: 5px;
  }

  input {
    background: transparent;
    border: none;
    border-bottom: 1px solid ${(props) =>
      props.error
        ? (props) => props.theme.red
        : (props) =>
            props.theme.blueish2}; /* Changed border color based on error */
    outline: none;
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding-left: 5px;
    padding-bottom: 4px;

    &:placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiRegular";
      font-size: 16px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }

    &:disabled {
      color: ${(props) => props.theme.blueish2}; !important;
    }
  }

  .eye-icon {
    position: absolute;
    right: 10px;
    bottom: 14px;
    cursor: pointer;
  }
`;

export const BottomText = styled.div`
  font-size: 12px;
  opacity: 1;
  color: ${(props) =>
    props.error ? (props) => props.theme.red : (props) => props.theme.blueish2};
  font-family: "SatoshiRegular";
  font-size: 12px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 18px;
  padding-left: 5px;
  margin-top: -20px;
`;

const PasswordInput = ({
  label,
  placeholder,
  value,
  bottomText,
  onChange,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <InputWrapper error={error}>
        <label>{label}</label>
        <input
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className={error ? "errorStyle" : ""}
        />
        <div>
          <img
            src={showPassword ? eyeClosed : eyeOpen}
            alt={showPassword ? "Hide password" : "Show password"}
            className="eye-icon"
            onClick={togglePasswordVisibility}
          />
        </div>
      </InputWrapper>
      {!error && (
        <BottomText className="bottom-text" error={error}>
          {bottomText}
        </BottomText>
      )}
      {error && (
        <BottomText className="bottom-text" error={error}>
          {error}
        </BottomText>
      )}
    </>
  );
};

export default PasswordInput;
