import React, { useEffect, useState } from "react";

// Style
import {
  CheckBoxWrapper,
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Search from "../../components/inputs/Search";
import MyPlaylist from "../../components/table/MyPlaylist";
import LinkTable from "../../components/table/LinkTable";

// Images
import englishFlag from "../../images/ic_eng_flag.svg";
import germanyFlag from "../../images/ic_germany_flag.svg";
import profile1 from "../../images/img_league.svg";
import profile2 from "../../images/img_league2.svg";

import search from "../../images/ic_search.svg";
import {
  TabsWrapper,
  TabListWrapper,
  TabTitle,
  TabContent,
  TopList,
} from "../../style/Favorites";
import Checkbox from "../../components/inputs/Checkbox";

// Language
import { useTranslation } from "react-i18next";
import { $apiV1 } from "../../config/api";

const Index = () => {
  const { t, i18n } = useTranslation();
  const [playlist, setPlaylist] = useState("");
  const [sharedList, setSharedList] = useState("");

  const getMyPlaylist = async () => {
    try {
      const response = await $apiV1.get(`/users/playlists/`);
      setPlaylist(response?.data);
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const getMyPlaylistShard = async () => {
    try {
      const response = await $apiV1.get(`/users/user_shared_playlists/`,
        { params: { page: 1 } }
      );
      setSharedList(response?.data);
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  useEffect(() => {
    getMyPlaylist();
    getMyPlaylistShard();
  }, []);

  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener("navigationOpenChange", handleNavigationOpenChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle={t("playlist.smallTitle")}
            title={t("playlist.title")}
          />
        </TopWrapper>

        <TabsWrapper>
          <TopList>
            <TabListWrapper>
              <TabTitle>{t("playlist.myPlaylist")}</TabTitle>
              <TabTitle>{t("playlist.sharedPlaylist")}</TabTitle>
            </TabListWrapper>
          </TopList>

          <TabContent>
            <MyPlaylist table={playlist} getMyPlaylist={getMyPlaylist} />
          </TabContent>
          <TabContent>
            <LinkTable table={sharedList} />
          </TabContent>
        </TabsWrapper>
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Index;
