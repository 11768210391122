import React from 'react'
import styled from 'styled-components'

const StyledInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
        color: ${props => props.theme.blueish2};
        font-family: "SatoshiMedium";
        font-size: 14px;
    }
`

const StyledInput = styled.input`
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 2px;
    font-family: "SatoshiMedium";
    font-size: 14px;
`

export const Input = (props) => {
    return (
        <StyledInputContainer>
            {props.label && <label>{props.label}</label>}
            <StyledInput {...props} />
        </StyledInputContainer>
    )
}
