import React from "react";
import { ParamCheckboxStyle } from "./Index";

const ParamFilter = ({ paramsList, isSelected, handleParameterSelect }) => {
  return (
    <>
      {paramsList?.map(([key]) => (
        <ParamCheckboxStyle key={key}>
          <input
            type="checkbox"
            checked={isSelected(key)}
            onChange={() => handleParameterSelect(key)}
          />
          <label style={{ color: "#fff", marginLeft: "8px" }}>{key}</label>
        </ParamCheckboxStyle>
      ))}
    </>
  );
};

export default ParamFilter;
