import styled from "styled-components";
import { breakpoints } from "./size";

import rightImage from "../images/authImage.webp";
import backgroundImage from "../images/authBackground.webp";

export const AuthWrapper = styled.div`
  background: ${(props) => props.theme.black};
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media only screen and (${breakpoints.mobile}) {
    background: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
`;

export const Content = styled.div`
  padding: 60px 60px 20px;
  height: 90vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (${breakpoints.mobile}) {
    height: fit-content;
    padding: 40px 20px;
  }
`;

export const ContentLogin = styled.div`
  padding: 60px 60px 20px;
  height: 90vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (${breakpoints.mobile}) {
    height: 100vh;
    padding: 40px 20px;
    // gap: 200px;
  }
`;

export const ContentReset = styled.div`
  padding: 60px 60px 20px;
  height: 90vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (${breakpoints.mobile}) {
    height: fit-content;
    padding: 40px 20px;
    gap: 200px;
  }
`;

export const FiedlWrapper = styled.div`
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const EmptyColumn = styled.div`
  @media only screen and (${breakpoints.mobile}) {
    display: none;
  }
`;

export const FormWrapper = styled.div`
  width: 40%;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .logo {
    margin-bottom: 40px;
    width: fit-content;
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 32px;
      line-height: 46px;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;
    margin-bottom: 20px;

    strong {
      font-family: "SatoshiBold";
    }

    a {
      font-family: "SatoshiRegular";
      font-size: 20px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 28px;
      color: ${(props) => props.theme.green};
    }

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

export const ConfirmWrapp = styled.div`
  margin-top: 20px;

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0.3px;
    text-align: left;
    line-height: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 2px;

    @media only screen and (${breakpoints.mobile}) {
      flex-wrap: wrap;
    }
  }
`;

export const LinkWrap = styled.div`
  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 60px;

    @media only screen and (${breakpoints.mobile}) {
      flex-wrap: wrap;
      margin-top: 40px;
      font-size: 16px;
      line-height: 15px;
      gap: 3px;

      a {
        font-size: 16px;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  background: url(${rightImage});
  background-repeat: no-repeat;
  background-size: cover;
  width: 60%;

  @media only screen and (${breakpoints.mobile}) {
    display: none;
  }
`;
