import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token || token === 'undefined') {
      setIsLoggedIn(false);
      localStorage.removeItem('token');
      return navigate('/login');
    }
    setIsLoggedIn(true);
  }, [navigate])

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn, checkUserToken]);

  return (
    <>
      {
        isLoggedIn ? children : null
      }
    </>
  );
}
