import styled from "styled-components";
import { breakpoints } from "./size";

export const TermsWrapper = styled.div`
  background: ${(props) => props.theme.black};
  padding: 40px 0px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 40px 20px;
  }

  img {
    display: flex;
    margin: auto;
  }
`;

export const TermContent = styled.div`
  width: 877px;
  margin: 40px auto;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 32px;
      line-height: 46px;
    }
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    margin: 40px 0 4px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 16px;
      line-height: 24px;
    }

    a {
      font-family: "SatoshiRegular";
      color: ${(props) => props.theme.green};
    }
  }
`;
