import React, { useCallback, useEffect, useRef, useState } from 'react'
import './TimelineChange.css';
import { SiderCurrentValue, SiderInput, SliderMark, SliderRange, TimelineSlider } from './TimelineComponents';

export const TimelineChange = ({ min = 0, max = 90, onChange, styleContainer = {} }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const range = useRef(null);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);

    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value);

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    useEffect(() => {
        onChange({ min: minVal, max: maxVal });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minVal, maxVal]);

    const handleMinChange = (e) => {
        const value = Math.min(+e.target.value, maxVal);
        setMinVal(value);
        minValRef.current.value = value;
    };

    const handleMaxChange = (e) => {
        const value = Math.max(+e.target.value, minVal);
        setMaxVal(value);
        maxValRef.current.value = value;
    };

    const marks = [];
    for (let i = min; i <= max; i += 15) {
        marks.push(i);
    }

    return (
        <TimelineSlider style={styleContainer}>
            <SliderRange ref={range} />
            {marks.map((mark, index) => (
                <SliderMark
                    key={index}
                    style={{ left: `${getPercent(mark) - (index === 0 ? 0 : index === marks.length - 1 ? 0.6 : 0.7)}%` }}
                >
                    <p style={{ left: index === marks.length - 1 ? -35 : -10 }}
                    >{mark === 0 ? '00' : mark}:00</p>
                    <span
                        style={{
                            left: index === 0 || index === marks.length - 1 ? 0 : 3
                        }}
                    />
                </SliderMark>
            ))}
            <SiderInput
                type="range"
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={handleMinChange}
            />
            <SiderInput
                type="range"
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={handleMaxChange}
            />
            <SiderCurrentValue
                style={{ left: `${getPercent(minVal) - (minVal === 0 ? 0 : 2)}%` }}
            >
                {minVal}:00
            </SiderCurrentValue>
            <SiderCurrentValue
                style={{ left: `${getPercent(maxVal) - (maxVal >= 88 ? 6 : 3)}%` }}
            >
                {maxVal}:00
            </SiderCurrentValue>
        </TimelineSlider>
    );
};
