import React from "react";

// Style
import "../../style/Terms";
import { TermsWrapper, TermContent } from "../../style/Terms";

// Images
import logo from "../../images/ic_logo.svg";

const index = () => {
  return (
    <TermsWrapper>
      <a href="/">
        <img src={logo} alt={logo} />
      </a>
      <TermContent>
        <h1>Terms and Conditions</h1>
        <h3>
          Founded in 2022 by experts with collective 30+ years of experience in
          sports performance analysis and scouting industry. We see our mission
          as servicing the professional sports' organisations with the highest
          level data driven tools. We have already launched our online video
          platform for Performance Analysis & Scouting!
        </h3>

        <h2>Some label</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          sit amet tempus neque. Sed porttitor dui eget neque egestas, a
          ullamcorper lorem ultricies. Mauris vitae mollis velit, id scelerisque
          eros. In interdum eget ligula non pulvinar. Nullam mollis feugiat
          tortor. Donec in mauris in turpis interdum consectetur gravida ac
          neque. Mauris malesuada placerat enim ut accumsan. Nunc lacus magna,{" "}
          <a href="/">ornare</a> non ullamcorper tristique, aliquet{" "}
          <a href="/">ornare</a> risus. Sed consectetur mauris luctus neque
          aliquet, et rutrum dui mollis. Duis faucibus facilisis massa, sed
          finibus justo condimentum eget. In efficitur purus eu ultricies
          bibendum. In hac habitasse platea dictumst. Donec lorem sem, pretium
          et lectus in, facilisis elementum mi. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Suspendisse sit amet tempus neque. Sed
          porttitor dui eget neque egestas, a ullamcorper lorem ultricies.
          Mauris vitae mollis velit, id scelerisque eros. In interdum eget
          ligula non pulvinar. Nullam mollis feugiat tortor. Donec in mauris in
          turpis interdum consectetur gravida ac neque. Mauris males
        </p>

        <h2>Some label</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          sit amet tempus neque. Sed porttitor dui eget neque egestas, a
          ullamcorper lorem ultricies. Mauris vitae mollis velit, id scelerisque
          eros. In interdum eget ligula non pulvinar. Nullam mollis feugiat
          tortor. Donec in mauris in turpis interdum consectetur gravida ac
          neque. Mauris malesuada placerat enim ut accumsan. Nunc lacus magna,
          ornare non ullamcorper tristique, aliquet ornare risus. Sed
          consectetur mauris luctus neque aliquet, et rutrum dui mollis. Duis
          faucibus facilisis massa, sed finibus justo condimentum eget. In
          efficitur purus eu ultricies bibendum. In hac habitasse platea
          dictumst. Donec lorem sem, pretium et lectus in, facilisis elementum
          mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Suspendisse sit amet tempus neque. Sed porttitor dui eget neque
          egestas, a ullamcorper lorem ultricies. Mauris vitae mollis velit, id
          scelerisque eros. In interdum eget ligula non pulvinar. Nullam mollis
          feugiat tortor. Donec in mauris in turpis interdum consectetur gravida
          ac neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Suspendisse sit amet tempus neque. Sed porttitor dui eget neque
          egestas, a ullamcorper lorem ultricies. Mauris vitae mollis velit, id
          scelerisque eros. In interdum eget ligula non pulvinar. Nullam mollis
          feugiat tortor. Donec in mauris in turpis interdum consectetur gravida
          ac neque. Mauris malesuada placerat enim ut accumsan. Nunc lacus
          magna, ornare non ullamcorper tristique, aliquet ornare risus. Sed
          consectetur mauris luctus neque aliquet, et rutrum dui mollis. Duis
          faucibus facilisis massa, sed finibus justo condimentum eget. In
          efficitur purus eu ultricies bibendum. In hac habitasse platea
          dictumst. Donec lorem sem, pretium et lectus in, facilisis elementum
          mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Suspendisse sit amet tempus neque. Sed porttitor dui eget neque
          egestas, a ullamcorper lorem ultricies. Mauris vitae mollis velit, id
          scelerisque eros. In interdum eget ligula non pulvinar. Nullam mollis
          feugiat tortor. Donec in mauris in turpis interdum consectetur gravida
          ac neque. Mauris males
        </p>
      </TermContent>
    </TermsWrapper>
  );
};

export default index;
