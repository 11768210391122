import React, { useEffect, useMemo, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";
import Empty from "../../components/empty/index";
import Filter, { FormWrap } from "../modal/Filter";
import logo from "../../images/contactLogo.svg";
import more from "../../images/ic_more.svg";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../button/index";
import {
  Action,
  Bottom,
  BottomStats,
  LeftTable,
  MatchContent,
  MatchInfo,
  MatchTableWrap,
  RightTable,
  Score,
  TeamMatches,
  TeamName,
  TeamScore,
  Top,
  TopStats,
  ScrollableContainer,
  StyledTable,
  TableButtons,
} from "../../style/MatchTable";
import Moment from "react-moment";
import TextInput, { InputWrapper } from "../inputs/TextInput";
import emptySearch from "../../images/ic_empty.svg";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import styled from "styled-components";
import { $apiV1 } from "../../config/api";
import { EmptyState } from "../../style/Tournaments";
import { Formation } from "../Formation";
import { ParametersSelector } from "../ParametersSelector";
import {
  Dropdown,
  DropdownButton,
  DropdownContent,
  DropdownItem,
} from "../../pages/tournametns/team/EventsMatches";
import { getGroupedAndSortedParametrs } from "../../helpers";

import play from "../../images/ic_play.svg";
import playMatchBlue from "../../images/play_match_blue.svg";
import playInBall from "../../images/ic_playinball.svg";
import playInBallBlue from "../../images/playInBall_blue.svg";
import statistics from "../../images/ic_statistics.svg";
import statisticsBlue from "../../images/statistics_blue.svg";
import formation from "../../images/ic_formation.svg";
import formationBlue from "../../images/formation_blue.svg";

export const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const OpenedDropdown = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: -7px;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  max-height: 200px;
  overflow: scroll;

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: #00000012;
    }
  }
`;

export const ParamCheckboxStyle = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 10px !important;

  label {
    opacity: 1;
    color: ${(props) => props.theme.blueish} !important;
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.green}; /* Green color */
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;

    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 0.6em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 21%, 80% 0, 43% 62%);
    transform: scale(0);
    transition: transform 0.15s ease-in-out;
    background-color: ${(props) =>
      props.theme.green}; /* Green color for the check */
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
`;

export const ParamsWrap = styled.div`
  position: relative;
`;

const SmrtSearchTable = ({
  table,
  all,
  statMathc,
  team,
  onSeasonChange,
  season,
  setStatMatch,
}) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const { t, i18n } = useTranslation();
  const [openRow, setOpenRow] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [availableSeasons, setAvailableSeason] = useState(
    all?.available_seasons || []
  );
  const [choosenSeasson, setChoosenSeasson] = useState(season);
  const [competitions, setCompetitions] = useState(all?.matches_played || []);
  const [choosenCompetition, setChoosenCompetition] = useState("");
  const [mathcesList, setMatchesList] = useState("");
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [lastMatches, SetLastMatches] = useState("");

  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    checkedIds: [],
    params: [],
  });

  const [open, setOpen] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);
  const [openMatchesCount, setOpenMatchesCount] = useState(false);
  const [openParams, setOpenParams] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedCompetition, setSelectedCompetition] = useState("");
  const [selectedMatch, setSelectedMatch] = useState("");

  //formation state
  const [openFormation, setOpenFormation] = useState(false);
  const [selectedFormationMatch, setSelectedFormationMatch] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);

  const [selectedMathes, setSelectedMathes] = useState(
    statMathc?.map((item) => item.id) || []
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getMathesWithSortedParams = (matches) => {
      const newArray = matches?.map((match) => {
        return {
          ...match,
          parameters: match.parameters.sort(
            (a, b) => a.parameter_id - b.parameter_id
          ),
        };
      });
      setFilteredMatches(newArray);
    };
    getMathesWithSortedParams(statMathc);
    setSelectedMathes(statMathc?.map((item) => item.id));
  }, [statMathc]);

  const handleCloseFormation = () => {
    setOpenFormation(false);
    setSelectedFormationMatch(null);
  };

  const handleOpenFormation = (match) => {
    setSelectedFormationMatch(match);
    setOpenFormation(true);
  };

  const handleClearFilter = () => {
    setChoosenSeasson();
    setSelectedSeason("");
    setCompetitions();
    setSelectedCompetition("");
    SetLastMatches();
    setSelectedMatch("");
    setTempSelectedFilters({ checkedIds: [], params: [] });
    setSelectedMathes([]);
  };

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setOpen(false);
    handleSeasons();
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(item.competition__id.toString());
    setSelectedCompetition(item.title);
    setOpenCompetition(false);
    const filteredMatches = mathcesList
      ?.filter((match) =>
        item.competition__id.toString()
          ? match.competition_id === item.competition__id.toString()
          : true
      )
      .map((match) => match.id);

    setMatchesList(filteredMatches);
  };

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
    setSelectedMathes(
      filteredMatches?.slice(0, item.value)?.map((item) => item.id)
    );
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSeasons = async () => {
    setLoading(true);
    onSeasonChange?.(choosenSeasson, tempSelectedFilters.checkedIds)
      .then(async (res) => {
        const response = await $apiV1.get(
          `/statistics/team/${id}?year_season_id=${choosenSeasson}`
        );
        setAvailableSeason(response.data.available_seasons);
        setCompetitions(response.data.matches_played);

        const filteredMatches = res?.data
          ?.filter((match) =>
            choosenCompetition
              ? match.competition_id === choosenCompetition
              : true
          )
          .map((match) => match.id);

        setMatchesList(filteredMatches);

        // Filter matches to show based on the updated list
        const matchesToShow = response.data.matches_list.filter((match) =>
          filteredMatches.includes(match.id)
        );
      })
      .catch((error) => {
        console.error("Error fetching players:", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const setParams = async () => {
      try {
        let params = null;
        if (sessionStorage.getItem("teamParameters")) {
          params = JSON.parse(sessionStorage.getItem("teamParameters"));
          const checkedIds = params.flatMap((group) =>
            group[1].filter((item) => item.checked).map((item) => item.id)
          );
          const checkedParams = params.flatMap((group) =>
            group[1].filter((item) => item.checked).map((item) => item.name)
          );
          setTempSelectedFilters({ checkedIds, params: checkedParams });
        } else {
          const response = await $apiV1.get("/stats/parameters/team/");
          const data = response.data?.results;
          if (data) {
            params = getGroupedAndSortedParametrs(data);
            const checkedIds = params.flatMap((group) =>
              group[1].map((item) => item.id)
            );
            const checkedParams = params.flatMap((group) =>
              group[1].map((item) => item.name)
            );
            setTempSelectedFilters({ checkedIds, params: checkedParams });
          }
        }
      } catch (e) {
        console.log(`Error in receiving team parameters. ${e.message}`);
      }
    };
    setParams();
  }, []);

  const handleSearch = async () => {
    if (mathcesList.length > 0) {
      const updatedFilteredMatches = statMathc.filter((match) =>
        mathcesList.includes(match.id)
      );
      setFilteredMatches(updatedFilteredMatches);
    }

    setOpenFilter(false);
  };

  const tableLeftNames = [
    { id: 1, name: "Opponents" },
    { id: 2, name: "Score" },
  ];

  const setMoreInfo = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const handleFilterChange = (filter) => {
    console.log(filter);
    setTempSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((item) => item !== filter)
        : [...prevFilters, filter]
    );
  };

  const uniqueParams = statMathc?.reduce((acc, match) => {
    match.parameters?.forEach((param) => {
      if (!acc.includes(param.parameter_name)) {
        acc.push(param.parameter_name);
      }
    });
    return acc;
  }, []);

  const displayFilters = () => {
    if (tempSelectedFilters?.params?.length === 0) {
      return "Choose params";
    }

    const firstTwo = tempSelectedFilters?.params?.slice(0, 2)?.join(", ");
    const additionalCount = tempSelectedFilters?.params?.length - 2;
    return additionalCount > 0 ? `${firstTwo}, +${additionalCount}` : firstTwo;
  };

  const matches = [
    {
      id: 1,
      value: filteredMatches?.length,
      title: "All matches",
    },
    {
      id: 2,
      value: 3,
      title: "Last 3 matches",
    },
    {
      id: 3,
      value: 5,
      title: "Last 5 matches",
    },
    {
      id: 4,
      value: 10,
      title: "Last 10 matches",
    },
  ];

  const currentSelectedSeason = useMemo(() => {
    if (selectedSeason) return selectedSeason;
    if (availableSeasons?.length) {
      const foundedSeason = availableSeasons?.find((item) => item.id == season);
      if (season && foundedSeason) {
        return foundedSeason?.years;
      }
    }
    return "Select Season";
  }, [season, selectedSeason, availableSeasons]);

  const onBallEventsClick = (match, new_tab) => {
    if (match?.parameters?.length > 0) {
      const obe = match.parameters.find(
        (item) =>
          item.parameter_name === "On-Ball Events" || item.parameter_id === 13
      );
      if (obe?.markers?.length > 0 && new_tab) {
        window.open(
          `/tournaments/match/video?markers=${obe?.markers?.join(",")}`,
          "_blank"
        );
      } else {
        navigate(`/tournaments/match/video?markers=${obe?.markers?.join(",")}`);
      }
    }
  };

  function handleChangeParams(params) {
    const checkedIds = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.id)
    );
    const checkedParams = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.name)
    );
    setTempSelectedFilters({ checkedIds, params: checkedParams });
    onSeasonChange?.(choosenSeasson, checkedIds);
  }

  const handleSelectionChange = (id) => {
    setSelectedMathes((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const [loadDownloadXml, setDownloadXml] = useState(false);
  const [loadDownloadXls, setDownloadXls] = useState(false);

  const dowloadXls = async () => {
    setDownloadXls(true);
    try {
      const matches = selectedMathes || [];
      const params = tempSelectedFilters.checkedIds || [];
      const response = await $apiV1.post(`/statistics/team_matches/${id}`, {
        matches,
        params,
      });
      if (response.data.report) {
        const a = document.createElement("a");
        a.href = response.data.report;
        a.download =
          response.data.report.split("/")[
            response.data.report.split("/").length - 1
          ];
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDownloadXls(false);
    }
  };

  const dowloadXml = async () => {
    setDownloadXml(true);
    try {
      const matches = selectedMathes || [];
      const params = tempSelectedFilters.checkedIds || [];
      const response = await $apiV1.post(
        `/statistics/team_matches_xml/${id}`,
        {
          matches,
          params,
        },
        { responseType: "blob" }
      ); // Ensure the response is treated as a binary blob

      if (response.data) {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = response.headers["content-disposition"]
          ? response.headers["content-disposition"].split("filename=")[1]
          : "file.xml"; // Default file name if not provided
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(fileUrl); // Clean up the URL object
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDownloadXml(false);
    }
  };

  const getColorScore = (item) => {
    const isTeamHome = item.home_team === team;
    if (isTeamHome) {
      return item.home_team_score > item.away_team_score
        ? "#36E82D"
        : item.home_team_score < item.away_team_score
        ? "#F20A46"
        : "#E8D02D";
    } else {
      return item.home_team_score > item.away_team_score
        ? "#F20A46"
        : item.home_team_score < item.away_team_score
        ? "#36E82D"
        : "#E8D02D";
    }
  };

  return (
    <>
      {isMobile && (
        <TableButtons>
          <div className="buttonFilterContainer">
            <button className="buttonFilter" onClick={dowloadXml}>
              {loadDownloadXml ? "Downloading" : "Download in XML"}
            </button>
            <button className="buttonFilter" onClick={dowloadXls}>
              {loadDownloadXls ? "Downloading" : "Download in XLS"}
            </button>
            <button
              className="buttonFilter"
              onClick={() => setOpenFilter(!openFilter)}
            >
              {t("smrtSearch.filter")}
            </button>
          </div>
        </TableButtons>
      )}
      <MatchTableWrap>
        {!statMathc?.length ? (
          <>
            <EmptyState>
              <img src={emptySearch} alt={emptySearch} />
              <h3>No results for matches</h3>
            </EmptyState>
            <RightTable style={{ width: "auto" }}>
              <button
                className="buttonFilter absolute"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {t("smrtSearch.filter")}
              </button>
            </RightTable>
          </>
        ) : (
          <>
            <LeftTable>
              <Top>
                <ul>
                  {tableLeftNames.map((item, index) => (
                    <li key={index}>{item.name}</li>
                  ))}
                </ul>
              </Top>
              <Bottom>
                {filteredMatches
                  ?.filter((item) =>
                    selectedMathes?.length > 0
                      ? selectedMathes.includes(item.id)
                      : true
                  )
                  ?.map((item, index) => (
                    <TeamMatches key={index}>
                      <TeamName>
                        {item?.away_team === team ? (
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                            }}
                            src={item?.home_team_logo}
                            alt={logo}
                          />
                        ) : (
                          ""
                        )}

                        {item?.home_team === team ? (
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                            }}
                            src={item?.away_team_logo}
                            alt={logo}
                          />
                        ) : (
                          ""
                        )}

                        <Score>
                          <h3>
                            <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                          </h3>
                          <h4>
                            {item?.away_team === team
                              ? item?.home_team
                              : item?.away_team}
                          </h4>
                        </Score>
                      </TeamName>
                      <TeamScore style={{ position: "relative" }}>
                        <span
                          style={{
                            color: getColorScore(item),
                          }}
                        >
                          {item?.score}
                        </span>
                        <img
                          className="more"
                          src={more}
                          alt={more}
                          onClick={() => setMoreInfo(index)}
                        />
                        {openRow === index && (
                          <MatchInfo>
                            <MatchContent>
                              <Action
                                onClick={() =>
                                  navigate(
                                    `/tournaments/match/video/${item.id}`
                                  )
                                }
                                onContextMenu={() =>
                                  window.open(
                                    `/tournaments/match/video/${item.id}`,
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? playMatchBlue
                                      : play
                                  }
                                  alt={play}
                                />
                                <h4>Play Full Match</h4>
                              </Action>
                              <Action
                                onClick={() => onBallEventsClick(item)}
                                onContextMenu={() =>
                                  onBallEventsClick(item, true)
                                }
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? playInBallBlue
                                      : playInBall
                                  }
                                  alt={playInBall}
                                />
                                <h4>OBE</h4>
                              </Action>
                              <Action
                                onClick={() =>
                                  navigate(`/tournaments/statistics/${item.id}`)
                                }
                                onContextMenu={() =>
                                  window.open(
                                    `/tournaments/statistics/${item.id}`,
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? statisticsBlue
                                      : statistics
                                  }
                                  alt={statistics}
                                />
                                <h4>See Match Statistics</h4>
                              </Action>
                              <Action
                                onClick={() => handleOpenFormation(item)}
                                onContextMenu={() => handleOpenFormation(item)}
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? formationBlue
                                      : formation
                                  }
                                  alt={formation}
                                />
                                <h4>See Match Formation</h4>
                              </Action>
                            </MatchContent>
                          </MatchInfo>
                        )}
                      </TeamScore>
                    </TeamMatches>
                  ))}
              </Bottom>
            </LeftTable>

            <RightTable>
              {!isMobile && (
                <div className="buttonFilterContainer">
                  <button className="buttonFilter" onClick={dowloadXml}>
                    {loadDownloadXml ? "Downloading" : "Download in XML"}
                  </button>
                  <button className="buttonFilter" onClick={dowloadXls}>
                    {loadDownloadXls ? "Downloading" : "Download in XLS"}
                  </button>
                  <button
                    className="buttonFilter"
                    onClick={() => setOpenFilter(!openFilter)}
                  >
                    {t("smrtSearch.filter")}
                  </button>
                </div>
              )}

              <ScrollableContainer>
                <StyledTable>
                  <thead>
                    {filteredMatches?.length > 0 && (
                      <>
                        <tr key={filteredMatches[1]?.id}>
                          {filteredMatches
                            ?.find((item) => item.parameters?.length > 0)
                            ?.parameters?.map((param, index) => (
                              <th key={index} data-tooltip-id={`name-${index}`}>
                                {param.parameter_name}
                                <ReactTooltip
                                  id={`name-${index}`}
                                  place="bottom"
                                  content={param.parameter_name}
                                />
                              </th>
                            ))}
                        </tr>
                      </>
                    )}
                  </thead>

                  <tbody>
                    {tempSelectedFilters?.checkedIds?.length === 0
                      ? null
                      : filteredMatches
                          ?.filter((item) => selectedMathes.includes(item.id))
                          ?.map((match) => {
                            return (
                              <tr key={match.id}>
                                {match.parameters?.map((param) => {
                                  return (
                                    <td
                                      key={param.parameter_id}
                                      style={{
                                        cursor:
                                          param.markers !== "" ? "pointer" : "",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/tournaments/match/video?markers=${param.markers?.join(
                                            ","
                                          )}`
                                        )
                                      }
                                    >
                                      {param.value}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                  </tbody>
                </StyledTable>
              </ScrollableContainer>
            </RightTable>
          </>
        )}
      </MatchTableWrap>

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        onClick={() => handleClearFilter()}
      >
        <FormWrap>
          {availableSeasons && (
            <DropdownWrapper>
              <div style={{ position: "relative" }}>
                <TextInput
                  type="text"
                  value={currentSelectedSeason}
                  onClick={() => setOpen(!open)}
                  readOnly
                />

                <img
                  src={open ? arrowIconUp : arrowIconDown}
                  alt={arrowIconDown}
                  onClick={() => setOpen(!open)}
                  style={{
                    color: "#fff",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                  }}
                />
              </div>
              {open && (
                <OpenedDropdown>
                  {availableSeasons?.map((item, index) => (
                    <div key={index} onClick={() => handleSeasonChoose(item)}>
                      {item.years}
                    </div>
                  ))}
                </OpenedDropdown>
              )}
            </DropdownWrapper>
          )}

          {competitions && (
            <DropdownWrapper>
              <div style={{ position: "relative" }}>
                <TextInput
                  type="text"
                  value={
                    selectedCompetition.length == 0
                      ? "Select Competition"
                      : selectedCompetition
                  }
                  onClick={() => setOpenCompetition(!openCompetition)}
                  readOnly
                />

                <img
                  src={openCompetition ? arrowIconUp : arrowIconDown}
                  alt={arrowIconDown}
                  onClick={() => setOpenCompetition(!openCompetition)}
                  style={{
                    color: "#fff",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                  }}
                />
              </div>
              {openCompetition && (
                <OpenedDropdown>
                  {competitions?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleCompetitionChoose(item)}
                    >
                      {item.title}
                    </div>
                  ))}
                </OpenedDropdown>
              )}
            </DropdownWrapper>
          )}

          <DropdownWrapper>
            <div style={{ position: "relative" }}>
              <TextInput
                type="text"
                value={selectedMatch.length == 0 ? "PRE-SET" : selectedMatch}
                onClick={() => setOpenMatchesCount(!openMatchesCount)}
                readOnly
              />

              <img
                src={openMatchesCount ? arrowIconUp : arrowIconDown}
                alt={arrowIconDown}
                onClick={() => setOpenMatchesCount(!openMatchesCount)}
                style={{
                  color: "#fff",
                  position: "absolute",
                  right: 0,
                  cursor: "pointer",
                }}
              />
            </div>

            {openMatchesCount && (
              <OpenedDropdown>
                {matches?.map((item, index) => (
                  <div key={index} onClick={() => handleMatches(item)}>
                    {item.title}
                  </div>
                ))}
              </OpenedDropdown>
            )}
          </DropdownWrapper>

          <Dropdown ref={dropdownRef}>
            <DropdownButton onClick={() => setShowDropdown(!showDropdown)}>
              {selectedMathes?.length === 0
                ? "Select Matches"
                : `${selectedMathes?.length} selected`}
              <img src={showDropdown ? arrowIconUp : arrowIconDown} />
            </DropdownButton>
            <DropdownContent show={showDropdown}>
              {filteredMatches?.map((item, index) => (
                <DropdownItem
                  key={index}
                  selected={selectedMathes.includes(item.id)}
                  onClick={() => handleSelectionChange(item.id)}
                >
                  {item.home_team} {item.score} {item.away_team}{" "}
                  {item.competition}
                </DropdownItem>
              ))}
            </DropdownContent>
          </Dropdown>

          <ParamsWrap>
            <InputWrapper>
              <input
                type="text"
                value={displayFilters()}
                readOnly
                style={{ width: "100%", marginBottom: "10px" }}
                onClick={() => setOpenParams(!openParams)}
              />
              <img
                className="dropdownIcon"
                src={openParams ? arrowIconUp : arrowIconDown}
                alt={openParams ? arrowIconUp : arrowIconDown}
                onClick={() => setOpenParams(!openParams)}
              />
            </InputWrapper>
          </ParamsWrap>

          {loading ? (
            <Button title="fetching data" disabled />
          ) : (
            <Button title="search" onClick={() => handleSearch()} />
          )}
        </FormWrap>
      </Filter>

      <ParametersSelector
        type={"team"}
        open={openParams}
        onClose={() => setOpenParams(false)}
        updateData={handleChangeParams}
      />

      {/* display formation */}
      <Formation
        open={openFormation}
        handleClose={handleCloseFormation}
        match={selectedFormationMatch}
      />
    </>
  );
};

export default SmrtSearchTable;
