import styled from "styled-components";
import { breakpoints } from "./size";

export const CompareWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;
export const ComparePlayerLabels = styled.div`
  width: 20%;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  border-radius: 5px;
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
`;

export const ComparePlayerLabelsContent = styled.div`
  padding: 20px 10px;
`;

export const ComparePlayerList = styled.div`
  width: 80%;
  display: flex;
  flex-direciton: row;
  gap: 30px;
  overflow: scroll;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const SelectedMatchesContent = styled.div`
  position: relative;
  margin-bottom: 235px;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

export const ParamsLabel = styled.div`
  ul {
    list-style-type: none;

    li {
      opacity: 1;
      color: ${(props) => props.theme.white};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }
`;

export const SelectedMatchesOptions = styled.div`
  height: auto;
  border-radius: 1px;
  border: 1px solid rgba(44, 50, 48, 1);
  opacity: 1;
  position: absolute;
  width: 99%;

  @media only screen and (${breakpoints.mobile}) {
    background: rgba(44, 50, 48, 1);
    z-index: 999;
  }
`;

export const SelectedMatchesOptionsContent = styled.div`
  padding: 10px;

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      font-family: "SatoshiMedium";
      color: ${(props) => props.theme.white};
      cursor: pointer;
    }
  }
`;

export const SelectedMatches = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 1px;
  border: 1px solid rgba(44, 50, 48, 1);
  opacity: 1;
  padding: 10px;
  cursor: pointer;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const ComparePlayerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const ComparePlayerInfo = styled.div`
  width: 273px;
  border-radius: 5px;
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
`;

export const ComparePlayerInfoContent = styled.div`
  padding: 20px 10px;
`;

export const SearchPlayer = styled.div`
  height: 40px;
  border-radius: 1px;
  border: 1px solid rgba(44, 50, 48, 1);
  opacity: 1;
`;

export const PlayerPhoto = styled.div`
  position: relative;
  width: fit-content;
  margin: 17px 0 6px;

  .playerPhoto {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .flag {
    position: absolute;
    object-fit: cover;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`;

export const PlayerClubInfo = styled.div`
  margin-bottom: 20px;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    max-width: 180px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PlayerSeason = styled.div`
  margin-bottom: 15px;

  select {
    border-radius: 1px;
    border: 1px solid rgba(44, 50, 48, 1);
    background: transparent;
    outline: none;

    color: ${(props) => props.theme.white};
    width: 100%;
    height: 40px;
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const Parameters = styled.div``;

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  #bar {
    width: 75%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  #bar::-webkit-progress-bar {
    background-color: ${(props) => props.theme.blueish3};
    border-radius: 3px;
  }

  #bar::-webkit-progress-value {
    background-color: ${(props) => props.theme.blueish};
    border-radius: 3px;
  }

  #bar::-moz-progress-bar {
    background-color: ${(props) => props.theme.blueish};
    border-radius: 3px;
  }

  #bar::-ms-fill {
    background-color: ${(props) => props.theme.blueish};
    border-radius: 3px;
  }

  h4 {
    opacity: 1;
    color: rgba(54, 232, 45, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;

    &.green {
      color: rgba(54, 232, 45, 1);
    }

    &.red {
      color: #f20a46;
    }

    &.yellow {
      color: #e8d02d;
    }
  }
`;
