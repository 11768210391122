import React, { useEffect, useState } from "react";

// Style
import {
  TableWrap,
  TableContent,
  TableNames,
  AlfabetSort,
  DateSort,
  LeftContent,
  MidContent,
  RightContent,
  NoVideo,
  AddButton,
  SeeMore,
  Actions,
  ActionWrap,
  EmailList,
  EmailListWrap,
} from "../../style/Playlist";
import { FormWrapPopup } from "../../style/DasboardMain";

// Images
import arrow from "../../images/down.svg";
import download from "../../images/ic_download.svg";
import download_blue from "../../images/download_blue.svg";
import share from "../../images/ic_share.svg";
import share_blue from "../../images/share_blue.svg";
import edit from "../../images/ic_edit.svg";
import edit_blue from "../../images/edit_blue.svg";
import trash from "../../images/ic_bin.svg";
import trash_blue from "../../images/trash_blue.svg";
import sort from "../../images/ic_sort.svg";
import play from "../../images/ic_play-button.svg";
import play_blue from "../../images/play_blue.svg";
import add from "../../images/ic_plus.svg";
import add_blue from "../../images/plus_blue.svg";
import dots from "../../images/ic_more.svg";
import closeIcon from "../../images/ic_close.svg";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Components
import Empty from "../../components/empty/index";
import Modal from "../../components/modal/index";
import TextInput from "../inputs/TextInput";
import Button from "../button";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { $apiV1 } from "../../config/api";

const MyPlaylist = ({ table, getMyPlaylist }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const navigate = useNavigate();

  // Sorting State
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("title");

  // Track open state for each item
  const [openIndex, setOpenIndex] = useState(null);
  const [showActions, setShowActions] = useState(null);
  const [openAddVideo, setOpenAddVide] = useState(false);
  const [openRenameVideo, setOpenRenameVideo] = useState(false);
  const [openDeleteVideo, setOpenDeleteVideo] = useState(false);
  const [openShareVideo, setOpenShareVideo] = useState(false);
  const [openSuccessShareVideo, setOpenSuccessShareVideo] = useState(false);
  const [openDownloadVideo, setOpenDownloadVideo] = useState(false);

  const [email, setEmail] = useState("");
  const [folderName, setFolderName] = useState("");

  const handleEmailChange = (newValue) => {
    setFolderName(newValue);
  };

  const sortData = (data) => {
    // Ensure data is an array
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return [];
    }

    return data.sort((a, b) => {
      if (sortBy === "title") {
        if (sortOrder === "asc") {
          return a.title.localeCompare(b.title);
        } else {
          return b.title.localeCompare(a.title);
        }
      } else if (sortBy === "date") {
        if (sortOrder === "asc") {
          return new Date(a.date) - new Date(b.date);
        } else {
          return new Date(b.date) - new Date(a.date);
        }
      }
    });
  };

  const sortedTable = sortData(table);

  console.log(table, "tabletable");

  // Functions for handling sort
  const handleSortByTitle = () => {
    setSortBy("title");
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSortByDate = () => {
    setSortBy("date");
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  // Function to toggle open state for a specific item
  const toggleOpen = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const toggleShowMore = (index) => {
    setShowActions((prevIndex) => (prevIndex === index ? null : index));
  };

  const addNewFolder = async () => {
    try {
      await $apiV1.post(`/users/playlists/`, { title: folderName });
      setOpenAddVide(!openAddVideo);
      getMyPlaylist();
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const [renameFolderInfo, setRenameFolderInfo] = useState("");

  const handleEdit = (item) => {
    setRenameFolderInfo(item);
    setOpenRenameVideo(!openRenameVideo);
  };

  const handleInputChange = (newValue) => {
    setRenameFolderInfo((prevInfo) => ({
      ...prevInfo,
      title: newValue,
    }));
  };

  const handleRenameFolder = async () => {
    try {
      await $apiV1.put(`/users/playlists/${renameFolderInfo.id}/`, {
        title: renameFolderInfo.title,
      });
      setOpenRenameVideo(!openRenameVideo);
      getMyPlaylist();
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const [deleteFolderInfo, setDeleteFolderInfo] = useState("");

  const handleDeleteFolder = (item) => {
    setDeleteFolderInfo(item.id);
    setOpenDeleteVideo(!openDeleteVideo);
  };

  const handleDelete = async (deleteFolderInfo) => {
    try {
      await $apiV1.delete(`/users/playlists/${deleteFolderInfo}/`);
      setOpenDeleteVideo(!openDeleteVideo);
      getMyPlaylist();
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const [videoName, setVideoName] = useState("");

  const [currentEmail, setCurrentEmail] = useState("");
  const [emailList, setEmailList] = useState([]);

  const handleShareVideo = (item) => {
    setVideoName(item);
    setOpenShareVideo(!openShareVideo);
    setEmailList([]);
    setCurrentEmail("");
  };

  const handleEmailList = (newValue) => {
    setCurrentEmail(newValue);
  };

  const handleAddEmail = () => {
    setEmailList([...emailList, currentEmail]);
    setCurrentEmail("");
  };

  const handleRemoveEmail = (index) => {
    setEmailList(emailList.filter((_, i) => i !== index));
  };

  useEffect(() => {}, [emailList]);

  const handleSharePlayers = async (videoName) => {
    try {
      await $apiV1.post(
        `/users/share/`,
        { email: emailList, playlist: videoName.id },
        setOpenSuccessShareVideo(!openSuccessShareVideo),
        setOpenShareVideo(false)
      );
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const modal = (
    <>
      {/* Add Folder */}
      <Modal isOpen={openAddVideo} setIsOpen={setOpenAddVide} closeIc>
        <h1>{t("playlist.addFolderTitle")}</h1>
        <p>{t("playlist.addFolderDesc")}</p>
        <FormWrapPopup style={{ marginTop: "20px" }}>
          <TextInput
            label={t("playlist.addFolderInput")}
            type={"text"}
            value={folderName}
            placeholder={t("playlist.addFolderInput")}
            onChange={handleEmailChange}
            required
          />
        </FormWrapPopup>
        <Button
          title={t("playlist.addFolderButton")}
          size={"full"}
          // onClick={() => setOpenAddVide(!openAddVideo)}
          onClick={() => addNewFolder()}
        />
      </Modal>

      {/* Rename Folder */}
      <Modal isOpen={openRenameVideo} setIsOpen={setOpenRenameVideo} closeIc>
        <h1>{t("playlist.renameFolderTitle")}</h1>
        <p>{t("playlist.renameFolderDesc")}</p>
        <FormWrapPopup style={{ marginTop: "20px" }}>
          <TextInput
            label={t("playlist.renameFolderInput")}
            type={"text"}
            value={renameFolderInfo.title}
            placeholder={t("playlist.renameFolderInput")}
            onChange={handleInputChange}
            required
          />
        </FormWrapPopup>
        <Button
          title={t("playlist.renameFolderButton")}
          size={"full"}
          onClick={() => handleRenameFolder()}
        />
      </Modal>

      {/* Delete Folder */}
      <Modal isOpen={openDeleteVideo} setIsOpen={setOpenDeleteVideo} closeIc>
        <h1>{t("playlist.deleteFolderTitle")}</h1>
        <p style={{ marginBottom: "20px" }}>{t("playlist.deleteFolderDesc")}</p>
        <Button
          title={t("playlist.deleteFolderButton")}
          size={"full"}
          onClick={() => handleDelete(deleteFolderInfo)}
        />
      </Modal>

      {/* Share Video */}
      <Modal isOpen={openShareVideo} setIsOpen={setOpenShareVideo} closeIc>
        <h1>{t("playlist.shareFolderTitle")}</h1>
        <p>
          Share {videoName?.title} via next social media, or just copy link.
        </p>
        <TextInput
          type="email"
          value={currentEmail}
          onChange={handleEmailList}
          placeholder="Enter Email"
        />

        <Button
          title="Add Email"
          size={"full"}
          color={"secondary"}
          onClick={() => handleAddEmail(currentEmail)}
          style={{ marginTop: "30px" }}
        />

        {emailList.length > 0 && (
          <EmailList>
            {emailList.map((email, index) => (
              <EmailListWrap key={index}>
                {email}{" "}
                <img
                  src={closeIcon}
                  alt={closeIcon}
                  onClick={() => handleRemoveEmail(index)}
                />
              </EmailListWrap>
            ))}
            <Button
              title="Share players"
              size={"full"}
              color={"secondary"}
              onClick={() => handleSharePlayers(videoName)}
              style={{ marginTop: "30px" }}
            />
          </EmailList>
        )}
      </Modal>

      {/* Success Share Video */}
      <Modal
        isOpen={openSuccessShareVideo}
        setIsOpen={setOpenSuccessShareVideo}
        successIc
      >
        <h1>{t("playlist.successCopyTitle")}</h1>
        <p style={{ marginBottom: "40px" }}>{t("playlist.successCopyDesc")}</p>

        <Button
          title={t("playlist.successCopyButton")}
          size={"full"}
          onClick={() => setOpenSuccessShareVideo(!openSuccessShareVideo)}
        />
      </Modal>
    </>
  );

  return (
    <>
      <TableNames>
        <AlfabetSort onClick={handleSortByTitle} style={{ cursor: "pointer" }}>
          <h6>{t("playlist.sortAlphabet")}</h6>
          <img src={sort} alt="sort" />
        </AlfabetSort>
        <DateSort onClick={handleSortByDate} style={{ cursor: "pointer" }}>
          <h6>{t("playlist.sortDate")}</h6>
          <img src={sort} alt="sort" />
        </DateSort>
      </TableNames>
      {sortedTable.length > 0 ? (
        <TableWrap>
          {sortedTable.map((table_item, index) => {
            return (
              <>
                <TableContent key={index}>
                  <LeftContent
                    style={{ color: "#fff" }}
                    onClick={() => toggleOpen(index)}
                  >
                    <img src={arrow} alt="arrow" /> <h3>{table_item.title}</h3>
                  </LeftContent>
                  <MidContent>
                    <h5>{table_item.date?.split("T")?.[0]}</h5>
                  </MidContent>
                  <RightContent>
                    {!isMobile ? (
                      <>
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? download_blue
                              : download
                          }
                          alt="download"
                          onClick={() =>
                            setOpenDownloadVideo(!openDownloadVideo)
                          }
                        />
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? share_blue
                              : share
                          }
                          alt="share"
                          onClick={() => handleShareVideo(table_item)}
                        />
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? edit_blue
                              : edit
                          }
                          alt="edit"
                          onClick={() => handleEdit(table_item)}
                        />
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? trash_blue
                              : trash
                          }
                          alt="trash"
                          onClick={() => handleDeleteFolder(table_item)}
                        />
                      </>
                    ) : (
                      <SeeMore>
                        <img
                          src={dots}
                          alt="dots"
                          onClick={() => toggleShowMore(index)}
                        />
                        {showActions === index && (
                          <Actions>
                            <ActionWrap
                              onClick={() =>
                                setOpenDownloadVideo(!openDownloadVideo)
                              }
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? download_blue
                                    : download
                                }
                                alt="download"
                              />
                              <h6>{t("playlist.download")}</h6>
                            </ActionWrap>
                            <ActionWrap
                              onClick={() => setOpenShareVideo(!openShareVideo)}
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? share_blue
                                    : share
                                }
                                alt="share"
                              />
                              <h6>{t("playlist.share")}</h6>
                            </ActionWrap>
                            <ActionWrap
                              onClick={() =>
                                setOpenRenameVideo(!openRenameVideo)
                              }
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? edit_blue
                                    : edit
                                }
                                alt="edit"
                              />
                              <h6>{t("playlist.rename")}</h6>
                            </ActionWrap>
                            <ActionWrap
                              onClick={() =>
                                setOpenDeleteVideo(!openDeleteVideo)
                              }
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? trash_blue
                                    : trash
                                }
                                alt="trash"
                              />
                              <h6>{t("playlist.delete")}</h6>
                            </ActionWrap>
                          </Actions>
                        )}
                      </SeeMore>
                    )}
                  </RightContent>
                </TableContent>

                {openIndex === index && (
                  <>
                    {table_item.videos.length > 0 ? (
                      table_item.videos.map((item, index) => {
                        return (
                          <TableContent key={index}>
                            <LeftContent
                              style={{ color: "#fff" }}
                              onClick={() =>
                                navigate(
                                  `/my-playlists/${table_item.id}/?video=${item.id}`
                                )
                              }
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? play_blue
                                    : play
                                }
                                alt="play"
                              />{" "}
                              <h3>{item.title}</h3>
                            </LeftContent>
                            <MidContent>
                              <h5>{item.date?.split("T")?.[0]}</h5>
                            </MidContent>
                            <RightContent>
                              {!isMobile ? (
                                <>
                                  <img
                                    src={
                                      localStorage.getItem("theme") === "light"
                                        ? download_blue
                                        : download
                                    }
                                    alt="download"
                                    onClick={() =>
                                      setOpenDownloadVideo(!openDownloadVideo)
                                    }
                                  />
                                  <img
                                    src={
                                      localStorage.getItem("theme") === "light"
                                        ? share_blue
                                        : share
                                    }
                                    alt="share"
                                    onClick={() =>
                                      setOpenShareVideo(!openShareVideo)
                                    }
                                  />
                                  <img
                                    src={
                                      localStorage.getItem("theme") === "light"
                                        ? edit_blue
                                        : edit
                                    }
                                    alt="edit"
                                    onClick={() =>
                                      setOpenRenameVideo(!openRenameVideo)
                                    }
                                  />
                                  <img
                                    src={
                                      localStorage.getItem("theme") === "light"
                                        ? trash_blue
                                        : trash
                                    }
                                    alt="trash"
                                    onClick={() =>
                                      setOpenDeleteVideo(!openDeleteVideo)
                                    }
                                  />
                                </>
                              ) : (
                                <SeeMore>
                                  <img
                                    src={dots}
                                    alt="dots"
                                    onClick={() => toggleShowMore(index)}
                                  />
                                  {showActions === index && (
                                    <Actions>
                                      <ActionWrap
                                        onClick={() =>
                                          setOpenDownloadVideo(
                                            !openDownloadVideo
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            localStorage.getItem("theme") ===
                                            "light"
                                              ? download_blue
                                              : download
                                          }
                                          alt="download"
                                        />
                                        <h6>{t("playlist.download")}</h6>
                                      </ActionWrap>
                                      <ActionWrap
                                        onClick={() =>
                                          setOpenShareVideo(!openShareVideo)
                                        }
                                      >
                                        <img
                                          src={
                                            localStorage.getItem("theme") ===
                                            "light"
                                              ? share_blue
                                              : share
                                          }
                                          alt="share"
                                        />
                                        <h6>{t("playlist.share")}</h6>
                                      </ActionWrap>
                                      <ActionWrap
                                        onClick={() =>
                                          setOpenRenameVideo(!openRenameVideo)
                                        }
                                      >
                                        <img
                                          src={
                                            localStorage.getItem("theme") ===
                                            "light"
                                              ? edit_blue
                                              : edit
                                          }
                                          alt="edit"
                                        />
                                        <h6>{t("playlist.rename")}</h6>
                                      </ActionWrap>
                                      <ActionWrap
                                        onClick={() =>
                                          setOpenDeleteVideo(!openDeleteVideo)
                                        }
                                      >
                                        <img
                                          src={
                                            localStorage.getItem("theme") ===
                                            "light"
                                              ? trash_blue
                                              : trash
                                          }
                                          alt="trash"
                                        />
                                        <h6>{t("playlist.delete")}</h6>
                                      </ActionWrap>
                                    </Actions>
                                  )}
                                </SeeMore>
                              )}
                            </RightContent>
                          </TableContent>
                        );
                      })
                    ) : (
                      <NoVideo>
                        <h6>Nothing in here for now. Start adding videos.</h6>
                      </NoVideo>
                    )}
                  </>
                )}
              </>
            );
          })}

          <AddButton onClick={() => setOpenAddVide(!openAddVideo)}>
            <img
              src={localStorage.getItem("theme") === "light" ? add_blue : add}
              alt="add"
            />
          </AddButton>
        </TableWrap>
      ) : (
        <Empty
          title={t("playlist.emptyPlaylistTitle")}
          text={t("playlist.emptyPlaylistDesc")}
          button1={t("playlist.emptyPlaylistButton")}
        />
      )}
      {modal}
    </>
  );
};

export default MyPlaylist;
