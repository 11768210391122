import React, { useState, useEffect, useRef, useMemo } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  Bottom,
  LeftTable,
  MatchTableWrap,
  RightTable,
  ScrollableContainer,
  StyledTable,
  TeamMatches,
  TeamName,
  Top,
  TableButtons,
} from "../../style/MatchTable";

import { PlayerPosition } from "../../style/PlayerStat";
import { Score, TeamScore } from "./Matches";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Filter, { FormWrap } from "../modal/Filter";
import axios from "axios";
import styled from "styled-components";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import TextInput, { InputWrapper } from "../inputs/TextInput";
import { ParametersSelector } from "../ParametersSelector";
import { ParamsWrap } from "./MatchTable";
import {
  Dropdown,
  DropdownButton,
  DropdownContent,
  DropdownItem,
} from "../../pages/tournametns/team/EventsMatches";
import { $apiV1 } from "../../config/api";
import Button from "../button";
import { getGroupedAndSortedParametrs } from "../../helpers";
import { breakpoints } from "../../style/size";
import { useMediaQuery } from "react-responsive";

export const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const OpenedDropdown = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: 20px;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  max-height: 200px;
  overflow: scroll;

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: #00000012;
    }
  }
`;

const PlayerStat = (props) => {
  const { table, statistics, season, onDataChange, mainStat } = props;
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  const [mathcesList, setMatchesList] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const [lastMatches, SetLastMatches] = useState("");
  const [selectedParams, setSelectedParams] = useState([]);

  const [openMatchesCount, setOpenMatchesCount] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState("");

  const [competitions, setCompetitions] = useState(
    mainStat?.matches_list || []
  );
  const [openCompetition, setOpenCompetition] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState("");
  const [choosenCompetition, setChoosenCompetition] = useState("");
  const [availableSeasons, setAvailableSeason] = useState(
    mainStat?.available_seasons || []
  );
  const [filteredMatches, setFilteredMatches] = useState([]);

  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    checkedIds: [],
    params: [],
  });

  const [choosenSeasson, setChoosenSeasson] = useState(season);

  const [openParams, setOpenParams] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState("");

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const currentSelectedSeason = useMemo(() => {
    if (selectedSeason) return selectedSeason;
    if (availableSeasons?.length) {
      const foundedSeason = availableSeasons?.find((item) => item.id == season);
      if (season && foundedSeason) {
        return foundedSeason?.years;
      }
    }
    return "Select Season";
  }, [season, selectedSeason, availableSeasons]);

  useEffect(() => {
    if (mainStat?.matches_list?.length > 0) {
      const uniqueArray = mainStat?.matches_list?.reduce(
        (accumulator, current) => {
          if (
            accumulator.findIndex(
              (object) => object.competition === current.competition
            ) === -1
          ) {
            accumulator.push(current);
          }
          return accumulator;
        },
        []
      );
      console.log(uniqueArray, mainStat);
      setCompetitions(uniqueArray);
    }
  }, [mainStat]);

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
    setSelectedMathes(
      filteredMatches?.slice(0, item.value)?.map((item) => item.id)
    );
  };

  const tableLeftNames = [
    {
      id: 1,
      name: "Match",
    },
    // {
    //   id: 2,
    //   name: "Position",
    // },
  ];

  const { id } = useParams();
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedMathes, setSelectedMathes] = useState([]);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const setParams = async () => {
      try {
        let params = null;
        if (sessionStorage.getItem("playerParameters")) {
          params = JSON.parse(sessionStorage.getItem("playerParameters"));
          const checkedIds = params.flatMap((group) =>
            group[1].filter((item) => item.checked).map((item) => item.id)
          );
          const checkedParams = params.flatMap((group) =>
            group[1].filter((item) => item.checked).map((item) => item.name)
          );
          setTempSelectedFilters({ checkedIds, params: checkedParams });
        } else {
          const response = await $apiV1.get("/stats/parameters/player/");
          const data = response.data?.results;
          if (data) {
            params = getGroupedAndSortedParametrs(data, true);
            const checkedIds = params.flatMap((group) =>
              group[1].map((item) => item.id)
            );
            const checkedParams = params.flatMap((group) =>
              group[1].map((item) => item.name)
            );
            setTempSelectedFilters({ checkedIds, params: checkedParams });
          }
        }
      } catch (e) {
        console.log(`Error in receiving player parameters. ${e.message}`);
      }
    };
    setParams();
  }, []);

  useEffect(() => {
    const getMathesWithSortedParams = (matches) => {
      const newArray = matches?.map((match) => {
        return {
          ...match,
          parameters: match.parameters.sort(
            (a, b) => a.parameter_id - b.parameter_id
          ),
        };
      });
      setFilteredMatches(newArray);
    };
    getMathesWithSortedParams(statistics);
    setSelectedMathes(statistics?.map((item) => item.id));
  }, [statistics]);

  const handleParamChange = (e) => {
    const paramId = e.target.value;
    setSelectedParams((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(paramId)) {
        newSet.delete(paramId);
      } else {
        newSet.add(paramId);
      }
      return newSet;
    });
  };

  const matches = [
    {
      id: 1,
      value: statistics?.length,
      title: "All matches",
    },
    {
      id: 2,
      value: 3,
      title: "Last 3 matches",
    },
    {
      id: 3,
      value: 5,
      title: "Last 5 matches",
    },
    {
      id: 4,
      value: 10,
      title: "Last 10 matches",
    },
  ];

  const displayFilters = () => {
    if (tempSelectedFilters?.params?.length === 0) {
      return "Choose params";
    }

    const firstTwo = tempSelectedFilters?.params?.slice(0, 2)?.join(", ");
    const additionalCount = tempSelectedFilters?.params?.length - 2;
    return additionalCount > 0 ? `${firstTwo}, +${additionalCount}` : firstTwo;
  };

  function handleChangeParams(params) {
    const checkedIds = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.id)
    );
    const checkedParams = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.name)
    );
    setTempSelectedFilters({ checkedIds, params: checkedParams });
    onDataChange?.(choosenSeasson, checkedIds);
  }

  const handleSeasons = async (season = choosenSeasson) => {
    setLoading(true);
    onDataChange?.(season, tempSelectedFilters.checkedIds)
      .then(async (res) => {
        const response = await $apiV1.get(
          `/statistics/player/${id}?year_season_id=${season}`
        );
        setAvailableSeason(response.data.available_seasons);
        const uniqueArray = response.data.matches_list?.reduce(
          (accumulator, current) => {
            if (
              accumulator.findIndex(
                (object) => object.competition === current.competition
              ) === -1
            ) {
              accumulator.push(current);
            }
            return accumulator;
          },
          []
        );
        setCompetitions(uniqueArray);

        const filteredMatches = res?.data
          ?.filter((match) =>
            choosenCompetition
              ? match.competition_id === choosenCompetition
              : true
          )
          .map((match) => match.id);

        setMatchesList(filteredMatches);

        // Filter matches to show based on the updated list
        // const matchesToShow = response.data.matches_list.filter((match) =>
        //   filteredMatches.includes(match.id)
        // );
      })
      .catch((error) => {
        console.error("Error fetching players:", error);
      })
      .finally(() => setLoading(false));
  };

  const handleSelectionChange = (id) => {
    setSelectedMathes((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(item.competition__id.toString());
    setSelectedCompetition(item.title);
    setOpenCompetition(false);
    const filteredMatches = mathcesList
      ?.filter((match) =>
        item.competition__id.toString()
          ? match.competition_id === item.competition__id.toString()
          : true
      )
      .map((match) => match.id);

    setMatchesList(filteredMatches);
  };

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setOpen(false);
    handleSeasons(item.id);
  };

  const handleClearFilter = () => {
    setChoosenSeasson();
    setSelectedSeason("");
    setCompetitions();
    setSelectedCompetition("");
    SetLastMatches();
    setSelectedMatch("");
    setTempSelectedFilters({ checkedIds: [], params: [] });
    setSelectedMathes([]);
  };

  const handleSearch = async () => {
    if (mathcesList?.length > 0) {
      const updatedFilteredMatches = statistics.filter((match) =>
        mathcesList.includes(match.id)
      );
      setFilteredMatches(updatedFilteredMatches);
    }

    setOpenFilter(false);
  };

  const dowloadXls = async () => {
    try {
      const matches = selectedMathes || [];
      const params = tempSelectedFilters.checkedIds || [];
      const response = await $apiV1.post(`/statistics/player_matches/${id}`, {
        matches,
        params,
      });
      if (response.data.report) {
        const a = document.createElement("a");
        a.href = response.data.report;
        a.download =
          response.data.report.split("/")[
            response.data.report.split("/").length - 1
          ];
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (e) {
      console.log(e);
      alert(`Error: ${e?.response?.data?.message || e?.message}`);
    }
  };

  return (
    <>
      {isMobile && (
        <TableButtons>
          <div className="buttonFilterContainer">
            <button className="buttonFilter" onClick={dowloadXls}>
              Download in XLS
            </button>
            <button
              className="buttonFilter"
              onClick={() => setOpenFilter(!openFilter)}
            >
              {t("smrtSearch.filter")}
            </button>
          </div>
        </TableButtons>
      )}
      <MatchTableWrap>
        <LeftTable>
          <Top>
            <ul>
              {tableLeftNames.map((item, index) => {
                return <li key={index}>{item.name}</li>;
              })}
            </ul>
          </Top>

          <Bottom>
            {filteredMatches
              ?.filter((item) => selectedMathes.includes(item.id))
              .map((item, index) => {
                return (
                  <TeamMatches key={index} className="playerStatColumnHeight">
                    <TeamName className="matchPlayerStat">
                      <Score className="scorePlayerStat">
                        <h3>
                          <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                        </h3>

                        <h5
                          data-tooltip-id={`match-${item.id}`}
                          className="playerStatTitle"
                          title={`${item.home_team} - ${item.away_team}, ${item.competition}`}
                        >
                          {item.home_team} - {item.away_team},{" "}
                          {item.competition}
                        </h5>
                      </Score>
                      {/* <PlayerPosition>
                    {" "}
                    {item.positions.map((item, index) => {
                      return <h5 key={index}>{item?.title}</h5>;
                    })}
                  </PlayerPosition> */}
                    </TeamName>
                  </TeamMatches>
                );
              })}
          </Bottom>
        </LeftTable>

        <RightTable>
          {!isMobile && (
            <div className="buttonFilterContainer">
              <button className="buttonFilter" onClick={dowloadXls}>
                Download in XLS
              </button>
              <button
                className="buttonFilter"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {t("smrtSearch.filter")}
              </button>
            </div>
          )}
          <ScrollableContainer>
            <StyledTable>
              {/* Assuming `matches` is an array of match objects */}
              <thead>
                <tr>
                  {filteredMatches
                    ?.find((item) => item.parameters?.length > 0)
                    ?.parameters?.map((param) => (
                      <React.Fragment key={param.parameter_id}>
                        <th data-tooltip-id={`full-name-${param.parameter_id}`}>
                          {param.parameter_name ?? "/"}
                        </th>
                        <ReactTooltip
                          id={`full-name-${param.parameter_id}`}
                          place="bottom"
                          content={param.parameter_name}
                        />
                      </React.Fragment>
                    ))}
                </tr>
                {/* <tr>
                  {statistics?.length > 0 &&
                    statistics[0].parameters?.map((stat) => (
                      <>
                        <th
                          key={stat.parameter_id}
                          data-tooltip-id={`full-name-${stat.parameter_id}`}
                        >
                          {stat.parameter_name}
                        </th>
                        <ReactTooltip
                          id={`full-name-${stat.parameter_id}`}
                          place="bottom"
                          content={stat.parameter_name}
                        />
                      </>
                    ))}
                </tr> */}
              </thead>

              <tbody>
                {tempSelectedFilters?.checkedIds?.length === 0
                  ? null
                  : filteredMatches
                      ?.filter((item) => selectedMathes.includes(item.id))
                      .map((player, index) => (
                        <tr key={index}>
                          {player?.parameters?.map((stat) => (
                            <td
                              key={stat.parameter_id}
                              style={{
                                cursor: stat.markers != "" ? "pointer" : "",
                              }}
                              onClick={() =>
                                navigate(
                                  `/tournaments/match/video?markers=${stat.markers?.join(
                                    ","
                                  )}`
                                )
                              }
                            >
                              {stat.value}
                            </td>
                          ))}
                        </tr>
                      ))}
              </tbody>
            </StyledTable>
          </ScrollableContainer>
        </RightTable>
      </MatchTableWrap>

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        onClick={() => handleClearFilter()}
      >
        <FormWrap>
          {availableSeasons && (
            <DropdownWrapper>
              <div style={{ position: "relative" }}>
                <TextInput
                  type="text"
                  value={currentSelectedSeason}
                  onClick={() => setOpen(!open)}
                  readOnly
                />

                <img
                  src={open ? arrowIconUp : arrowIconDown}
                  alt={arrowIconDown}
                  onClick={() => setOpen(!open)}
                  style={{
                    color: "#fff",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                  }}
                />
              </div>
              {open && (
                <OpenedDropdown>
                  {availableSeasons?.map((item, index) => (
                    <div key={index} onClick={() => handleSeasonChoose(item)}>
                      {item.years}
                    </div>
                  ))}
                </OpenedDropdown>
              )}
            </DropdownWrapper>
          )}
          {competitions && (
            <DropdownWrapper>
              <div style={{ position: "relative" }}>
                <TextInput
                  type="text"
                  value={
                    selectedCompetition.length == 0
                      ? "Select Competition"
                      : selectedCompetition
                  }
                  onClick={() => setOpenCompetition(!openCompetition)}
                  readOnly
                />

                <img
                  src={openCompetition ? arrowIconUp : arrowIconDown}
                  alt={arrowIconDown}
                  onClick={() => setOpenCompetition(!openCompetition)}
                  style={{
                    color: "#fff",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                  }}
                />
              </div>
              {openCompetition && (
                <OpenedDropdown>
                  {competitions?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleCompetitionChoose(item)}
                    >
                      {item.competition}
                    </div>
                  ))}
                </OpenedDropdown>
              )}
            </DropdownWrapper>
          )}
          <DropdownWrapper>
            <div style={{ position: "relative" }}>
              <TextInput
                type="text"
                value={selectedMatch.length == 0 ? "PRE-SET" : selectedMatch}
                onClick={() => setOpenMatchesCount(!openMatchesCount)}
                readOnly
              />

              <img
                src={openMatchesCount ? arrowIconUp : arrowIconDown}
                alt={arrowIconDown}
                onClick={() => setOpenMatchesCount(!openMatchesCount)}
                style={{
                  color: "#fff",
                  position: "absolute",
                  right: 0,
                  cursor: "pointer",
                }}
              />
            </div>

            {openMatchesCount && (
              <OpenedDropdown>
                {matches?.map((item, index) => (
                  <div key={index} onClick={() => handleMatches(item)}>
                    {item.title}
                  </div>
                ))}
              </OpenedDropdown>
            )}
          </DropdownWrapper>

          <Dropdown ref={dropdownRef}>
            <DropdownButton onClick={() => setShowDropdown(!showDropdown)}>
              {selectedMathes?.length === 0
                ? "Select Matches"
                : `${selectedMathes?.length} selected`}
              <img src={showDropdown ? arrowIconUp : arrowIconDown} />
            </DropdownButton>
            <DropdownContent show={showDropdown}>
              {filteredMatches?.map((item, index) => (
                <DropdownItem
                  key={index}
                  selected={selectedMathes.includes(item.id)}
                  onClick={() => handleSelectionChange(item.id)}
                >
                  {item.home_team} {item.score} {item.away_team}{" "}
                  {item.competition}
                </DropdownItem>
              ))}
            </DropdownContent>
          </Dropdown>

          <ParamsWrap>
            <InputWrapper>
              <input
                type="text"
                value={displayFilters()}
                readOnly
                style={{ width: "100%", marginBottom: "10px" }}
                onClick={() => setOpenParams(!openParams)}
              />
              <img
                className="dropdownIcon"
                src={openParams ? arrowIconUp : arrowIconDown}
                alt={openParams ? arrowIconUp : arrowIconDown}
                onClick={() => setOpenParams(!openParams)}
              />
            </InputWrapper>
          </ParamsWrap>

          {/* <div style={{ height: "300px", overflow: "scroll" }}>
            <h4>Select Parameters</h4>
            {table?.statistics[0].parameters.map((param) => (
              <div key={param.parameter_id}>
                <input
                  type="checkbox"
                  value={param.parameter_id}
                  checked={selectedParams.has(param.parameter_id)}
                  onChange={handleParamChange}
                />
                <label style={{ color: "#fff" }}>{param.parameter_name}</label>
              </div>
            ))}
          </div> */}
          {loading ? (
            <Button title="fetching data" disabled />
          ) : (
            <Button title="search" onClick={() => handleSearch()} />
          )}
        </FormWrap>
      </Filter>

      <ParametersSelector
        type={
          table?.position?.findIndex((item) => item.id === 10) >= 0
            ? "gk"
            : "player"
        }
        open={openParams}
        onClose={() => setOpenParams(false)}
        updateData={handleChangeParams}
      />
    </>
  );
};

export default PlayerStat;
