import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Style
import {
  AuthWrapper,
  FormWrapper,
  ContentLogin,
  ImageWrapper,
  Form,
  ConfirmWrapp,
  LinkWrap,
  EmptyColumn,
} from "../../style/Auth";

// Images
import logo from "../../images/ic_logo.svg";

// Components
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/button/index";
import ButtonLink from "../../components/link/ButtonLink";

// Language
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const handleForgotPassword = () => {
    navigate("/success");
  };

  return (
    <AuthWrapper>
      <FormWrapper>
        <ContentLogin>
          <EmptyColumn></EmptyColumn>
          <Form>
            <img className="logo" src={logo} alt={logo} />

            <h1>{t("forgotPassword.title")}</h1>
            <p>{t("forgotPassword.desc")}</p>
            <TextInput
              label={t("inputs.email")}
              type={"email"}
              value={email}
              placeholder={t("placeholders.email")}
              onChange={handleEmailChange}
            />

            <ConfirmWrapp>
              <Button
                title={t("forgotPassword.button")}
                size={"full"}
                sizeResponse={"full"}
                onClick={() => handleForgotPassword()}
              />
            </ConfirmWrapp>
          </Form>
          <LinkWrap>
            <h3>
              {t("forgotPassword.remember")}
              <ButtonLink
                title={t("forgotPassword.goBack")}
                size="large"
                color={"primary"}
                href="/login"
                underline
              />{" "}
              {t("forgotPassword.toLogin")}
            </h3>
          </LinkWrap>
        </ContentLogin>
      </FormWrapper>
      <ImageWrapper></ImageWrapper>
    </AuthWrapper>
  );
};

export default ForgotPassword;
