import styled from "styled-components";
import { breakpoints } from "./size";

export const EmptyState = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  height: calc(100vh - 280px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

  @media only screen and (${breakpoints.mobile}) {
    height: 100%;
  }
`;

export const EmptyContent = styled.div`
  width: 422px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
    gap: 20px;
    padding: 40px 20px;
  }

  img {
    width: fit-content;
    margin: auto;

    @media only screen and (${breakpoints.mobile}) {
      width: 30%;
    }
  }
`;

export const EmpytText = styled.div`
  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 50px;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: center;
    line-height: 28px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
