import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Style
import {
  HomeSearch,
  HomeSearchContent,
  PlayersSearch,
  SearchForm,
  Search,
  TeamsSearch,
  ToggleSearch,
  SearchByFlag,
  SearchContent,
  SearchInput,
  FagsWrap,
  Flag,
  SearchResult,
  HistoryList,
  HistoryHeader,
  WrapSearch,
  Area,
} from "../../style/HomeSearch";
import { MainWrapper } from "../../style/DasboardMain";

// Componenst
import Navigation from "../../components/Navigation/Navigation";

// Images
import placeholder from "../../images/ic_logo.svg";
import searchIcon from "../../images/ic_search.svg";
import closeIcon from "../../images/ic_close.svg";
import team from "../../images/team.webp";
import eyeOpen from "../../images/on.svg";
import eyeClosed from "../../images/off.svg";
import { $apiV1, $apiV2 } from "../../config/api";
import { Oval } from "react-loader-spinner";

const fetchData = async (url, params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await fetch(`${url}?${queryString}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const Index = () => {
  const [activeTab, setActiveTab] = useState("teams");
  const [text, setText] = useState("");
  const [toggleChecked, setToggleChecked] = useState(false);
  const [teamSearchText, setTeamSearchText] = useState("");
  const [teamPage, setTeamPage] = useState(1);
  const [playerPage, setPlayerPage] = useState(1);
  const [teamSearchResults, setTeamSearchResults] = useState([]);
  const [playerSearchResults, setPlayerSearchResults] = useState([]);
  const [flags, setFlags] = useState([]);
  const [selectedFlag, setSelectedFlag] = useState(null);
  const [flagLeague, setFlagLeague] = useState([]);
  const [flagSearchText, setFlagSearchText] = useState("");
  const [leagueSearchText, setLeagueSearchText] = useState("");
  const [flagSearchResults, setFlagSearchResults] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [historyList, setHistoryList] = useState("");
  const [showhistoryList, setShowHistoryList] = useState(false);
  const [teamLeagueSearch, setTeamLeagueSearch] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (text && !toggleChecked) {
      const endpoint =
        activeTab === "teams"
          ? "https://platform.smrtstats.com:8888/api/v1/platform/team/"
          : "https://platform.smrtstats.com:8888/api/v1/platform/player/";
      setLoadingSearch(true);
      fetchData(endpoint, { page: 1, search: text })
        .then((data) => {
          if (activeTab === "teams") {
            setTeamSearchResults(data.results || []);
          } else {
            setPlayerSearchResults(data.results || []);
          }
        })
        .catch((error) => console.error("Error fetching data:", error))
        .finally(() => setLoadingSearch(false));
    }
  }, [text, activeTab, toggleChecked]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (text) {
      const endpoint =
        tab === "teams"
          ? "https://platform.smrtstats.com:8888/api/v1/platform/team/"
          : "https://platform.smrtstats.com:8888/api/v1/platform/player/";
      setLoadingSearch(true);
      fetchData(endpoint, { page: 1, search: text })
        .then((data) => {
          if (tab === "teams") {
            setTeamSearchResults(data.results || []);
          } else {
            setPlayerSearchResults(data.results || []);
          }
        })
        .catch((error) => console.error("Error fetching data:", error))
        .finally(() => setLoadingSearch(false));
    }
  };

  const emptyInput = () => {
    setText("");
  };

  const [pageSize, setPageSize] = useState(100);

  const getFlags = async () => {
    try {
      setLoadingSearch(true);
      const response = await $apiV1.get(`/platform/nationality/`, {
        params: {
          page_size: pageSize,
          with_tournaments: true,
        },
      });
      setFlags(response?.data || []);
    } catch (error) {
      console.error("Error fetching flags:", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleToggleChange = () => {
    setToggleChecked(!toggleChecked);
    if (!toggleChecked) {
      getFlags();
    }
    setText(""); // Clear the text input when toggling
    setSelectedFlag(null); // Clear selected flag when toggling
    setSelectedLeague(null); // Clear selected league when toggling
  };

  useEffect(() => {
    getFlags();
  }, [pageSize]);

  const handleFlagSearch = async (e) => {
    const searchText = e.target.value;
    setFlagSearchText(searchText);

    if (searchText) {
      try {
        setLoadingSearch(true);
        const response = await $apiV1.get(`/platform/nationality/`, {
          params: {
            page: 1,
            search: searchText,
            page_size: 100,
            with_tournaments: true,
          },
        });
        setFlagSearchResults(response?.data?.results || []);
      } catch (error) {
        console.error("Error searching flags:", error);
      } finally {
        setLoadingSearch(false);
      }
    } else {
      setFlagSearchResults([]);
    }
  };

  const handleLeague = async (e) => {
    const searchLeagueText = e.target.value;
    setLeagueSearchText(searchLeagueText);

    if (searchLeagueText.trim() === "" && selectedFlag) {
      // If search text is empty, fetch all leagues for the selected flag
      try {
        const response = await $apiV1.get(`/platform/competition/`, {
          params: {
            country: selectedFlag.name, // Filter by selected flag's country name
          },
        });

        setFlagLeague(response?.data?.results || []);
      } catch (error) {
        console.error("Error fetching leagues:", error);
        setFlagLeague([]);
      }
    } else if (searchLeagueText && selectedFlag) {
      // If search text is not empty, filter leagues by search text and selected flag
      try {
        const response = await $apiV1.get(`/platform/competition/`, {
          params: {
            page: 1,
            search: searchLeagueText,
            country: selectedFlag.name, // Filter by selected flag's country name
          },
        });
        setFlagLeague(response?.data?.results || []);
      } catch (error) {
        console.error("Error searching leagues:", error);
        setFlagLeague([]);
      }
    } else {
      // If selectedFlag is not defined, clear the league results
      setFlagLeague([]);
    }
  };

  const handleLeagueSearch = async (item) => {
    try {
      const response = await $apiV1.get(`/platform/competition/`, {
        params: { country: item.name },
      });
      setSelectedFlag(item);
      setFlagLeague(response?.data?.results || []);
    } catch (error) {
      console.error("Error fetching leagues:", error);
    }
  };

  const handleLoadmore = async () => {
    try {
      const response = await $apiV1.get(`/platform/team/`, {
        params: {
          page: teamPage + 1, // Fetch the next page
          search: teamSearchText,
        },
      });
      const newResults = response?.data?.results || [];
      setTeamSearchResults((prevResults) => [...prevResults, ...newResults]);
      setTeamPage((prevPage) => prevPage + 1); // Increment page for next load more
    } catch (error) {
      console.error("Error fetching more teams:", error);
    }
  };

  const handleSearchTeam = async () => {
    try {
      const response = await $apiV1.get(`/platform/team/`, {
        params: {
          page: 1,
          search: teamSearchText,
          country: selectedLeague.country.name,
          league: selectedLeague.name,
        },
      });
      setTeamSearchResults(response?.data?.results || []);
    } catch (error) {
      console.error("Error searching teams:", error);
      setTeamSearchResults([]); // Clear results on error
    }
  };

  const handleLoadmorePlayers = async () => {
    try {
      const response = await $apiV1.get(`/platform/player/`, {
        params: {
          page: playerPage + 1, // Fetch the next page
          search: teamSearchText,
        },
      });
      const newResults = response?.data?.results || [];
      setPlayerSearchResults((prevResults) => [...prevResults, ...newResults]);
      setPlayerPage((prevPage) => prevPage + 1); // Increment page for next load more
    } catch (error) {
      console.error("Error fetching more teams:", error);
    }
  };

  const handleSearchPlayer = async () => {
    try {
      const response = await $apiV1.get(`/platform/player/`, {
        params: {
          page: 1,
          search: teamSearchText,
        },
      });
      setPlayerSearchResults(response?.data?.results || []);
    } catch (error) {
      console.error("Error searching teams:", error);
      setPlayerSearchResults([]); // Clear results on error
    }
  };

  useEffect(() => {
    handleSearchTeam(); // Always fetch teams based on current inputs
    handleSearchPlayer(); // Always fetch teams based on current inputs
  }, [selectedLeague, teamSearchText]);

  const handleTeamSearch = async (league) => {
    try {
      const response = await $apiV1.get(
        `/platform/season/${league.seasons[0].id}/`,
        {
          // params: { league: league.seasons[0].id },
        }
      );
      setTeamLeagueSearch(response?.data);
      setSelectedLeague(league);
      setTeamSearchResults(response?.data?.teams || []);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const getHistory = async () => {
    try {
      const response = await $apiV2.get(`/users/history/`, {
        // params: { league: league.seasons[0].id },
      });
      setHistoryList(response?.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const handleShowHistory = () => {
    if (!showhistoryList) {
      getHistory();
    }
    setShowHistoryList(!showhistoryList);
  };

  return (
    <MainWrapper>
      <Navigation />

      <HomeSearch>
        <HomeSearchContent>
          <h2>Start searching, teams, players or flags</h2>
          {/* <p>
            Some explanation why user has to search first, and then he will see
            the rest of platform.
          </p> */}

          <WrapSearch>
            <ToggleSearch className={toggleChecked ? "disable" : ""}>
              <TeamsSearch
                className={activeTab === "teams" ? "active" : ""}
                onClick={() => handleTabChange("teams")}
              >
                Teams
              </TeamsSearch>
              <PlayersSearch
                className={activeTab === "players" ? "active" : ""}
                onClick={() => handleTabChange("players")}
              >
                Players
              </PlayersSearch>
            </ToggleSearch>

            <SearchForm>
              <SearchContent>
                <Search>
                  {loadingSearch ? (
                    <Oval
                      secondaryColor={
                        localStorage.getItem("theme") === "light"
                          ? "#4E5255"
                          : "#CFDAE6"
                      }
                      wrapperStyle={{ alignItems: "center" }}
                      width={14}
                      height={14}
                    />
                  ) : (
                    <img src={searchIcon} alt="search icon" />
                  )}
                  <SearchInput>
                    {!selectedFlag && (
                      <>
                        <input
                          type="text"
                          placeholder={toggleChecked ? "Flag Search" : "Search"}
                          onChange={
                            toggleChecked
                              ? handleFlagSearch
                              : (e) => setText(e.target.value)
                          }
                          value={toggleChecked ? flagSearchText : text}
                        />
                        <img
                          className="removeText"
                          src={closeIcon}
                          alt="clear icon"
                          onClick={() => {
                            if (toggleChecked) {
                              setFlagSearchText("");
                            } else {
                              emptyInput();
                            }
                          }}
                        />
                      </>
                    )}

                    {selectedFlag && !selectedLeague && (
                      <SearchInput>
                        <input
                          type="text"
                          placeholder={"Search"}
                          onChange={
                            toggleChecked
                              ? handleLeague
                              : (e) => setText(e.target.value)
                          }
                          value={toggleChecked ? leagueSearchText : text}
                        />
                        <img
                          className="removeText"
                          src={closeIcon}
                          alt="clear icon"
                          onClick={() => {
                            if (toggleChecked) {
                              setLeagueSearchText("");
                              handleLeague({ target: { value: "" } }); // Reset leagues to all for selected flag
                            } else {
                              emptyInput();
                              setFlagLeague([]); // Clear league results when input is empty
                            }
                          }}
                        />
                      </SearchInput>
                    )}

                    {selectedLeague && (
                      <SearchInput>
                        <input
                          type="text"
                          placeholder="Search teams"
                          value={teamSearchText}
                          onChange={(e) => setTeamSearchText(e.target.value)}
                        />
                        <img
                          className="removeText"
                          src={closeIcon}
                          alt="clear icon"
                          onClick={() => setTeamSearchText("")}
                        />
                      </SearchInput>
                    )}

                    {(toggleChecked ? flagSearchText : text).length > 0 && (
                      <></>
                      // <img
                      //   className="removeText"
                      //   src={closeIcon}
                      //   alt="clear icon"
                      //   onClick={() => {
                      //     if (toggleChecked) {
                      //       setFlagSearchText("");
                      //     } else {
                      //       emptyInput();
                      //     }
                      //   }}
                      // />
                    )}
                  </SearchInput>
                </Search>
                <SearchByFlag>
                  <h5>Search by Flag</h5>
                  <label className="switch">
                    <input type="checkbox" onChange={handleToggleChange} />
                    <span className="sliderHome round"></span>
                  </label>
                </SearchByFlag>
              </SearchContent>
            </SearchForm>
          </WrapSearch>

          {!toggleChecked && text.length > 0 && (
            <SearchResult>
              <ul>
                {activeTab === "teams" &&
                  teamSearchResults.map((team) => (
                    <li
                      key={team.id}
                      onClick={() => {
                        navigate(`/tournaments/team/${team.id}`);
                      }}
                      onContextMenu={() =>
                        window.open(`/tournaments/team/${team.id}`, "_blank")
                      }
                    >
                      <a>
                        <img
                          src={team.logo ? team.logo : placeholder}
                          alt={team.logo}
                        />
                        <h5>{team.name}</h5>
                      </a>
                    </li>
                  ))}

                {text.length < 4 && activeTab === "teams" && (
                  <button
                    className="loadMoreTeams"
                    onClick={() => handleLoadmore()}
                  >
                    Load more
                  </button>
                )}

                {activeTab === "players" &&
                  playerSearchResults.map((player) => (
                    <li
                      key={player.id}
                      onClick={() => {
                        navigate(`/tournaments/player/${player.id}`);
                      }}
                      onContextMenu={() =>
                        window.open(
                          `/tournaments/player/${player.id}`,
                          "_blank"
                        )
                      }
                    >
                      <a>
                        <img
                          src={player?.nationality[0]?.flag || team}
                          alt={player.logo}
                        />
                        <h5>
                          {player.name} {player.surname}
                        </h5>
                      </a>
                    </li>
                  ))}

                {text.length < 4 && activeTab === "players" && (
                  <button
                    className="loadMoreTeams"
                    onClick={() => handleLoadmorePlayers()}
                  >
                    Load more
                  </button>
                )}
              </ul>
            </SearchResult>
          )}

          {!toggleChecked && text.length === 0 && (
            <HistoryList>
              <HistoryHeader>
                <h3>History</h3>
                <img
                  src={showhistoryList ? eyeClosed : eyeOpen}
                  alt={showhistoryList ? eyeClosed : eyeOpen}
                  onClick={() => handleShowHistory()}
                />
              </HistoryHeader>
              {showhistoryList && (
                <ul>
                  {historyList?.results?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          if (item?.entity?.team_type_display) {
                            navigate(`/tournaments/team/${item?.entity?.id}`);
                          } else {
                            navigate(`/tournaments/player/${item?.entity?.id}`);
                          }
                        }}
                        onContextMenu={() => {
                          if (item?.entity?.team_type_display) {
                            window.open(
                              `/tournaments/team/${item?.entity?.id}`
                            );
                          } else {
                            window.open(
                              `/tournaments/player/${item?.entity?.id}`
                            );
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {item?.entity?.logo ? (
                          <img
                            src={`https://platform.smrtstats.com:8888/${item?.entity?.logo}`}
                            alt={item?.entity?.logo}
                          />
                        ) : (
                          <img
                            src={`https://platform.smrtstats.com:8888/${item?.entity?.nationality?.[0].flag}`}
                            alt={item?.entity?.nationality?.[0].flag}
                          />
                        )}

                        <h5>
                          {item?.entity?.name} {item?.entity?.surname}
                        </h5>
                      </li>
                    );
                  })}
                </ul>
              )}
            </HistoryList>
          )}

          {toggleChecked && !selectedFlag && (
            <>
              {flagSearchText.length > 0 ? (
                <Area>
                  <FagsWrap>
                    {flagSearchResults.map((item, index) => (
                      <Flag
                        key={index}
                        onClick={() => handleLeagueSearch(item)}
                      >
                        <img
                          src={item?.flag ?? placeholder}
                          alt={item.name}
                          // style={{ height: "50px" }}
                        />
                        <h3 data-tooltip-id={`full-name-${index}`}>
                          {item.name}
                        </h3>
                        <ReactTooltip
                          id={`full-name-${index}`}
                          place="bottom"
                          content={item.name}
                        />
                      </Flag>
                    ))}
                  </FagsWrap>
                </Area>
              ) : (
                <Area>
                  <FagsWrap>
                    {flags.results.map((item, index) => (
                      <Flag
                        key={index}
                        onClick={() => handleLeagueSearch(item)}
                      >
                        <img
                          src={item?.flag ?? placeholder}
                          alt={item.name}
                          style={{ height: "50px" }}
                        />
                        <h3 data-tooltip-id={`full-name-${index}`}>
                          {item.name}
                        </h3>
                        <ReactTooltip
                          id={`full-name-${index}`}
                          place="bottom"
                          content={item.name}
                        />
                      </Flag>
                    ))}
                  </FagsWrap>

                  {pageSize === flags.count ? (
                    <button onClick={() => setPageSize(100)}>load less</button>
                  ) : (
                    <button onClick={() => setPageSize(256)}>load more</button>
                  )}
                </Area>
              )}
            </>
          )}

          {selectedFlag && !selectedLeague && (
            <Area>
              <FagsWrap>
                {flagLeague.map((item, index) => (
                  <Flag key={index} onClick={() => handleTeamSearch(item)}>
                    <img
                      src={item.logo ?? placeholder}
                      alt={item.name}
                      style={{ height: "50px" }}
                    />
                    <h3 data-tooltip-id={`full-name-${index}`}>{item.name}</h3>
                    <ReactTooltip
                      id={`full-name-${index}`}
                      place="bottom"
                      content={item.name}
                    />
                  </Flag>
                ))}
              </FagsWrap>
            </Area>
          )}

          {selectedLeague && (
            <Area>
              <FagsWrap>
                {teamLeagueSearch?.teams.map((team, index) => (
                  <Flag
                    key={index}
                    onClick={() => navigate(`/tournaments/team/${team.id}`)}
                    onContextMenu={() =>
                      window.open(`/tournaments/team/${team.id}`, "_blank")
                    }
                  >
                    <img
                      src={team.logo ?? placeholder}
                      alt={team.name}
                      style={{ height: "50px" }}
                    />
                    <h3 data-tooltip-id={`full-name-${index}`}>{team.name}</h3>
                    <ReactTooltip
                      id={`full-name-${index}`}
                      place="bottom"
                      content={team.name}
                    />
                  </Flag>
                ))}
              </FagsWrap>
            </Area>
          )}
        </HomeSearchContent>
      </HomeSearch>
    </MainWrapper>
  );
};

export default Index;
