import styled from "styled-components";
import { breakpoints } from "./size";

// Images
import background from "../images/background.svg";

export const MainWrapper = styled.div`
  // background: url(${background});
  background: ${(props) => props.theme.background};
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: row;
  gap: 30px;
  overflow: scroll;
  // margin: 0 20px 0 0;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 0;
    margin: 0;
  }
`;

export const RightContent = styled.div`
  // padding-bottom: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  // width: 75%;

  @media only screen and (${breakpoints.mobile}) {
    padding: 0 20px 100px;
    width: auto;
  }
`;

export const CompareSide = styled.div`
  width: 80%;

  @media only screen and (${breakpoints.mobile}) {
    width: auto;
    padding: 0 20px 40px;
  }
`;

export const MainWrapperFavorite = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  width: 75%;

  &.fullWidthPage {
    width: 100%;
  }

  @media only screen and (${breakpoints.mobile}) {
    padding: 0 20px 100px;
    width: auto;
  }

  .buttonFilter {
    border-radius: 1px;
    border: none;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    color: ${(props) => props.theme.black};
    opacity: 1;
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    width: 92px;
    height: 40px;
    cursor: pointer;
    float: right;
    // position: absolute;
    // right: 0;
    // top: -50px;

    @media only screen and (${breakpoints.mobile}) {
      float: unset;
      margin-bottom: 20px;
    }
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 30px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const BottomInfo = styled.div`
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiRegular";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
  line-height: 24px;
  margin: auto;
  margin-top: 20px;
  width: 500px;
  padding-bottom: 100px;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
    padding-bottom: 20px;
  }

  a {
    font-family: "SatoshiBold";
    color: ${(props) => props.theme.green};
  }
`;

export const BoxWrrapper = styled.div`
  margin-top: 60px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  width: 536px;
  // height: 400px;

  @media only screen and (${breakpoints.mobile}) {
    margin-top: 20px;
    width: 100%;
    height: fit-content;
  }
`;

export const BoxContent = styled.div`
  padding: 40px 20px;
  position: relative;
  background: ${(props) => props.theme.black};

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    margin-bottom: 20px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 28px;
    }

    span {
      color: ${(props) => props.theme.green};
    }
  }
`;

export const BoxTitle = styled.div`
  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    margin: 0;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    margin: 0 0 20px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    width: 300px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 28px;
      width: 100%;
    }
  }
`;

export const ParagraphStyle = styled.div`
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiRegular";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  margin: 10px 0 44px;

  @media only screen and (${breakpoints.mobile}) {
    margin: 10px 0 20px;
  }

  a {
    font-family: "SatoshiBold";
    color: ${(props) => props.theme.blueish};
    text-decoration: none;
  }
`;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

export const FormWrapPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 0 20px;
  }
`;

export const Info = styled.div`
  opacity: 1;
  color: ${(props) => props.theme.blueish2};
  font-family: "SatoshiItalic";
  font-size: 12px;
  font-weight: 400;
  font-style: Italic;
  letter-spacing: 0px;
  text-align: left;
  line-height: 18px;
  position: absolute;
  bottom: -30px;

  @media only screen and (${breakpoints.mobile}) {
    bottom: -20px;
  }
`;

export const CheckTheme = styled.div`
  span {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    padding-left: 5px;
  }
`;

export const CheckThemeContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media only screen and (${breakpoints.mobile}) {
    gap: 10px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 20px;
    align-items: baseline;
    margin-bottom: 60px;
  }
`;

export const IntroWrap = styled.div`
  margin-bottom: 40px;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

export const FilterContentWrap = styled.div`
  overflow: scroll;
  height: 550px;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white} !important;
    font-family: "SatoshiRegular";
    font-size: 14px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    padding-left: 5px;
    margin-bottom: 20px;
  }
`;
