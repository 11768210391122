import styled from "styled-components";
import { breakpoints } from "./size";

export const GlobalWrap = styled.div``;
export const PlayerTable = styled.div``;

export const Club = styled.div`
  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    // width: 100px;
    margin-left: 20px;
  }
`;

export const LeftInfo = styled.div``;
export const TopHeader = styled.div`
  display: flex;
  gap: 10px;

  h3 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;

    &.position{
       width: 50px;
    }

    &:nth-child(1) {
      width: 80px;
      text-align: right;
    }
    &:nth-child(2) {
      width: 160px;
    
     &:nth-child(3) {
      width: 50px;
    }
  }
`;
export const StatInfo = styled.div`
  display: flex;
`;

export const PlayerPrimaryInfo = styled.div`
  // width: 30%;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const PlayerStatistics = styled.div`
  width: 70%;
`;

export const PlayerPrimaryInfoContent = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 60px;
  padding: 0 20px;

  &:nth-child(odd){
    background: ${(props) => props.theme.black};
  }

  img{
    width: 40px;
    height: 40px;
        object-fit: contain;
  }

   .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;


   

    &.position{
    width: 50px;
  }

    &:nth-child(1) {
      // width: 80px;
      text-align: right;
    }
    &:nth-child(4) {
      // width: 120px;
      // max-width: 120px;
      // overflow: hidden;
      // display: inline-block;
      // text-overflow: ellipsis;
      // white-space: nowrap;

       max-width: 120px;
    width: 120px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    
 
  }
`;

export const RightInfo = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const TableInfo = styled.div`
  display: flex;
  flex-direction: row;
`;
