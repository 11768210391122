import styled from "styled-components";
import { breakpoints } from "./size";

export const HomeSearch = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  width: 906px;
  height: 680px;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  margin: auto;

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
    height: 100%;
    margin: 20px auto;
  }
`;

export const WrapSearch = styled.div`
  width: 651px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const HomeSearchContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: 60px;
  padding-top: 20px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 20px 0 10px;
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;
    padding-bottom: 10px;

    @media only screen and (${breakpoints.mobile}) {
      text-align: center;
      line-height: 40px;
    }
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.green};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 30px;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 20px;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

export const ToggleSearch = styled.div`
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  width: 538px;
  display: flex;
  flex-direction: row;

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
  }

  &.disable {
    opacity: 0.4;
  }
`;

export const TeamsSearch = styled.div`
  border-radius: 2px;
  opacity: 1;

  width: 269px;
  height: 36px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  opacity: 1;
  color: ${(props) => props.theme.blueish2};
  font-family: "SatoshiRegular";
  font-weight: 400;
  font-style: Regular;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  &.active {
    background-color: ${(props) => props.theme.blueish3};
    color: ${(props) => props.theme.green};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }
`;

export const PlayersSearch = styled.div`
  opacity: 1;

  width: 269px;
  height: 36px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  opacity: 1;
  color: ${(props) => props.theme.blueish2};
  font-family: "SatoshiRegular";
  font-weight: 400;
  font-style: Regular;

  &.active {
    background-color: ${(props) => props.theme.blueish3};
    opacity: 1;
    color: ${(props) => props.theme.green};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }
`;

export const SearchForm = styled.div`
  width: 538px;
  border-radius: 1px;
  border: 1px solid ${(props) => props.theme.blueish3};
  opacity: 1;
  margin-top: 10px;

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
  }
`;

export const SearchResult = styled.div`
  background: ${(props) => props.theme.lines};
  width: 538px;

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
  }

  .loadMoreTeams {
    background: ${(props) => props.theme.green};
    border: none;
    color: ${(props) => props.theme.black};
    font-family: "SatoshiBold";
    font-size: 14px;
    font-weight: 600;
    font-style: Regular;
    cursor: pointer;

    position: sticky;
    bottom: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0px;

    max-height: 250px;
    overflow: auto;
    -ms-overflow-style: auto;
    scrollbar-width: auto;
    background: ${(props) => props.theme.blueish3};

    @media only screen and (${breakpoints.mobile}) {
      max-height: 100px;
    }

    li,
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 2px 3px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background: ${(props) => props.theme.row};
      }

      h5 {
        opacity: 1;
        color: ${(props) => props.theme.blueish};
        font-family: "SatoshiRegular";
        font-size: 16px;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0px;
        text-align: left;
        line-height: 24px;
      }

      img {
        width: 25px;
        height: 25px;
        object-fit: scale-down;
      }
    }
  }
`;

export const SearchContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  height: 34px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    height: auto;
  }
`;

export const Search = styled.div`
  display: flex;
  gap: 10px;
  width: 60%;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  img {
    width: 14px;
  }
`;

export const SearchInput = styled.div`
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 30px;
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    background: transparent;
    border: none;
    outline: none;

    &:placeholder {
      color: ${(props) => props.theme.blueish2};
    }
  }

  .removeText {
    position: absolute;
    top: 10px;
    width: 12px;
    cursor: pointer;

    @media only screen and (${breakpoints.mobile}) {
      right: 5px;
    }
  }
`;

export const SearchByFlag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  border-left: 1px solid ${(props) => props.theme.blueish3};
  padding-left: 10px;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
    border: none;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: right;
    line-height: 24px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 28px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .sliderHome {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.blueish3};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .sliderHome:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .sliderHome {
    background-color: ${(props) => props.theme.green};
  }

  input:focus + .sliderHome {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .sliderHome:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .sliderHome.round {
    border-radius: 34px;
  }

  .sliderHome.round:before {
    border-radius: 50%;
  }
`;

export const Area = styled.div`
  height: 450px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  button {
    border-radius: 1px;
    border: none;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    color: ${(props) => props.theme.black};
    opacity: 1;
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    width: fit-content;
    cursor: pointer;
    padding: 7px 2px;
  }
`;

export const FagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 20px;
  // width: 100%;
  margin-top: 40px;
  text-align: center;

  // height: 400px;
  padding: 0 20px;
  overflow: scroll;

  @media only screen and (${breakpoints.mobile}) {
    height: 200px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Flag = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  width: 90px;

  img {
    width: 70px;
    height: 50px;
    object-fit: contain;
  }

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const HistoryList = styled.div`
  width: 538px;
  margin-top: 20px;

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
    max-height: 100px;
    overflow: scroll;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 14px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;
    // margin-left: 20px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 300px;
    overflow: scroll;

    li {
      display: flex;
      align-items: center;
      gap: 10px;

      h5 {
        opacity: 1;
        color: ${(props) => props.theme.blueish};
        font-family: "SatoshiMedium";
        font-size: 16px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: left;
        line-height: 24px;
      }

      img {
        background: #fff;
        width: 20px;
      }
    }
  }
`;

export const HistoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  img {
    cursor: pointer;
  }
`;
