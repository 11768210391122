import styled from "styled-components";
import { breakpoints } from "./size";

export const ErrorWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.linesOpacity};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 650px;

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
  }
`;

export const ErrorContent = styled.div`
  padding: 60px 0;
  width: 350px;
  text-align: center;

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 50px;
    margin: 40px 0 5px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 32px;
      line-height: 46px;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 40px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;
