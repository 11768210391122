export const getCoordsPosInZone = (x1, x2, y1, y2) => ({
    x: +((x1 + x2) / 2).toFixed(2),
    y: +((y1 + y2) / 2).toFixed(2),
});


export const getLeftZoneByShortName = (
    name,
    pitchWidth = 105,
    pitchHeight = 68
) => {
    const zonesArr = getLeftSideZones(pitchWidth, pitchHeight);
    const zone = zonesArr.find((item) => item.short_name === name);
    return zone;
};

export const getRightZoneByShortName = (
    name,
    pitchWidth = 105,
    pitchHeight = 68
) => {
    const zonesArr = getRightSideZones(pitchWidth, pitchHeight);
    const zone = zonesArr.find((item) => item.short_name === name);
    return zone;
};


export const getLeftSideZones = (
    pitchWidth = 105,
    pitchHeight = 68,
) => {
    const widthZone = +((pitchWidth / 2 - 7) / 5).toFixed(2);
    const heightZone = +(pitchHeight / 5).toFixed(2);

    return [
        {
            action_id: 4,
            coords_pos: getCoordsPosInZone(
                7,
                0,
                pitchHeight,
                0,
            ),
            id: 1,
            long_name: 'Goalkeeper',
            movable: false,
            player: null,
            position_id: 31,
            short_name: 'GK',
            zone: {
                maxX: 7,
                maxY: pitchHeight,
                minX: 0,
                minY: 0,
            },
        },
        {
            action_id: 8,
            coords_pos: getCoordsPosInZone(
                widthZone + 7,
                7,
                pitchHeight,
                pitchHeight - heightZone,
            ),
            id: 2,
            long_name: 'Defender - Left',
            movable: true,
            player: null,
            position_id: 12,
            short_name: 'LD',
            zone: {
                maxX: widthZone + 7,
                maxY: pitchHeight,
                minX: 7,
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 95,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 2,
                7 + widthZone,
                pitchHeight,
                pitchHeight - heightZone,
            ),
            id: 3,
            long_name: 'Midfielder - Left',
            movable: true,
            player: null,
            position_id: 13,
            short_name: 'LDM',
            zone: {
                maxX: 7 + widthZone * 2,
                maxY: pitchHeight,
                minX: 7 + widthZone,
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 19,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 3,
                7 + widthZone * 2,
                pitchHeight,
                pitchHeight - heightZone,
            ),
            id: 4,
            long_name: 'Midfielder - Left',
            movable: true,
            player: null,
            position_id: 14,
            short_name: 'LM',
            zone: {
                maxX: 7 + widthZone * 3,
                maxY: pitchHeight,
                minX: 7 + widthZone * 2,
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 13,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 4,
                7 + widthZone * 3,
                pitchHeight,
                pitchHeight - heightZone,
            ),
            id: 5,
            long_name: 'Attacking midfielder - Left',
            movable: true,
            player: null,
            position_id: 15,
            short_name: 'LAM',
            zone: {
                maxX: 7 + widthZone * 4,
                maxY: pitchHeight,
                minX: 7 + widthZone * 3,
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 198,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 5,
                7 + widthZone * 4,
                pitchHeight,
                pitchHeight - heightZone,
            ),
            id: 6,
            long_name: 'Forward - Left',
            movable: true,
            player: null,
            position_id: 16,
            short_name: 'LF',
            zone: {
                maxX: 7 + widthZone * 5,
                maxY: pitchHeight,
                minX: 7 + widthZone * 4,
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 5,
            coords_pos: getCoordsPosInZone(
                widthZone + 7,
                7,
                pitchHeight - heightZone,
                pitchHeight - heightZone * 2,
            ),
            id: 7,
            long_name: 'Defender - Left central',
            movable: true,
            player: null,
            position_id: 22,
            short_name: 'LCD',
            zone: {
                maxX: widthZone + 7,
                maxY: pitchHeight - heightZone,
                minX: 7,
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 84,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 2,
                7 + widthZone,
                pitchHeight - heightZone,
                pitchHeight - heightZone * 2,
            ),
            id: 8,
            long_name: 'Defensive midfielder - Left central',
            movable: true,
            player: null,
            position_id: 23,
            short_name: 'LCDM',
            zone: {
                maxX: 7 + widthZone * 2,
                maxY: pitchHeight - heightZone,
                minX: 7 + widthZone,
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 9,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 3,
                7 + widthZone * 2,
                pitchHeight - heightZone,
                pitchHeight - heightZone * 2,
            ),
            id: 9,
            long_name: 'Midfielder - Left central',
            movable: true,
            player: null,
            position_id: 24,
            short_name: 'LCM',
            zone: {
                maxX: 7 + widthZone * 3,
                maxY: pitchHeight - heightZone,
                minX: 7 + widthZone * 2,
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 92,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 4,
                7 + widthZone * 3,
                pitchHeight - heightZone,
                pitchHeight - heightZone * 2,
            ),
            id: 10,
            long_name: 'Attacking midfielder - Left central',
            movable: true,
            player: null,
            position_id: 25,
            short_name: 'LCAM',
            zone: {
                maxX: 7 + widthZone * 4,
                maxY: pitchHeight - heightZone,
                minX: 7 + widthZone * 3,
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 20,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 5,
                7 + widthZone * 4,
                pitchHeight - heightZone,
                pitchHeight - heightZone * 2,
            ),
            id: 11,
            long_name: 'Forward - Left central',
            movable: true,
            player: null,
            position_id: 26,
            short_name: 'LCF',
            zone: {
                maxX: 7 + widthZone * 5,
                maxY: pitchHeight - heightZone,
                minX: 7 + widthZone * 4,
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 93,
            coords_pos: getCoordsPosInZone(
                widthZone + 7,
                7,
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone * 3,
            ),
            id: 12,
            long_name: 'Defender - Central',
            movable: true,
            player: null,
            position_id: 32,
            short_name: 'CD',
            zone: {
                maxX: widthZone + 7,
                maxY: pitchHeight - heightZone * 2,
                minX: 7,
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 10,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 2,
                7 + widthZone,
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone * 3,
            ),
            id: 13,
            long_name: 'Defensive midfielder - Central',
            movable: true,
            player: null,
            position_id: 33,
            short_name: 'CDM',
            zone: {
                maxX: 7 + widthZone * 2,
                maxY: pitchHeight - heightZone * 2,
                minX: 7 + widthZone,
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 112,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 3,
                7 + widthZone * 2,
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone * 3,
            ),
            id: 14,
            long_name: 'Midfielder - Central',
            movable: true,
            player: null,
            position_id: 34,
            short_name: 'CM',
            zone: {
                maxX: 7 + widthZone * 3,
                maxY: pitchHeight - heightZone * 2,
                minX: 7 + widthZone * 2,
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 17,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 4,
                7 + widthZone * 3,
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone * 3,
            ),
            id: 15,
            long_name: 'Attacking midfielder - Central',
            movable: true,
            player: null,
            position_id: 35,
            short_name: 'CAM',
            zone: {
                maxX: 7 + widthZone * 4,
                maxY: pitchHeight - heightZone * 2,
                minX: 7 + widthZone * 3,
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 12,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 5,
                7 + widthZone * 4,
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone * 3,
            ),
            id: 16,
            long_name: 'Forward - Central',
            movable: true,
            player: null,
            position_id: 36,
            short_name: 'CF',
            zone: {
                maxX: 7 + widthZone * 5,
                maxY: pitchHeight - heightZone * 2,
                minX: 7 + widthZone * 4,
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 6,
            coords_pos: getCoordsPosInZone(
                widthZone + 7,
                7,
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 4,
            ),
            id: 17,
            long_name: 'Defender - Right central',
            movable: true,
            player: null,
            position_id: 42,
            short_name: 'RCD',
            zone: {
                maxX: widthZone + 7,
                maxY: pitchHeight - heightZone * 3,
                minX: 7,
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 83,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 2,
                7 + widthZone,
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 4,
            ),
            id: 18,
            long_name: 'Defensive midfielder - Right central',
            movable: true,
            player: null,
            position_id: 43,
            short_name: 'RCDM',
            zone: {
                maxX: 7 + widthZone * 2,
                maxY: pitchHeight - heightZone * 3,
                minX: 7 + widthZone,
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 11,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 3,
                7 + widthZone * 2,
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 4,
            ),
            id: 19,
            long_name: 'Midfielder - Right central',
            movable: true,
            player: null,
            position_id: 44,
            short_name: 'RCM',
            zone: {
                maxX: 7 + widthZone * 3,
                maxY: pitchHeight - heightZone * 3,
                minX: 7 + widthZone * 2,
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 94,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 4,
                7 + widthZone * 3,
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 4,
            ),
            id: 20,
            long_name: 'Attacking midfielder - Right central',
            movable: true,
            player: null,
            position_id: 45,
            short_name: 'RCAM',
            zone: {
                maxX: 7 + widthZone * 4,
                maxY: pitchHeight - heightZone * 3,
                minX: 7 + widthZone * 3,
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 21,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 5,
                7 + widthZone * 4,
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 4,
            ),
            id: 21,
            long_name: 'Forward - Right central',
            movable: true,
            player: null,
            position_id: 46,
            short_name: 'RCF',
            zone: {
                maxX: 7 + widthZone * 5,
                maxY: pitchHeight - heightZone * 3,
                minX: 7 + widthZone * 4,
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 7,
            coords_pos: getCoordsPosInZone(
                widthZone + 7,
                7,
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 5,
            ),
            id: 22,
            long_name: 'Defender - Right',
            movable: true,
            player: null,
            position_id: 52,
            short_name: 'RD',
            zone: {
                maxX: widthZone + 7,
                maxY: pitchHeight - heightZone * 4,
                minX: 7,
                minY: pitchHeight - heightZone * 5,
            },
        },
        {
            action_id: 91,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 2,
                7 + widthZone,
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 5,
            ),
            id: 23,
            long_name: 'Midfielder - Right',
            movable: true,
            player: null,
            position_id: 53,
            short_name: 'RDM',
            zone: {
                maxX: 7 + widthZone * 2,
                maxY: pitchHeight - heightZone * 4,
                minX: 7 + widthZone,
                minY: pitchHeight - heightZone * 5,
            },
        },
        {
            action_id: 18,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 3,
                7 + widthZone * 2,
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 5,
            ),
            id: 24,
            long_name: 'Midfielder - Right',
            movable: true,
            player: null,
            position_id: 54,
            short_name: 'RM',
            zone: {
                maxX: 7 + widthZone * 3,
                maxY: pitchHeight - heightZone * 4,
                minX: 7 + widthZone * 2,
                minY: pitchHeight - heightZone * 5,
            },
        },
        {
            action_id: 14,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 4,
                7 + widthZone * 3,
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 5,
            ),
            id: 25,
            long_name: 'Attacking midfielder - Right',
            movable: true,
            player: null,
            position_id: 55,
            short_name: 'RAM',
            zone: {
                maxX: 7 + widthZone * 4,
                maxY: pitchHeight - heightZone * 4,
                minX: 7 + widthZone * 3,
                minY: pitchHeight - heightZone * 5,
            },
        },
        {
            action_id: 199,
            coords_pos: getCoordsPosInZone(
                7 + widthZone * 5,
                7 + widthZone * 4,
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 5,
            ),
            id: 21,
            long_name: 'Forward - Right',
            movable: true,
            player: null,
            position_id: 56,
            short_name: 'RF',
            zone: {
                maxX: 7 + widthZone * 5,
                maxY: pitchHeight - heightZone * 4,
                minX: 7 + widthZone * 4,
                minY: pitchHeight - heightZone * 5,
            },
        },
    ];
};

export const getRightSideZones = (
    pitchWidth = 105,
    pitchHeight = 68,
) => {
    const widthZone = +((pitchWidth / 2 - 7) / 5).toFixed(2);
    const heightZone = +(pitchHeight / 5).toFixed(2);

    return [
        {
            action_id: 4,
            coords_pos: getCoordsPosInZone(
                pitchWidth,
                pitchWidth - 7,
                pitchHeight,
                0,
            ),
            id: 1,
            long_name: 'Goalkeeper',
            movable: false,
            player: null,
            position_id: 31,
            short_name: 'GK',
            zone: {
                maxX: pitchWidth - 7,
                maxY: 0,
                minX: pitchWidth,
                minY: pitchHeight,
            },
        },
        {
            action_id: 7,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone + 7),
                pitchWidth - 7,
                pitchHeight - heightZone,
                pitchHeight,
            ),
            id: 2,
            long_name: 'Defender - Left',
            movable: true,
            player: null,
            position_id: 12,
            short_name: 'RD',
            zone: {
                maxX: pitchWidth - (widthZone + 7),
                maxY: pitchHeight - heightZone,
                minX: pitchWidth - 7,
                minY: pitchHeight,
            },
        },
        {
            action_id: 91,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 2 + 7),
                pitchWidth - (widthZone + 7),
                pitchHeight - heightZone,
                pitchHeight,
            ),
            id: 3,
            long_name: 'Midfielder - Left',
            movable: true,
            player: null,
            position_id: 13,
            short_name: 'RDM',
            zone: {
                maxX: pitchWidth - (widthZone * 2 + 7),
                maxY: pitchHeight - heightZone,
                minX: pitchWidth - (widthZone + 7),
                minY: pitchHeight,
            },
        },
        {
            action_id: 18,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 3 + 7),
                pitchWidth - (widthZone * 2 + 7),
                pitchHeight - heightZone,
                pitchHeight,
            ),
            id: 4,
            long_name: 'Midfielder - Left',
            movable: true,
            player: null,
            position_id: 14,
            short_name: 'RM',
            zone: {
                maxX: pitchWidth - (widthZone * 3 + 7),
                maxY: pitchHeight - heightZone,
                minX: pitchWidth - (widthZone * 2 + 7),
                minY: pitchHeight,
            },
        },
        {
            action_id: 14,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 4 + 7),
                pitchWidth - (widthZone * 3 + 7),
                pitchHeight - heightZone,
                pitchHeight,
            ),
            id: 5,
            long_name: 'Attacking midfielder - Left',
            movable: true,
            player: null,
            position_id: 15,
            short_name: 'RAM',
            zone: {
                maxX: pitchWidth - (widthZone * 4 + 7),
                maxY: pitchHeight - heightZone,
                minX: pitchWidth - (widthZone * 3 + 7),
                minY: pitchHeight,
            },
        },
        {
            action_id: 199,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 5 + 7),
                pitchWidth - (widthZone * 4 + 7),
                pitchHeight - heightZone,
                pitchHeight,
            ),
            id: 6,
            long_name: 'Forward - Left',
            movable: true,
            player: null,
            position_id: 16,
            short_name: 'RF',
            zone: {
                maxX: pitchWidth - (widthZone * 5 + 7),
                maxY: pitchHeight - heightZone,
                minX: pitchWidth - (widthZone * 4 + 7),
                minY: pitchHeight,
            },
        },
        {
            action_id: 6,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone + 7),
                pitchWidth - 7,
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone,
            ),
            id: 7,
            long_name: 'Defender - Left central',
            movable: true,
            player: null,
            position_id: 22,
            short_name: 'RCD',
            zone: {
                maxX: pitchWidth - (widthZone + 7),
                maxY: pitchHeight - heightZone * 2,
                minX: pitchWidth - 7,
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 83,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 2 + 7),
                pitchWidth - (widthZone + 7),
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone,
            ),
            id: 8,
            long_name: 'Defensive midfielder - Left central',
            movable: true,
            player: null,
            position_id: 23,
            short_name: 'RCDM',
            zone: {
                maxX: pitchWidth - (widthZone * 2 + 7),
                maxY: pitchHeight - heightZone * 2,
                minX: pitchWidth - (widthZone + 7),
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 11,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 3 + 7),
                pitchWidth - (widthZone * 2 + 7),
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone,
            ),
            id: 9,
            long_name: 'Midfielder - Left central',
            movable: true,
            player: null,
            position_id: 24,
            short_name: 'RCM',
            zone: {
                maxX: pitchWidth - (widthZone * 3 + 7),
                maxY: pitchHeight - heightZone * 2,
                minX: pitchWidth - (widthZone * 2 + 7),
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 94,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 4 + 7),
                pitchWidth - (widthZone * 3 + 7),
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone,
            ),
            id: 10,
            long_name: 'Attacking midfielder - Left central',
            movable: true,
            player: null,
            position_id: 25,
            short_name: 'RCAM',
            zone: {
                maxX: pitchWidth - (widthZone * 4 + 7),
                maxY: pitchHeight - heightZone * 2,
                minX: pitchWidth - (widthZone * 3 + 7),
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 21,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 5 + 7),
                pitchWidth - (widthZone * 4 + 7),
                pitchHeight - heightZone * 2,
                pitchHeight - heightZone,
            ),
            id: 11,
            long_name: 'Forward - Left central',
            movable: true,
            player: null,
            position_id: 26,
            short_name: 'RCF',
            zone: {
                maxX: pitchWidth - (widthZone * 5 + 7),
                maxY: pitchHeight - heightZone * 2,
                minX: pitchWidth - (widthZone * 4 + 7),
                minY: pitchHeight - heightZone,
            },
        },
        {
            action_id: 93,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone + 7),
                pitchWidth - 7,
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 2,
            ),
            id: 12,
            long_name: 'Defender - Central',
            movable: true,
            player: null,
            position_id: 32,
            short_name: 'CD',
            zone: {
                maxX: pitchWidth - (widthZone + 7),
                maxY: pitchHeight - heightZone * 3,
                minX: pitchWidth - 7,
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 10,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 2 + 7),
                pitchWidth - (widthZone + 7),
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 2,
            ),
            id: 13,
            long_name: 'Defensive midfielder - Central',
            movable: true,
            player: null,
            position_id: 33,
            short_name: 'CDM',
            zone: {
                maxX: pitchWidth - (widthZone * 2 + 7),
                maxY: pitchHeight - heightZone * 3,
                minX: pitchWidth - (widthZone + 7),
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 112,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 3 + 7),
                pitchWidth - (widthZone * 2 + 7),
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 2,
            ),
            id: 14,
            long_name: 'Midfielder - Central',
            movable: true,
            player: null,
            position_id: 34,
            short_name: 'CM',
            zone: {
                maxX: pitchWidth - (widthZone * 3 + 7),
                maxY: pitchHeight - heightZone * 3,
                minX: pitchWidth - (widthZone * 2 + 7),
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 17,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 4 + 7),
                pitchWidth - (widthZone * 3 + 7),
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 2,
            ),
            id: 15,
            long_name: 'Attacking midfielder - Central',
            movable: true,
            player: null,
            position_id: 35,
            short_name: 'CAM',
            zone: {
                maxX: pitchWidth - (widthZone * 4 + 7),
                maxY: pitchHeight - heightZone * 3,
                minX: pitchWidth - (widthZone * 3 + 7),
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 12,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 5 + 7),
                pitchWidth - (widthZone * 4 + 7),
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 2,
            ),
            id: 16,
            long_name: 'Forward - Central',
            movable: true,
            player: null,
            position_id: 36,
            short_name: 'CF',
            zone: {
                maxX: pitchWidth - (widthZone * 5 + 7),
                maxY: pitchHeight - heightZone * 3,
                minX: pitchWidth - (widthZone * 4 + 7),
                minY: pitchHeight - heightZone * 2,
            },
        },
        {
            action_id: 5,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone + 7),
                pitchWidth - 7,
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 3,
            ),
            id: 17,
            long_name: 'Defender - Right central',
            movable: true,
            player: null,
            position_id: 42,
            short_name: 'LCD',
            zone: {
                maxX: pitchWidth - (widthZone + 7),
                maxY: pitchHeight - heightZone * 4,
                minX: pitchWidth - 7,
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 84,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 2 + 7),
                pitchWidth - (widthZone + 7),
                pitchHeight - heightZone * 3,
                pitchHeight - heightZone * 4,
            ),
            id: 18,
            long_name: 'Defensive midfielder - Right central',
            movable: true,
            player: null,
            position_id: 43,
            short_name: 'LCDM',
            zone: {
                maxX: pitchWidth - (widthZone * 2 + 7),
                maxY: pitchHeight - heightZone * 4,
                minX: pitchWidth - (widthZone + 7),
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 9,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 3 + 7),
                pitchWidth - (widthZone * 2 + 7),
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 3,
            ),
            id: 19,
            long_name: 'Midfielder - Right central',
            movable: true,
            player: null,
            position_id: 44,
            short_name: 'LCM',
            zone: {
                maxX: pitchWidth - (widthZone * 3 + 7),
                maxY: pitchHeight - heightZone * 4,
                minX: pitchWidth - (widthZone * 2 + 7),
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 92,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 4 + 7),
                pitchWidth - (widthZone * 3 + 7),
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 3,
            ),
            id: 20,
            long_name: 'Attacking midfielder - Right central',
            movable: true,
            player: null,
            position_id: 45,
            short_name: 'LCAM',
            zone: {
                maxX: pitchWidth - (widthZone * 4 + 7),
                maxY: pitchHeight - heightZone * 4,
                minX: pitchWidth - (widthZone * 3 + 7),
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 20,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 5 + 7),
                pitchWidth - (widthZone * 4 + 7),
                pitchHeight - heightZone * 4,
                pitchHeight - heightZone * 3,
            ),
            id: 21,
            long_name: 'Forward - Right central',
            movable: true,
            player: null,
            position_id: 46,
            short_name: 'LCF',
            zone: {
                maxX: pitchWidth - (widthZone * 5 + 7),
                maxY: pitchHeight - heightZone * 4,
                minX: pitchWidth - (widthZone * 4 + 7),
                minY: pitchHeight - heightZone * 3,
            },
        },
        {
            action_id: 8,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone + 7),
                pitchWidth - 7,
                pitchHeight - heightZone * 5,
                pitchHeight - heightZone * 4,
            ),
            id: 22,
            long_name: 'Defender - Right',
            movable: true,
            player: null,
            position_id: 52,
            short_name: 'LD',
            zone: {
                maxX: pitchWidth - (widthZone + 7),
                maxY: pitchHeight - heightZone * 5,
                minX: pitchWidth - 7,
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 95,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 2 + 7),
                pitchWidth - (widthZone + 7),
                pitchHeight - heightZone * 5,
                pitchHeight - heightZone * 4,
            ),
            id: 23,
            long_name: 'Midfielder - Right',
            movable: true,
            player: null,
            position_id: 53,
            short_name: 'LDM',
            zone: {
                maxX: pitchWidth - (widthZone * 2 + 7),
                maxY: pitchHeight - heightZone * 5,
                minX: pitchWidth - (widthZone + 7),
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 19,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 3 + 7),
                pitchWidth - (widthZone * 2 + 7),
                pitchHeight - heightZone * 5,
                pitchHeight - heightZone * 4,
            ),
            id: 24,
            long_name: 'Midfielder - Right',
            movable: true,
            player: null,
            position_id: 54,
            short_name: 'LM',
            zone: {
                maxX: pitchWidth - (widthZone * 3 + 7),
                maxY: pitchHeight - heightZone * 5,
                minX: pitchWidth - (widthZone * 2 + 7),
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 13,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 4 + 7),
                pitchWidth - (widthZone * 3 + 7),
                pitchHeight - heightZone * 5,
                pitchHeight - heightZone * 4,
            ),
            id: 25,
            long_name: 'Attacking midfielder - Right',
            movable: true,
            player: null,
            position_id: 55,
            short_name: 'LAM',
            zone: {
                maxX: pitchWidth - (widthZone * 4 + 7),
                maxY: pitchHeight - heightZone * 5,
                minX: pitchWidth - (widthZone * 3 + 7),
                minY: pitchHeight - heightZone * 4,
            },
        },
        {
            action_id: 198,
            coords_pos: getCoordsPosInZone(
                pitchWidth - (widthZone * 5 + 7),
                pitchWidth - (widthZone * 4 + 7),
                pitchHeight - heightZone * 5,
                pitchHeight - heightZone * 4,
            ),
            id: 21,
            long_name: 'Forward - Right',
            movable: true,
            player: null,
            position_id: 56,
            short_name: 'LF',
            zone: {
                maxX: pitchWidth - (widthZone * 5 + 7),
                maxY: pitchHeight - heightZone * 5,
                minX: pitchWidth - (widthZone * 4 + 7),
                minY: pitchHeight - heightZone * 4,
            },
        },
    ];
};

export const availableActionPositionIds = getLeftSideZones().map((item) => item.action_id);
