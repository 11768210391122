export const SuccessIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="#47d764"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <circle stroke="#47d764" cx={12} cy={12} r={10} />
            <path stroke="#fff" d="m9 12 2 2 4-4" />
        </svg>
    )
}

export const InfoIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="#CFDAE6"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <circle stroke="#CFDAE6" cx={12} cy={12} r={10} />
            <path stroke="#000" d="M12 16v-4" />
            <path stroke="#000" d="M12 8h.01" />
        </svg>
    )
}

export const WarningIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="#ffc021"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <circle stroke="#ffc021" cx={12} cy={12} r={10} />
            <line stroke="#fff" x1={12} x2={12} y1={8} y2={12} />
            <line stroke="#fff" x1={12} x2="12.01" y1={16} y2={16} />
        </svg>
    )
}

export const ErrorIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="#ff355b"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-circle-x"
        >
            <circle stroke="#ff355b" cx={12} cy={12} r={10} />
            <path stroke="#fff" d="m15 9-6 6" />
            <path stroke="#fff" d="m9 9 6 6" />
        </svg>
    )
}