import React, { useState, useMemo, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TextInput, { InputWrapper } from "../inputs/TextInput";
import Button from "../button/index";
import {
  Action,
  Bottom,
  LeftTable,
  MatchContent,
  MatchInfo,
  MatchTableWrap,
  Score,
  TeamMatches,
  TeamName,
  TeamScore,
  Top,
  RightTable,
  ScrollableContainer,
  StyledTable,
  TableButtons,
} from "../../style/MatchTable";
import Moment from "react-moment";
import Filter, { FormWrap } from "../modal/Filter";
import { Formation } from "../Formation";
import { PageButton, PaginationWrapper } from "../../style/Favorites";
import {
  DropdownWrapper,
  OpenedDropdown,
  ParamCheckboxStyle,
  ParamsWrap,
} from "./MatchTable";

import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import more from "../../images/ic_more.svg";
import play from "../../images/ic_play.svg";
import playMatchBlue from "../../images/play_match_blue.svg";
import playInBall from "../../images/ic_playinball.svg";
import playInBallBlue from "../../images/playInBall_blue.svg";
import statistics from "../../images/ic_statistics.svg";
import statisticsBlue from "../../images/statistics_blue.svg";
import formation from "../../images/ic_formation.svg";
import formationBlue from "../../images/formation_blue.svg";

import { $apiV1 } from "../../config/api";
import { ParametersSelector } from "../ParametersSelector";

const CompetitionMatch = ({
  table = [],
  load,
  currentPage,
  totalPages,
  onPageChange,
  teamParams,
}) => {
  console.log("data CompetitionMatch", table);
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]); // State for selected filters
  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    checkedIds: [],
    params: [],
  }); // Temporary state for filters
  const [numMatches, setNumMatches] = useState(10); // State for number of matches to display

  const [openFormation, setOpenFormation] = useState(false);
  const [selectedFormationMatch, setSelectedFormationMatch] = useState(null);

  const [selectedMatch, setSelectedMatch] = useState("");
  const [openMatchesCount, setOpenMatchesCount] = useState(false);
  const [lastMatches, SetLastMatches] = useState("");
  const [openParams, setOpenParams] = useState(false);
  const [downloadFile, setDownloadFile] = useState("");

  useEffect(() => {
    const setDefaultSelectedParams = () => {
      const checkedIds = teamParams.flatMap((group) =>
        group[1].filter((item) => item.checked).map((item) => item.id)
      );
      const checkedParams = teamParams.flatMap((group) =>
        group[1].filter((item) => item.checked).map((item) => item.name)
      );
      setTempSelectedFilters({ checkedIds, params: checkedParams });
    };
    teamParams?.length > 0 && setDefaultSelectedParams();
  }, [teamParams]);

  const handleMatches = (item) => {
    setNumMatches(item.value);
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const tableLeftNames = [
    { id: 1, name: "Opponents" },
    { id: 2, name: "Score" },
  ];

  const setMoreInfo = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const handleFilterChange = (filter) => {
    setTempSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((item) => item !== filter)
        : [...prevFilters, filter]
    );
  };

  const applyFilter = () => {
    setSelectedFilters(tempSelectedFilters);
    setOpenFilter(false);
  };

  // Filter the table based on the selected number of matches
  const filteredTable = useMemo(() => {
    return table.slice(0, numMatches);
  }, [table, numMatches]);

  console.log(filteredTable);

  // Determine which keys to display based on selected filters
  const filteredKeys = selectedFilters.length
    ? selectedFilters
    : filteredTable.length > 0
    ? Object.keys(filteredTable[0]?.away_team?.stats || {})
    : [];

  const handleCloseFormation = () => {
    setOpenFormation(false);
    setSelectedFormationMatch(null);
  };

  const handleClearFilter = () => {
    SetLastMatches();
    setSelectedMatch("");
    setTempSelectedFilters("");
    setNumMatches(table.length);
  };

  useEffect(() => {
    // Fetch data whenever currentPage changes
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const matches = [
    {
      id: 1,
      value: table.length,
      title: "All matches",
    },
    {
      id: 2,
      value: 3,
      title: "Last 3 matches",
    },
    {
      id: 3,
      value: 5,
      title: "Last 5 matches",
    },
    {
      id: 4,
      value: 10,
      title: "Last 10 matches",
    },
  ];

  const displayFilters = () => {
    if (tempSelectedFilters?.params?.length === 0) {
      return "Choose params";
    }

    const firstTwo = tempSelectedFilters?.params?.slice(0, 2)?.join(", ");
    const additionalCount = tempSelectedFilters?.params?.length - 2;
    return additionalCount > 0 ? `${firstTwo}, +${additionalCount}` : firstTwo;
  };

  const matchIds = table.map((item) => item.id);

  const [loadDownloadXls, setDownloadXls] = useState(false);
  const handleDownloadXls = async () => {
    setDownloadXls(true);
    try {
      const response = await $apiV1.post(`/statistics/match_event_unload`, {
        file_type: "xlsx",
        matches: matchIds,
      });

      const fileUrl = response?.data?.file_url;
      if (fileUrl) {
        // Create a temporary <a> element
        const a = document.createElement("a");
        a.href = `https://platform.smrtstats.com:8888/${fileUrl}`;
        a.download = "file.xlsx"; // Specify the default filename
        a.style.display = "none"; // Hide the element
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up
      } else {
        console.error("File URL not found in response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setDownloadXls(false);
    }
  };

  const getWinner = (score, isHome) => {
    let scoreColor;
    if (isHome) {
      if (+score[0] > +score[2]) {
        scoreColor = "#36e82d";
      } else if (+score[0] === +score[2]) {
        scoreColor = "#E8D02D";
      } else {
        scoreColor = "#f20a46";
      }
    } else if (+score[2] > +score[0]) {
      scoreColor = "#36e82d";
    } else if (+score[0] === +score[2]) {
      scoreColor = "#E8D02D";
    } else {
      scoreColor = "#f20a46";
    }
    return scoreColor;
  };

  function handleChangeParams(params) {
    const checkedIds = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.id)
    );
    const checkedParams = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.name)
    );
    setTempSelectedFilters({ checkedIds, params: checkedParams });
  }

  return (
    <>
      {load ? (
        "loading"
      ) : (
        <>
          {isMobile && (
            <TableButtons>
              <button
                className="buttonFilter"
                onClick={() => handleDownloadXls()}
                style={{ width: "100%" }}
              >
                {loadDownloadXls ? "Downloading" : " Download xls"}
              </button>
              <button
                className="buttonFilter"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {t("smrtSearch.filter")}
              </button>
            </TableButtons>
          )}

          {/* <TournamentMatchesTable teamParams={teamParams} loading={load} matchesStat={table} id={id} /> */}
          <MatchTableWrap>
            <LeftTable>
              <Top>
                <ul>
                  {tableLeftNames.map((item, index) => (
                    <li key={index}>{item.name}</li>
                  ))}
                </ul>
              </Top>
              <Bottom>
                {filteredTable &&
                  filteredTable.map((item, index) => (
                    <TeamMatches key={index}>
                      <TeamName>
                        <Score>
                          <h3>
                            <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                          </h3>
                          <h4>{item?.away_team?.name}</h4>
                          <h4>{item?.home_team?.name}</h4>
                        </Score>
                      </TeamName>
                      <TeamScore style={{ position: "relative" }}>
                        <span
                          style={{
                            color: getWinner(item.score, false),
                          }}
                        >
                          {item?.away_team_score}
                        </span>
                        <span
                          style={{
                            color: getWinner(item.score, true),
                          }}
                        >
                          {item?.home_team_score}
                        </span>
                      </TeamScore>

                      <img
                        className="more"
                        src={more}
                        alt="more"
                        onClick={() => setMoreInfo(index)}
                      />
                      {openRow === index && (
                        <MatchInfo className="competitionMathcInfo">
                          <MatchContent>
                            <Action
                              onClick={() =>
                                navigate(`/tournaments/match/video/${item.id}`)
                              }
                              onContextMenu={() =>
                                window.open(
                                  `/tournaments/match/video/${item.id}`,
                                  "__blank"
                                )
                              }
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? playMatchBlue
                                    : play
                                }
                                alt="play"
                              />
                              <h4>Play Full Match</h4>
                            </Action>
                            <Action>
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? playInBallBlue
                                    : playInBall
                                }
                                alt="playInBall"
                              />
                              <h4>OBE</h4>
                            </Action>
                            <Action
                              onClick={() =>
                                navigate(`/tournaments/statistics/${item.id}`)
                              }
                              onContextMenu={() =>
                                window.open(
                                  `/tournaments/statistics/${item.id}`,
                                  "__blank"
                                )
                              }
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? statisticsBlue
                                    : statistics
                                }
                                alt="statistics"
                              />
                              <h4>See Match Statistics</h4>
                            </Action>
                            <Action
                              onClick={() => {
                                setSelectedFormationMatch(item);
                                setOpenFormation(true);
                              }}
                              onContextMenu={() => {
                                setSelectedFormationMatch(item);
                                setOpenFormation(true);
                              }}
                            >
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? formationBlue
                                    : formation
                                }
                                alt="formation"
                              />
                              <h4>See Match Formation</h4>
                            </Action>
                          </MatchContent>
                        </MatchInfo>
                      )}
                    </TeamMatches>
                  ))}
              </Bottom>
            </LeftTable>

            <RightTable>
              {!isMobile && (
                <TableButtons>
                  <button
                    className="buttonFilter"
                    onClick={() => handleDownloadXls()}
                    style={{ width: "100%" }}
                  >
                    {loadDownloadXls ? "Downloading" : " Download xls"}
                  </button>
                  <button
                    className="buttonFilter"
                    onClick={() => setOpenFilter(!openFilter)}
                  >
                    {t("smrtSearch.filter")}
                  </button>
                </TableButtons>
              )}

              <ScrollableContainer>
                <StyledTable>
                  <thead>
                    <tr>
                      {filteredTable
                        ?.find((match) => match?.home_team?.stats?.length > 0)
                        ?.home_team?.stats?.map((param) => {
                          if (
                            !tempSelectedFilters.checkedIds.includes(
                              param.parameter_id
                            )
                          )
                            return null;
                          return (
                            <React.Fragment key={param.parameter_id}>
                              <th
                                data-tooltip-id={`full-name-${param.parameter_id}`}
                              >
                                {param.parameter_name}
                              </th>
                              <ReactTooltip
                                id={`full-name-${param.parameter_id}`}
                                place="bottom"
                                content={param.parameter_name}
                              />
                            </React.Fragment>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTable.map((match, matchIndex) => {
                      return (
                        <tr key={match.id} style={{ height: "96.5px" }}>
                          {match?.home_team?.stats?.map((stat, idx) => {
                            if (
                              !tempSelectedFilters.checkedIds.includes(
                                stat.parameter_id
                              )
                            )
                              return null;
                            return (
                              <React.Fragment key={stat.paramter_id}>
                                <td className="tournamentTable">
                                  <span
                                    onClick={() =>
                                      match.away_team?.stats?.[idx]?.markers
                                        ?.length > 0 &&
                                      navigate(
                                        `/tournaments/match/video?markers=${match.away_team?.stats?.[
                                          idx
                                        ]?.markers?.join(",")}`
                                      )
                                    }
                                    onContextMenu={() =>
                                      match.away_team?.stats?.[idx]?.markers
                                        ?.length > 0 &&
                                      window.open(
                                        `/tournaments/match/video?markers=${match.away_team?.stats?.[
                                          idx
                                        ]?.markers?.join(",")}`
                                      )
                                    }
                                  >
                                    {match.away_team?.stats?.[idx]?.value || 0}
                                  </span>
                                  <br />{" "}
                                  <span
                                    onClick={() =>
                                      stat?.markers?.length > 0 &&
                                      navigate(
                                        `/tournaments/match/video?markers=${stat?.markers?.join(
                                          ","
                                        )}`
                                      )
                                    }
                                    onContextMenu={() =>
                                      stat?.markers?.length > 0 &&
                                      window.open(
                                        `/tournaments/match/video?markers=${stat?.markers?.join(
                                          ","
                                        )}`
                                      )
                                    }
                                  >
                                    {stat.value}
                                  </span>
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </StyledTable>
              </ScrollableContainer>
            </RightTable>

            <Filter
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              setTempSelectedFilters={setTempSelectedFilters}
              setNumMatches={setNumMatches}
              onClick={() => handleClearFilter()}
            >
              <FormWrap>
                <DropdownWrapper>
                  <div style={{ position: "relative" }}>
                    <TextInput
                      type="text"
                      value={
                        selectedMatch.length == 0 ? "PRE-SET" : selectedMatch
                      }
                      onClick={() => setOpenMatchesCount(!openMatchesCount)}
                      readOnly
                    />
                    <img
                      src={openMatchesCount ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      onClick={() => setOpenMatchesCount(!openMatchesCount)}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {openMatchesCount && (
                    <OpenedDropdown>
                      {matches?.map((item, index) => (
                        <div key={index} onClick={() => handleMatches(item)}>
                          {item.title}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <ParamsWrap>
                  <InputWrapper>
                    <input
                      type="text"
                      value={displayFilters()}
                      readOnly
                      style={{ width: "100%", marginBottom: "10px" }}
                      onClick={() => setOpenParams(!openParams)}
                    />
                    <img
                      className="dropdownIcon"
                      src={openParams ? arrowIconUp : arrowIconDown}
                      alt={openParams ? arrowIconUp : arrowIconDown}
                      onClick={() => setOpenParams(!openParams)}
                    />
                  </InputWrapper>
                </ParamsWrap>
              </FormWrap>

              <Button
                size={"full"}
                sizeResponse={"full"}
                title="Apply Filter"
                onClick={() => applyFilter()}
              />
            </Filter>
          </MatchTableWrap>
          <PaginationWrapper className="pagination-controls">
            <PageButton onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </PageButton>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <PageButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </PaginationWrapper>
        </>
      )}

      <ParametersSelector
        type={"team"}
        open={openParams}
        onClose={() => setOpenParams(false)}
        updateData={handleChangeParams}
      />

      <Formation
        open={openFormation}
        handleClose={handleCloseFormation}
        match={selectedFormationMatch}
      />
    </>
  );
};

export default CompetitionMatch;
