import styled from "styled-components";
import { breakpoints } from "./size";

export const PlayerWrapperCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-bottom: 50px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 20px;
    padding-bottom: 0px;
  }
`;

export const PlayerCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  width: 536px;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const PlayerContent = styled.div`
  padding: 40px 20px;
  height: 776px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (${breakpoints.mobile}) {
    padding: 40px 20px 20px;
    gap: 40px;
    height: auto;
  }
`;

export const TopInfo = styled.div``;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 10px;

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 28px;
    }
  }
`;

export const PlayerName = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const LeftImage = styled.div`
  position: relative;

  @media only screen and (${breakpoints.mobile}) {
    width: fit-content;
  }

  .profile {
    width: 80px;
    height: 80px;

    @media only screen and (${breakpoints.mobile}) {
      width: 44px;
      height: 44px;
    }
  }

  .flag {
    width: 25px;
    height: 25px;
    position: absolute;
    top: -10px;
    right: -10px;

    @media only screen and (${breakpoints.mobile}) {
      width: 15px;
      height: 15px;
      top: -7px;
      right: -7px;
    }
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 38px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  img {
    width: 18px;
    height: 18px;
  }
`;

export const PlayerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  //   @media only screen and (${breakpoints.mobile}) {
  //     &:nth-child(1) {
  //       width: 100%;
  //     }

  //     &:nth-child(2) {
  //       width: 100%;
  //     }
  //   }

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const List = styled.div`
  margin-bottom: 20px;

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  ul {
    list-style-type: none;
    li {
      opacity: 1;
      color: ${(props) => props.theme.white};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }
`;
