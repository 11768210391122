import styled from "styled-components";

export const TimelineSlider = styled.div`
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #9a9b99;
    border-radius: 2px;
    user-select: none;
`

export const SliderRange = styled.div`
    position: absolute;
    background-color: ${props => props.theme.green};
    height: 100%;
    border-radius: 10px;
`

export const SliderMark = styled.div`
    position: absolute;
    
    span {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #fff;
        top: 0px;
    }

    p {
        top: 10px;
        position: absolute;
        font-size: 12px;
        font-family: "SatoshiBold";
        color: ${props => props.theme.blueish2};
    }
`

export const SiderInput = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-slider-thumb {
        pointer-events: all;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${props => props.theme.green};
        box-shadow: 0 0 1px 1px ${props => props.theme.green};
        -webkit-appearance: none;
        appearance: none;
    }

    &::-moz-range-thumb {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${props => props.theme.green};
        box-shadow: 0 0 1px 1px ${props => props.theme.green};
    }
`

export const SiderCurrentValue = styled.div`
    position: absolute;
    top: -25px;
    font-family: "SatoshiBold";
    font-size: 12px;
    color: ${props => props.theme.green};
`