import styled from "styled-components";
import { breakpoints } from "./size";

export const TableWrap = styled.ul`
  border: 1px solid ${(props) => props.theme.lines};
`;

export const TableContent = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;

  @media only screen and (${breakpoints.mobile}) {
    padding-left: 10px;
  }

  &:nth-child(odd) {
    background: ${(props) => props.theme.row};
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.black};
  }
`;

export const ImagesWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ProfileImage = styled.div`
  position: relative;
  display: flex;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    position: absolute;
    top: -9px;
    right: -6px;

    @media only screen and (${breakpoints.mobile}) {
      top: -12px;
      img {
        width: 10px;
      }
    }
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const RightContent = styled.div``;

export const SeeMoreWrap = styled.div`
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiBold";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: right;
  line-height: 24px;
  padding: 20px;
  text-transform: capitalize;
  cursor: pointer;

  @media only screen and (${breakpoints.mobile}) {
    width: 95px;
  }
`;
