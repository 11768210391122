import { createAsyncThunk } from "@reduxjs/toolkit";
import { $apiV1 } from "../../config/api";

export const searchTeam = createAsyncThunk(
  "platform/search_team",
  async ({ page, search }, { rejectWithValue }) => {
    try {
      const response = await $apiV1.get(
        "https://platform.smrtstats.com:8888/api/v1/platform/team/",
        {
          params: { page, search },
        }
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  }
);

export const searchPlayer = createAsyncThunk(
  "platform/search_player",
  async ({ page, search }, { rejectWithValue }) => {
    try {
      const response = await $apiV1.get(
        "https://platform.smrtstats.com:8888/api/v1/platform/player/",
        {
          params: { page, search },
        }
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  }
);
