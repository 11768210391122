import React, { useState, useEffect } from "react";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  NavigationDesktopWrapper,
  Content,
  TopContent,
  BottomContent,
  MenuWrapper,
  ResizeMenu,
  NavigationMobileWrapper,
  MobileContent,
  MobileListOverylay,
  MobileList,
  ContentMenu,
} from "../../style/Navigation";

// Language
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

// Images
import logo from "../../images/footerLogo.svg";
import logoWhite from "../../images/ic_logo_white.svg";
import smallLogo from "../../images/ic_logo_small.svg";
import resize from "../../images/ic_minimize.svg";
import resizeBlue from "../../images/ic_minimize_blue.svg";
import hamburgerMenu from "../../images/ic_menu.svg";
import close from "../../images/ic_close.svg";

// Menu Icons
import Home from "../../images/icons/home.svg";
import HomeBlue from "../../images/icons/home_blue.svg";
import HomeHover from "../../images/icons/home_hover.svg";
import HomeBlueHover from "../../images/icons/home_blue_hover.svg";
import HomeActive from "../../images/icons/home_active.svg";
import HomeBlueActive from "../../images/icons/home_blue_active.svg";

import Tournaments from "../../images/icons/tournaments.svg";
import TournamentsBlue from "../../images/icons/tournaments_blue.svg";
import TournamentsHover from "../../images/icons/tournaments_hover.svg";
import TournamentsBlueHover from "../../images/icons/tournaments_blue_hover.svg";
import TournamentsActive from "../../images/icons/tournaments_active.svg";
import TournamentsBlueActive from "../../images/icons/tournaments_blue_active.svg";

import SmrtSearch from "../../images/icons/smrtSearch.svg";
import SmrtSearchBlue from "../../images/icons/smrtSearch_blue.svg";
import SmrtSearchHover from "../../images/icons/smrtSearch_hover.svg";
import SmrtSearchBlueHover from "../../images/icons/smrtSearch_blue_hover.svg";
import SmrtSearchActive from "../../images/icons/smrtSearch_active.svg";
import SmrtSearchBlueActive from "../../images/icons/smrtSearch_blue_active.svg";

import FavoritesLinks from "../../images/icons/favoritesLinks.svg";
import FavoritesLinksBlue from "../../images/icons/favoritesLinks_blue.svg";
import FavoritesLinksHover from "../../images/icons/favoritesLinks_hover.svg";
import FavoritesLinksBlueHover from "../../images/icons/favoritesLinks_blue_hover.svg";
import FavoritesLinksActive from "../../images/icons/favoritesLinks_active.svg";
import FavoritesLinksBlueActive from "../../images/icons/favoritesLinks_blue_active.svg";

import MyPlaylists from "../../images/icons/myPlaylists.svg";
import MyPlaylistsBlue from "../../images/icons/myPlaylists_blue.svg";
import MyPlaylistsHover from "../../images/icons/myPlaylists_hover.svg";
import MyPlaylistsBlueHover from "../../images/icons/myPlaylists_blue_hover.svg";
import MyPlaylistsActive from "../../images/icons/myPlaylists_active.svg";
import MyPlaylistsBlueActive from "../../images/icons/myPlaylists_blue_active.svg";

import PlatformSetup from "../../images/icons/platformSetup.svg";
import PlatformSetupBlue from "../../images/icons/platformSetup_blue.svg";
import PlatformSetupHover from "../../images/icons/platformSetup_hover.svg";
import PlatformSetupBlueHover from "../../images/icons/platformSetup_hover_blue.svg";
import PlatformSetupActive from "../../images/icons/platformSetup_active.svg";
import PlatformSetupBlueActive from "../../images/icons/platformSetup_active_blue.svg";

import ContactUs from "../../images/icons/contactUs.svg";
import ContactUsBlue from "../../images/icons/contactUsBlue.svg";
import ContactUsHover from "../../images/icons/contactUs_hover.svg";
import ContactUsBlueHover from "../../images/icons/contactUs_blue_hover.svg";
import ContactUsActive from "../../images/icons/contactUs_active.svg";
import ContactUsBlueActive from "../../images/icons/contactUs_blue_active.svg";

import Logout from "../../images/icons/logout.svg";
import LogoutBlue from "../../images/icons/logout_blue.svg";
import LogoutHover from "../../images/icons/logout_hover.svg";
import LogoutBlueHover from "../../images/icons/logout_blue_hover.svg";
import LogoutActive from "../../images/icons/logout_hover.svg";
import LogoutBlueActive from "../../images/icons/logout_blue_hover.svg";

import { useDispatch } from "react-redux";
import { userActions } from "../../store/user/userSlice";

const Navigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  // Menu links
  const [activeRoute, setActiveRoute] = useState(null);
  const [hoveredRoute, setHoveredRoute] = useState(null);

  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveRoute(currentPath);
  }, []);

  function handleLogout() {
    localStorage.removeItem("token");
    dispatch(userActions.setToken(""));
    navigate("/");
  }

  const menu = [
    {
      label: `${t("navigationDashboard.home")}`,
      href: "/dashboard",
      icon: localStorage.getItem("theme") === "light" ? HomeBlue : Home,
      iconHover:
        localStorage.getItem("theme") === "light" ? HomeBlueHover : HomeHover,
      iconActive:
        localStorage.getItem("theme") === "light" ? HomeBlueActive : HomeActive,
    },
    {
      label: `${t("navigationDashboard.tournaments")}`,
      href: "/tournaments",
      icon:
        localStorage.getItem("theme") === "light"
          ? TournamentsBlue
          : Tournaments,
      iconHover:
        localStorage.getItem("theme") === "light"
          ? TournamentsBlueHover
          : TournamentsHover,
      iconActive:
        localStorage.getItem("theme") === "light"
          ? TournamentsBlueActive
          : TournamentsActive,
    },
    {
      label: `${t("navigationDashboard.smrtSearch")}`,
      href: "/smrt-search",
      icon:
        localStorage.getItem("theme") === "light" ? SmrtSearchBlue : SmrtSearch,
      iconHover:
        localStorage.getItem("theme") === "light"
          ? SmrtSearchBlueHover
          : SmrtSearchHover,
      iconActive:
        localStorage.getItem("theme") === "light"
          ? SmrtSearchBlueActive
          : SmrtSearchActive,
    },
    {
      label: `${t("navigationDashboard.favoritesLinks")}`,
      href: "/favorites",
      icon:
        localStorage.getItem("theme") === "light"
          ? FavoritesLinksBlue
          : FavoritesLinks,
      iconHover:
        localStorage.getItem("theme") === "light"
          ? FavoritesLinksBlueHover
          : FavoritesLinksHover,
      iconActive:
        localStorage.getItem("theme") === "light"
          ? FavoritesLinksBlueActive
          : FavoritesLinksActive,
    },
    {
      label: `${t("navigationDashboard.myPlaylists")}`,
      href: "/my-playlists",
      icon:
        localStorage.getItem("theme") === "light"
          ? MyPlaylistsBlue
          : MyPlaylists,
      iconHover:
        localStorage.getItem("theme") === "light"
          ? MyPlaylistsBlueHover
          : MyPlaylistsHover,
      iconActive:
        localStorage.getItem("theme") === "light"
          ? MyPlaylistsBlueActive
          : MyPlaylistsActive,
    },
    {
      label: `${t("navigationDashboard.platformSetup")}`,
      href: "/platform-setup",
      icon:
        localStorage.getItem("theme") === "light"
          ? PlatformSetupBlue
          : PlatformSetup,
      iconHover:
        localStorage.getItem("theme") === "light"
          ? PlatformSetupBlueHover
          : PlatformSetupHover,
      iconActive:
        localStorage.getItem("theme") === "light"
          ? PlatformSetupBlueActive
          : PlatformSetupActive,
    },

    {
      label: `${t("navigationDashboard.contactUs")}`,
      href: "/contact-us",
      icon:
        localStorage.getItem("theme") === "light" ? ContactUsBlue : ContactUs,
      iconHover:
        localStorage.getItem("theme") === "light"
          ? ContactUsBlueHover
          : ContactUsHover,
      iconActive:
        localStorage.getItem("theme") === "light"
          ? ContactUsBlueActive
          : ContactUsActive,
    },
    {
      label: `${t("navigationDashboard.logOut")}`,
      icon: localStorage.getItem("theme") === "light" ? LogoutBlue : Logout,
      iconHover:
        localStorage.getItem("theme") === "light"
          ? LogoutBlueHover
          : LogoutHover,
      iconActive:
        localStorage.getItem("theme") === "light"
          ? LogoutBlueActive
          : LogoutActive,
      customClick: handleLogout,
    },
  ];

  const topMenu = menu.slice(0, 6).map((item, index) => {
    const Icon =
      activeRoute === item.href
        ? item.iconActive
        : hoveredRoute === item.href
        ? item.iconHover
        : item.icon;
    const isActive = activeRoute === item.href ? open && "active hideMenu" : ""; // Set active class
    return (
      <Link
        key={index}
        onMouseEnter={() => setHoveredRoute(item.href)}
        onMouseLeave={() => setHoveredRoute(null)}
        onClick={() => {
          setActiveRoute(item.href);
        }}
        to={item.href}
        className={isActive}
      >
        <img src={Icon} alt={item.label} />
        {open && item.label}
      </Link>
    );
  });

  const bottomMenu = menu.slice(-2).map((item, index) => {
    const Icon =
      activeRoute === item.href
        ? item.iconActive
        : hoveredRoute === item.href
        ? item.iconHover
        : item.icon;
    const isActive = activeRoute === item.href ? open && "active hideMenu" : ""; // Set active class
    return (
      <Link
        key={index}
        onMouseEnter={() => setHoveredRoute(item.href)}
        onMouseLeave={() => setHoveredRoute(null)}
        onClick={item.customClick ? item.customClick : () => {}}
        // onClick={() => {
        //   setActiveRoute(item.href);
        // }}
        to={item.href}
        className={isActive}
      >
        <img src={Icon} alt={item.label} />
        {open && item.label}
      </Link>
    );
  });

  const mobileMeni = menu.map((item, index) => {
    const Icon =
      activeRoute === item.href
        ? item.iconActive
        : hoveredRoute === item.href
        ? item.iconHover
        : item.icon;
    const isActive = activeRoute === item.href ? "active" : "";
    return (
      <Link
        key={index}
        onMouseEnter={() => setHoveredRoute(item.href)}
        onMouseLeave={() => setHoveredRoute(null)}
        onClick={() => {
          setActiveRoute(item.href);
        }}
        to={item.href}
        className={isActive}
      >
        <img src={Icon} alt={item.label} />
        {item.label}
      </Link>
    );
  });

  let themeMode = localStorage.getItem("theme");

  const handleNavigationOpen = () => {
     setOpen(!open);
     localStorage.setItem("navigationOpen", open ? false : true)
     window.dispatchEvent(new Event("navigationOpenChange"));
  }

  const desktopNavigation = (
    <NavigationDesktopWrapper hide={!open}>
      <Content hide={!open}>
        <TopContent>
          {!open ? (
            <img className="smallLogo" src={smallLogo} alt={smallLogo} />
          ) : (
            <img
              className="logo"
              src={themeMode === "light" ? logoWhite : logo}
              alt={logo}
            />
          )}

          <MenuWrapper>{topMenu}</MenuWrapper>
        </TopContent>
        <BottomContent>
          <MenuWrapper>{bottomMenu}</MenuWrapper>
        </BottomContent>
      </Content>

      <ResizeMenu onClick={handleNavigationOpen}>
        <img src={localStorage.getItem("theme") === "light" ? resizeBlue : resize} alt={resize} />
      </ResizeMenu>
    </NavigationDesktopWrapper>
  );

  const mobileNavigation = (
    <>
      <NavigationMobileWrapper>
        <MobileContent>
          <img className="mobileLogo" src={localStorage.getItem("theme") === "light" ? logoWhite : logo} alt={logo} />
          {!openMenu ? (
            <img
              src={hamburgerMenu}
              alt={hamburgerMenu}
              onClick={() => setOpenMenu(!openMenu)}
            />
          ) : (
            <img
              src={close}
              alt={close}
              onClick={() => setOpenMenu(!openMenu)}
            />
          )}
        </MobileContent>
      </NavigationMobileWrapper>
      {openMenu && (
        <MobileListOverylay>
          <MobileList>
            <ContentMenu>{mobileMeni}</ContentMenu>
          </MobileList>
        </MobileListOverylay>
      )}
    </>
  );

  return <>{isMobile ? mobileNavigation : desktopNavigation}</>;
};

export default Navigation;
