import { fieldPadding } from "../../../../components/Formation/constants/fieldVars"

export const groupEventsByTeam = (events) => {
    try {
        const groupedDataByTeam = events?.reduce((
            data,
            item,
        ) => {
            const idxGroup = data.findIndex((a) => a.match.id === item.match.id)
            if (idxGroup >= 0) {
                data[idxGroup] = { ...data[idxGroup], markers: [...data[idxGroup].markers, item] }
            } else {
                data.push({ markers: [item], match: item.match })
            }
            return data
        }, [])
        return groupedDataByTeam;
    } catch {
        return [];
    }
}

export const getVideoName = (item, offset) => `${item?.action}, ${new Date(
    +(item?.second - (offset?.start ?? 10)) * 1000,
)
    .toISOString()
    .slice(11, 19)} - ${new Date(
        (+item?.second + (offset?.end ?? 10)) * 1000,
    )
        .toISOString()
        .slice(11, 19)}`

export function convertIsoToYyyyMmDd(isoDate) {
    try {
        const dateObject = new Date(isoDate);

        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();

        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return formattedDate;
    } catch (error) {
        return '';
    }
}

export const getZones = ({
    width,
    height,
    quantity,
    step = quantity,
}) => {
    const zones = []
    const columns = quantity % step !== 0 ? quantity : step
    const zoneWidth = step ? (width / columns) : (width / quantity)
    const zoneHeight = step ? (height / (quantity / columns)) : height

    let x = 5;
    let y = 5;


    for (let i = 0; i < quantity; i++) {
        if (i % columns === 0 && i) {
            x = 5
            y += zoneHeight
        } else if (i) {
            x += zoneWidth
        }
        const zone = {
            height: zoneHeight,
            id: i,
            width: zoneWidth,
            x,
            y,
            visible: true,
        }
        zones.push(zone)
    }
    return zones
}

const customZones = (
    pitchWidth,
    pitchHeight,
    quantity = 12,
) => {
    const correctParam = quantity === 14 ? 2 : 1

    const additionalZones = quantity === 14 ? [
        {
            height: pitchHeight - ((pitchHeight / 4.5) * 2),
            width: (pitchWidth / 3) / correctParam,
            x: (pitchWidth / 3) / correctParam + fieldPadding,
            y: pitchHeight / 4.5 + fieldPadding,
        },
        {
            height: pitchHeight - ((pitchHeight / 4.5) * 2),
            width: (pitchWidth / 3) / correctParam ,
            x: pitchWidth - ((pitchWidth / 3) / correctParam) + fieldPadding,
            y: pitchHeight / 4.5 + fieldPadding,
        },
    ] : []
    return [
        /* corner zones */
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 3,
            x: 5,
            y: 5,
            id: 'corn-top-left'
        },
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 3 + fieldPadding,
            x: pitchWidth - pitchWidth / 3,
            y: 5,
            id: 'corn-top-right'
        },
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 3,
            x: 5,
            y: pitchHeight - pitchHeight / 4.5 + fieldPadding,
            id: 'corn-bottom-left'
        },
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 3 + fieldPadding,
            x: pitchWidth - pitchWidth / 3,
            y: pitchHeight - pitchHeight / 4.5 + fieldPadding,
            id: 'corn-bottom-right'
        },
        /* penalty areas */
        {
            height: pitchHeight - ((pitchHeight / 4.5) * 2),
            width: (pitchWidth / 3) / correctParam,
            x: 5,
            y: pitchHeight / 4.5 + fieldPadding,
        },
        {
            height: pitchHeight - ((pitchHeight / 4.5) * 2),
            width: (pitchWidth / 3) / correctParam + fieldPadding,
            x: pitchWidth - pitchWidth / 3,
            y: pitchHeight / 4.5 + fieldPadding,
        },
        /* center zones left and right */
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 6,
            x: pitchWidth / 3 + fieldPadding,
            y: 5,
        },
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 6 - fieldPadding,
            x: pitchWidth - pitchWidth / 3 - pitchWidth / 6 + fieldPadding,
            y: 5,
        },
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 6,
            x: pitchWidth / 3 + fieldPadding,
            y: pitchHeight - pitchHeight / 4.5 + fieldPadding,
        },
        {
            height: pitchHeight / 4.5,
            width: pitchWidth / 6 - fieldPadding,
            x: pitchWidth - pitchWidth / 3 - pitchWidth / 6 + fieldPadding,
            y: pitchHeight - pitchHeight / 4.5 + fieldPadding,
        },
        /* center */
        {
            height: pitchHeight - ((pitchHeight / 4.5) * 2),
            width: pitchWidth / 6,
            x: pitchWidth / 3 + fieldPadding,
            y: pitchHeight / 4.5 + fieldPadding,
        },
        {
            height: pitchHeight - ((pitchHeight / 4.5) * 2),
            width: pitchWidth / 6 - fieldPadding,
            x: pitchWidth / 3 + pitchWidth / 6 + fieldPadding,
            y: pitchHeight / 4.5 + fieldPadding,
        },
        ...additionalZones,
    ]
}

export const getCustomZones = ({
    width,
    height,
    quantity,
}) => {
    const zones = []

    customZones(
        width,
        height,
        quantity,
    )
        ?.forEach((zone, i) => {
            zones.push({
                id: i,
                visible: true,
                ...zone,
            })
        })

    return zones
}
