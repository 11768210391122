import React from "react";

// Style
import {
  ButtonWrap,
  EmptyContent,
  EmptyState,
  EmpytText,
} from "../../style/Empty";

// Images
import empty from "../../images/ic_empty.svg";

// Componenst
import Button from "../button";

const index = ({ title, text, button1, button2, onClick }) => {
  return (
    <EmptyState>
      <EmptyContent>
        <img src={empty} alt={empty} />
        <EmpytText>
          <h2>{title}</h2>
          <p>{text}</p>
        </EmpytText>
        <ButtonWrap>
          {button1 && <Button title={button1} color="primary" />}
          {button2 && <Button title={button2} color="secondary" />}
        </ButtonWrap>
      </EmptyContent>
    </EmptyState>
  );
};

export default index;
